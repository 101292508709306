import { PrismicNextImage } from "@prismicio/next";
import { UptownLink } from "..";
import Styles from "./ProductCard.module.css";

const ProductCard = ({ product, index, course }) => {
	return (
		<UptownLink
			className={Styles.wrapper}
			key={index}
			link={{ link_type: "Document", url: product?.url || course?.url }}
		>
			<div className={Styles.container}>
				<PrismicNextImage
					field={product?.data?.main_image || course?.data?.featured_image}
					className={Styles.image}
				/>
				<h5 className={Styles.productName}>
					{product?.data?.product_name || course?.data?.card_title}
				</h5>
			</div>
			<div className={Styles.learn}>Learn More</div>
		</UptownLink>
	);
};

export default ProductCard;
