import { useRouter } from "next/router";
import Link from "next/link";
import { PrismicProvider } from "@prismicio/react";
import { PrismicPreview } from "@prismicio/next";
import { repositoryName } from "../prismicio";
import { Layout } from "@/components";
import Script from "next/script";
import { ParallaxProvider } from "react-scroll-parallax";
import "@/styles/globals.css";

export default function App({ Component, pageProps }) {
	const router = useRouter();

	if (router.pathname === "/admin") {
		return <Component {...pageProps} />;
	} else {
		return (
			<>
				<Script id="google-tag-manager" strategy="afterInteractive">
					{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-MS29R4Q');`}
				</Script>

				<PrismicProvider internalLinkComponent={(props) => <Link {...props} />}>
					<PrismicPreview repositoryName={repositoryName}>
						<ParallaxProvider>
							<Layout pageProps={pageProps}>
								<Component {...pageProps} />
							</Layout>
						</ParallaxProvider>
					</PrismicPreview>
				</PrismicProvider>
			</>
		);
	}
}
