import { useState } from "react";
import useIsomorphicLayoutEffect from "./useIsomorphicLayoutEffect";

const isBrowser = typeof window !== "undefined";

const useWindowSize = () => {
	const [size, setSize] = useState({
		width: isBrowser ? window.innerWidth : 0,
		height: isBrowser ? window.innerHeight : 0,
	});

	useIsomorphicLayoutEffect(() => {
		const handleResize = () => {
			setSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return size;
};

export default useWindowSize;
