import { useRef, useState, useEffect } from "react";
import { FaPlay, FaPause } from "react-icons/fa";
import { BsFullscreen } from "react-icons/bs";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";

import Styles from "./VideoPlayer.module.css";

/**
 * Renders a video player component based on the provided video type.
 *
 * @param {string} videoType - The type of video player to render. Defaults to "native". Options: "native", "youtube".
 * @param {string} videoUrl - The URL of the video to play.
 * @param {string} poster - The URL of the poster image to display before the video starts playing.
 * @param {string} className - The CSS class name to apply to the video player container.
 * @param {boolean} autoPlay - Whether the video should automatically start playing.
 * @param {object} showControls - An object specifying which controls to show. Defaults to { volume: true, fullscreen: true, play: true, progress: true, scrubbing: true }.
 * @param {object} theme - An object specifying the color theme of the video player. Defaults to { primary: "#ba0000", secondary: "#333", icon: "#fff" }.
 * @return {JSX.Element} The rendered video player component.
 */
const VideoPlayer = ({
	videoType = "native",
	videoUrl,
	poster,
	className,
	autoPlay,
	showControls = {
		volume: true,
		fullscreen: true,
		play: true,
		progress: true,
		scrubbing: true,
	},
	theme = {
		primary: "#ba0000",
		secondary: "#333",
		icon: "#fff",
	},
}) => {
	const video = useRef();
	const progress = useRef();
	const requestRef = useRef();
	const [playing, setPlaying] = useState(false);
	const [muted, setMuted] = useState(video?.current?.muted || false);
	const [userMuted, setUserMuted] = useState(false);
	let animate = false;

	useEffect(() => {
		if (video.current) {
			setPlaying(!video.current.paused);
			setMuted(video.current.muted);
			hideMediaControls(video.current);
		}
	}, [video.current?.paused, video.current?.muted]);

	// iOS media controls bugfix
	const hideMediaControls = (videoElement) => {
		if (videoElement) {
			videoElement.controls = false;
		}
	};

	const progressLoop = () => {
		if (video.current) {
			const value =
				Math.round(
					(video.current.currentTime / video.current.duration) * 100
				) || 0;
			progress.current.value = value;
			progress.current.style.setProperty("--value", `${value}%`);

			if (progress.current.value == 100) {
				setPlaying(autoPlay);
				if (!autoPlay) {
					progress.current.value = 0;
				}
			}
		}

		if (!animate) {
			return;
		}

		requestRef.current = requestAnimationFrame(progressLoop);
	};

	const handleFullScreen = (e) => {
		e.stopPropagation();
		const videoElement = video.current;
		if (document.fullscreenElement || document.webkitFullscreenElement) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.webkitExitFullscreen) {
				// for Safari
				document.webkitExitFullscreen();
			}
		} else {
			if (videoElement.requestFullscreen) {
				videoElement.requestFullscreen();
			} else if (videoElement.webkitEnterFullscreen) {
				// for Safari
				videoElement.webkitEnterFullscreen();
			} else if (videoElement.mozRequestFullScreen) {
				// for Firefox
				videoElement.mozRequestFullScreen();
			} else if (videoElement.msRequestFullscreen) {
				// for IE/Edge
				videoElement.msRequestFullscreen();
			}
		}
	};

	const handlePlay = () => {
		if (!playing) {
			hideMediaControls(video.current);
			video?.current
				?.play()
				.then(() => {
					hideMediaControls(video.current);
					if (video?.current && !autoPlay && !userMuted) {
						video.current.muted = false;
						setMuted(false);
					}
				})
				.catch((error) => {
					console.log("Video play failed because:", error);
				});
			animate = true;
			progressLoop();
		}
		if (playing) {
			animate = false;
			video?.current?.pause();
			cancelAnimationFrame(requestRef.current);
		}
		setPlaying(!playing);
	};

	const handleVolumeChange = (e) => {
		e.stopPropagation();
		video.current.volume = e.target.value / 100;
		if (muted) {
			setMuted(false);
			video.current.muted = false;
		}
	};

	const handleMute = (e) => {
		e.stopPropagation();
		video.current.muted = !video.current.muted;
		setMuted(video.current.muted);
		setUserMuted(!userMuted);
	};

	const handleScrub = (e) => {
		e.stopPropagation();
		const scrubTime = (e.target.value / 100) * video.current.duration;
		video.current.currentTime = scrubTime;
	};

	const handleTimeUpdate = () => {
		if (video.current.currentTime === 0) {
			setPlaying(true);
			progress.current.value = 0;
		}
	};

	// RENDER
	switch (videoType) {
		case "native":
			return (
				<div
					className={`${Styles.videoContainer} ${className}`}
					data-active={playing}
					style={{
						"--primary-color": theme.primary,
						"--secondary-color": theme.secondary,
						"--icon-color": theme.icon,
					}}
				>
					<video
						src={videoUrl}
						ref={video}
						onClick={() => handlePlay()}
						className={Styles.video}
						playsInline
						poster={poster}
						muted
						autoPlay={autoPlay}
						loop={autoPlay}
						onPlay={() => {
							animate = true;
							setPlaying(true);
							progressLoop();
						}}
						onTimeUpdate={handleTimeUpdate}
					/>
					<div
						className={Styles.controlsContainer}
						onClick={() => handlePlay()}
					>
						{showControls.progress && (
							<input
								type="range"
								min="0"
								max="100"
								ref={progress}
								value={
									Math.round(
										(video?.current?.currentTime / video.current?.duration) *
											100
									) || 0
								}
								className={Styles.progress}
								onClick={(e) => e.stopPropagation()}
								onChange={handleScrub}
							/>
						)}

						{showControls.play && (
							<button className={Styles.playBtn} onClick={() => handlePlay()}>
								{playing ? <FaPause size={"40px"} /> : <FaPlay size={"40px"} />}
							</button>
						)}

						{showControls.fullscreen && (
							<button
								className={Styles.fullscreenBtn}
								onClick={(e) => handleFullScreen(e)}
							>
								<BsFullscreen size={"20px"} />
							</button>
						)}

						{showControls.volume && (
							<div
								className={Styles.volumeControl}
								onClick={(e) => e.stopPropagation()}
							>
								<button
									onClick={(e) => handleMute(e)}
									className={Styles.volumeBtn}
								>
									{muted ? (
										<FaVolumeMute size={"20px"} />
									) : (
										<FaVolumeUp size={"20px"} />
									)}
								</button>
								<input
									type="range"
									min="0"
									max="100"
									defaultValue="100"
									className={Styles.volumeSlider}
									onChange={(e) => handleVolumeChange(e)}
								/>
							</div>
						)}
					</div>
				</div>
			);

		case "youtube":
			return (
				<div className={`${Styles.videoContainer}``${className}`}>
					<div
						className={Styles.youtube}
						dangerouslySetInnerHTML={{
							__html: videoUrl,
						}}
					/>
				</div>
			);
	}
};

export default VideoPlayer;
