import Link from "next/link";

/**
 * Renders a link based on the provided link object and properties for use with Prismic CMS.
 * Throws an error if the link object is missing required properties.
 *
 * @param {Object} link - The link object to render.
 * @param {string|ReactNode} children - The content of the link.
 * @param {string} anchorLink - The hash value for the href if the link type is "Document".
 * @param {string} className - The class name for the link element.
 * @param {function} onClick - The click event handler for the link.
 * @returns {ReactNode} - The rendered link element.
 */

const UptownLink = ({
	link,
	children,
	anchorLink,
	className,
	onClick,
	active,
}) => {
	const type = link?.link_type;
	let href;
	let target;
	let rel;

	if (!link?.link_type) {
		console.error(
			"[UptownLink] The provided field is missing required properties to properly render a link.",
			link
		);
		throw new Error(
			`[UptownLink] The provided field is missing required properties to properly render a link. The link will not render.`
		);
	} else if (type == "Document") {
		href = {
			pathname: link?.url,
			hash: anchorLink,
		};
	} else if (type == "Web") {
		href = {
			pathname: link?.url,
		};
		target = "_blank";
		rel = "noopener noreferrer";
	}

	return (
		<Link
			href={href}
			target={target}
			rel={rel}
			onClick={onClick}
			className={className}
			data-active={active}
		>
			{children}
		</Link>
	);
};

export default UptownLink;
