import { PrismicLink } from "@prismicio/react";
// import {
// 	MdOutlineArrowBackIos,
// 	MdOutlineArrowForwardIos,
// } from "react-icons/md";
import Styles from "./Button.module.css";

const Button = ({
  link,
  label,
  variant = "primary",
  lightTheme = false,
  hideIcon = false,
  type,
  submit = false,
  onClick,
  iconLeft = false,
  style,
  disabled,
  id,
}) => {
  if (type == "button") {
    return (
      <button
        data-variant={variant}
        data-light={lightTheme}
        className={Styles.button}
        type={submit ? "submit" : "button"}
        onClick={onClick}
        style={{ ...style }}
        disabled={disabled}
        id={id}
      >
        <span>{label}</span>
      </button>
    );
  } else if (type == "submit") {
    return (
      <input
        type="submit"
        value={label}
        className={Styles.button}
        data-variant={variant}
      />
    );
  } else {
    return (
      <PrismicLink
        field={link}
        data-variant={variant}
        data-light={lightTheme}
        className={Styles.button}
        onClick={onClick}
      >
        <span>{label}</span>
      </PrismicLink>
    );
  }
};

export default Button;
