import Styles from "./InternationalMap.module.css";

const InternationalMap = ({ active, setActiveContinent }) => {
	return (
		<div>
			<svg
				className={Styles.map}
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				viewBox="0 0 2000 857"
				xmlSpace="preserve"
			>
				<g
					id="South_America"
					className={Styles.continent}
					data-active={active == "South America"}
					onClick={() => setActiveContinent("South America")}
				>
					<path
						className={Styles.country}
						d="M669.1,851.7l-3-0.2h-5l-6-13.6l3.1,2.8l4.3,4.6l7.8,3.7l7.3,1.5l-0.8,3l-4.4,0.3L669.1,851.7z"
					/>
					<path
						className={Styles.country}
						d="M638.6,644.7l11.3,10.4l4.6,1l7.3,4.8l5.9,2.5l1.1,2.8l-4.2,9.8l5.8,1.7l6.3,1l4.2-1l4.3-5l0.3-5.6l2.6-1.3
		l3.2,3.8l0.4,5.1l-4.2,3.5l-3.3,2.6l-5.3,6.3l-6,8.7l-0.5,5.2l-0.4,6.6l1.2,6.4l-0.9,1.4l0.4,4.1l0.3,3.4l7.8,5.5l0.2,4.4l3.9,2.8
		l0.3,3.1l-3.3,8.2l-7,3.5l-10.2,1.3l-6-0.7l2.1,3.9l0.1,4.7l1.8,3.2l-2.5,2.3l-5.1,0.9l-5.6-2.4l-1.5,1.7l2.5,6.3l4,1.9l2.3-2
		l2.5,3.3l-4.2,2l-2.9,4l1.2,6.3l-0.1,3.4h-4.8l-3,3.2l0.1,4.8l6.5,4.6l5.2,1.2l0.2,5.7l-4.6,3.5l-0.6,7.3l-3.5,2.4l-0.9,2.9
		l4.2,6.5l4.6,3.5l-2.1-0.3l-4.9-1l-12.1-0.8l-3.5-3.6l-1.9-4.6l-3.1,0.4l-2.6-2.3l-3.1-6.5l2.7-2.8l0.1-3.9l-1.8-3.2l0.7-5.4
		l-1.1-8.3l-1.8-3.7l1.8-1.2l-1.4-2.4l-2.8-1.2l0.8-2.7l-3.1-2.4l-3.7-7.3l1.7-1.3l-3.3-7.8l-0.8-6.5l-0.2-5.7l2.5-2.3l-3.3-6.3
		l-1.6-5.8l3-4.2l-1.4-5.4l1.6-6.2l-1.4-5.9l-1.6-1.2l-4.9-11.1l2.1-6.6l-1.7-6.2l0.9-5.9l2.6-6l3.3-4l-2-2.5l0.8-2.1l-1.6-10.7
		l5.6-3.1l1.2-6.7l-0.9-1.6l4-5.8l7.5,1.6l3.7,4.6l1.6-5.2l6.4,0.3L638.6,644.7z"
					/>
					<path
						id="BO"
						className={Styles.country}
						d="M662.5,631.4l-0.3-2l-5.4-3.3l-5.2-0.1l-9.6,1.9l-2.1,5.6l0.2,3.5l-1.5,7.7l-1-1.4l-6.4-0.3l-1.6,5.2
		l-3.7-4.6l-7.5-1.6l-4,5.8l-3.9,0.9l-3.1-8.9l-3.7-7.2l1.1-6.2l-3.2-2.7l-1.2-4.6l-3.2-4.4l2.9-6.9l-2.9-5.4l1.1-2.2l-1.2-2.4
		l1.9-3.2l-0.3-5.4v-4.6l1.1-2.1l-5.5-10.4l4.2,0.6l2.9-0.2l1.1-1.9l4.8-2.6l2.9-2.4l7.3-1.1l-0.4,4.8l0.9,2.5l-0.3,4.3l6.5,5.7
		l6.4,1.1l2.3,2.4l3.9,1.3l2.5,1.8h3.5l3.4,1.9l0.5,3.7l1.2,1.9l0.3,2.7l-1.7,0.1l2.8,7.5l10.7,0.3l-0.5,3.7l0.8,2.5l3.2,1.8l1.7,4
		l-0.6,5.1l-1.3,2.8l0.8,3.6L662.5,631.4L662.5,631.4z"
					/>
					<path
						id="BR"
						className={Styles.country}
						d="M665.8,489.6l3.1,0.6l0.6-1.4l-1-1.2l0.6-1.9l2.3,0.6l2.7-0.7l3.2,1.4l2.5,1.3l1.7-1.7l1.3,0.2
		l0.8,1.8l2.7-0.4l2.2-2.5l1.8-4.7l3.4-5.9l2-0.3l1.3,3.6l3,11.2l3.1,1.1l0.1,4.4l-4.3,5.3l1.7,1.9l10.1,1l0.2,6.5l4.3-4.2l7.1,2.3
		l9.5,3.9l2.8,3.7l-0.9,3.6l6.6-2l11,3.4l8.5-0.2l8.4,5.3l7.4,7.2l4.4,1.8l4.8,0.3l2.1,2l2,8.2l1.1,3.9l-2.1,10.6l-2.7,4.2l-7.7,8.9
		l-3.4,7.3l-4,5.5l-1.4,0.2l-1.3,4.7l0.9,12l-1.1,9.9l-0.3,4.2l-1.6,2.6l-0.5,8.6l-5.2,8.3l-0.5,6.7l-4.3,2.7l-1.1,3.9h-6l-8.5,2.4
		l-3.7,2.9l-6,1.9l-6.1,5.1l-4.1,6.4l-0.3,4.8l1.3,3.5l-0.3,6.5l-0.8,3.1l-3.4,3.6l-4.5,11.3l-4,5l-3.2,3.1l-1.5,6.1l-2.9,3.6
		l-2.1-3.6l1.8-3.1l-3.8-4.3l-4.8-3.6l-6.3-4.1l-1.9,0.2l-6.3-5l-3.4,0.7l6-8.7l5.3-6.3l3.3-2.6l4.2-3.5l-0.4-5.1l-3.2-3.8l-2.6,1.3
		l0.7-3.7l0.3-3.8l-0.3-3.6l-2.1-1.1l-2,1l-2.1-0.3l-0.8-2.4l-1.1-5.9l-1.2-1.9l-3.9-1.8l-2.2,1.3l-5.9-1.3l-0.4-8.7l-2-3.5l1.6-1.4
		l-0.8-3.6l1.3-2.8l0.6-5.1l-1.7-4l-3.2-1.8l-0.8-2.5l0.5-3.7l-10.7-0.3l-2.8-7.5l1.7-0.1l-0.3-2.7l-1.2-1.9l-0.5-3.7l-3.4-1.9h-3.5
		l-2.5-1.8l-3.9-1.3l-2.3-2.4l-6.4-1.1l-6.5-5.7l0.3-4.3l-0.9-2.5l0.4-4.8l-7.3,1.1l-2.9,2.4l-4.8,2.6l-1.1,1.9l-2.9,0.2l-4.2-0.6
		l-3.2,1.1l-2.6-0.7l-0.1-9.7l-4.4,3.7l-5-0.1l-2.3-3.5l-3.8-0.3l1-2.8l-3.3-3.9l-2.6-5.8l1.5-1.1l-0.2-2.8l3.4-1.8l-0.7-3.5
		l1.4-2.2l0.3-3l6.3-4.4l4.6-1.2l0.8-1l5.1,0.3l2.2-17.6l0.1-2.8l-0.9-3.6l-2.6-2.4l0.1-4.7l3.2-1l1.1,0.7l0.2-2.5l-3.3-0.7v-4
		l11,0.2l1.9-2.3l1.6,2.1l1,3.8l1.1-0.8l3.1,3.4l4.4-0.4l1.1-2l4.2-1.5l2.4-1.1l0.7-2.7l4.1-1.8l-0.3-1.4l-4.8-0.5l-0.7-4.1l0.3-4.3
		l-2.5-1.6l1.1-0.6l4.1,0.8l4.5,1.6l1.7-1.5l4.1-1l6.4-2.4l2.1-2.5l-0.7-1.8l3-0.2l1.2,1.4l-0.8,2.9l2,0.9l1.2,3l-1.6,2.3l-1,5.4
		l1.4,3.3l0.3,3l3.5,3l2.8,0.3l0.6-1.3l1.8-0.3l2.6-1.1l1.8-1.7l3.2,0.6L665.8,489.6L665.8,489.6z"
					/>
					<path
						id="CO"
						className={Styles.country}
						d="M584.4,426.2l-3.7,1.1l-1.6,3.2l-2.3,1.8l-1.8,2.4l-0.9,4.6l-1.8,3.8l2.9,0.4l0.6,2.9l1.2,1.5l0.3,2.5
		l-0.7,2.4l0.1,1.4l1.4,0.5l1.2,2.2l7.3-0.6l3.3,0.8l3.8,5.6l2.3-0.7l4.1,0.3l3.2-0.7l2,1.1l-1.2,3.4l-1.3,2.2l-0.6,4.6l1.1,4.3
		l1.5,1.9l0.2,1.4l-2.9,3.2l2,1.4l1.5,2.3l1.6,6.4l-1.1,0.8l-1-3.8l-1.6-2.1l-1.9,2.3l-11-0.2v4l3.3,0.7l-0.2,2.5l-1.1-0.7l-3.2,1
		l-0.1,4.7l2.6,2.4l0.9,3.6l-0.1,2.8l-2.2,17.6l-2.9-3.4l-1.7-0.1l3.5-6.6l-4.4-3l-3.4,0.6l-2.1-1.1l-3.1,1.7l-4.2-0.9l-3.5-6.7
		l-2.6-1.6l-1.8-3.1l-3.8-3l-1.5,0.6l-2.4-1.5l-2.8-2.1l-1.6,1l-4.8-0.9l-1.4-2.8l-1.1,0.1l-5.6-3.6l-0.7-2l2.1-0.5l-0.2-3.2
		l1.4-2.4l2.8-0.4l2.5-4l2.2-3.4l-2-1.5l1.2-3.7l-1.1-5.9l1.3-1.7l-0.7-5.4l-2.2-3.5l0.9-3.1l1.8,0.5l1.1-1.9l-1.1-3.8l0.7-0.9
		l2.9,0.2l4.5-4.5l2.4-0.7l0.1-2.1l1.4-5.5l3.4-2.9l3.5-0.2l0.6-1.3l4.4,0.5l4.6-3.2l2.3-1.4l2.9-3.1l2,0.4l1.3,1.7L584.4,426.2
		L584.4,426.2z"
					/>
					<path
						id="EC"
						className={Styles.country}
						d="M559,502.8l0.8,4.9l-1.7,4.1l-6.1,6.8l-6.7,2.5l-3.4,5.6L541,531l-3.1,2.7l-2.5-3.3l-2.3-0.7l-2.3,0.5
		l-0.3-2.3l1.6-1.5l-0.7-2.7l2.9-4.8l-1.3-2.8l-2.1,3l-3.5-2.9l1.1-1.8l-1-5.8l2-1l1-4l2.1-4.1l-0.3-2.6l3.1-1.4l3.9-2.5l5.6,3.6
		l1.1-0.1l1.4,2.8l4.8,0.9l1.6-1l2.8,2.1L559,502.8L559,502.8z"
					/>
					<path
						id="GY"
						className={Styles.country}
						d="M662.9,463.5l-1,5.8l-3.5,1.6l0.3,1.5l-1.1,3.4l2.4,4.6h1.8l0.7,3.6l3.3,5.6l-1.3,0.3l-3.2-0.6
		l-1.8,1.7l-2.6,1.1l-1.8,0.3l-0.6,1.3l-2.8-0.3l-3.5-3l-0.3-3l-1.4-3.3l1-5.4l1.6-2.3l-1.2-3l-2-0.9l0.8-2.9l-1.2-1.4l-3,0.2
		l-3.7-4.8l1.6-1.8v-3l3.5-1l1.4-1.2l-1.8-2.4l0.5-2.3l4.7-3.8l3.6,2.4l3.3,4.1l0.1,3.4l2.1,0.1l3,3.1L662.9,463.5L662.9,463.5z"
					/>
					<path
						id="PE"
						className={Styles.country}
						d="M590.5,529.4l-5.1-0.3l-0.8,1l-4.6,1.2l-6.3,4.4l-0.3,3l-1.4,2.2l0.7,3.5l-3.4,1.8l0.2,2.8l-1.5,1.1
		l2.6,5.8l3.3,3.9l-1,2.8l3.8,0.3l2.3,3.5l5,0.1l4.4-3.7l0.1,9.7l2.6,0.7l3.2-1.1l5.5,10.4l-1.1,2.1v4.6l0.3,5.4l-1.9,3.2l1.2,2.4
		l-1.1,2.2l2.9,5.4l-2.9,6.9l-1.1,3.3l-2.8,1.6l-5.9-3.7l-0.8-2.6l-11.7-6.4l-10.7-7.1l-4.7-3.9l-2.8-5.3l0.8-1.9l-5.4-8.4
		l-6.4-11.8l-6-12.8l-2.4-3l-2-4.7l-4.6-4.2l-4.1-2.6l1.7-2.8l-3-6.2l1.7-4.5l4.4-4l0.7,2.7l-1.6,1.5l0.3,2.3l2.3-0.5l2.3,0.7
		l2.5,3.3l3.1-2.7l0.9-4.3l3.4-5.6l6.7-2.5l6.1-6.8l1.7-4.1l-0.8-4.9l1.5-0.6l3.8,3l1.8,3.1l2.6,1.6l3.5,6.7l4.2,0.9l3.1-1.7
		l2.1,1.1l3.4-0.6l4.4,3l-3.5,6.6l1.7,0.1L590.5,529.4L590.5,529.4z"
					/>
					<path
						id="PY"
						className={Styles.country}
						d="M662.5,631.4l2,3.5l0.4,8.7l5.9,1.3l2.2-1.3l3.9,1.8l1.2,1.9l1.1,5.9l0.8,2.4l2.1,0.3l2-1l2.1,1.1
		l0.3,3.6l-0.3,3.8l-0.7,3.7l-0.3,5.6l-4.3,5l-4.2,1l-6.3-1l-5.8-1.7l4.2-9.8l-1.1-2.8l-5.9-2.5l-7.3-4.8l-4.6-1l-11.3-10.4l1.5-7.7
		l-0.2-3.5l2.1-5.6l9.6-1.9l5.2,0.1l5.4,3.3L662.5,631.4L662.5,631.4z"
					/>
					<path
						id="SR"
						className={Styles.country}
						d="M681,464.9l-3.1,5.5l0.3,4.4l2.2,3.8l-1.1,2.7l-0.5,3l-1.5,2.7l-3.2-1.4l-2.7,0.7l-2.3-0.6l-0.6,1.9
		l1,1.2l-0.6,1.4l-3.1-0.6l-3.3-5.6l-0.7-3.6H660l-2.4-4.6l1.1-3.4l-0.3-1.5l3.5-1.6l1-5.8l6.8,1.3l0.6-1.2l4.6-0.5L681,464.9
		L681,464.9z"
					/>
					<path
						id="UY"
						className={Styles.country}
						d="M699.7,718.6l-1.6,4.1l-5.4,3.5l-4.2-1.3l-2.8,0.7l-5.5-2.7l-3.6,0.2l-3.9-3.6l-0.4-4.1l0.9-1.4
		l-1.2-6.4l0.4-6.6l0.5-5.2l3.4-0.7l6.3,5l1.9-0.2l6.3,4.1l4.8,3.6l3.8,4.3l-1.8,3.1L699.7,718.6L699.7,718.6z"
					/>
					<path
						id="VE"
						className={Styles.country}
						d="M648.7,448.1l-4.7,3.8l-0.5,2.3l1.8,2.4l-1.4,1.2l-3.5,1v3l-1.6,1.8l3.7,4.8l0.7,1.8l-2.1,2.5
		l-6.4,2.4l-4.1,1l-1.7,1.5l-4.5-1.6l-4.1-0.8l-1.1,0.6l2.5,1.6l-0.3,4.3l0.7,4.1l4.8,0.5l0.3,1.4l-4.1,1.8l-0.7,2.7l-2.4,1.1
		l-4.2,1.5l-1.1,2l-4.4,0.4l-3.1-3.4l-1.6-6.4l-1.5-2.3l-2-1.4l2.9-3.2l-0.2-1.4l-1.5-1.9l-1.1-4.3l0.6-4.6l1.3-2.2l1.2-3.4l-2-1.1
		l-3.2,0.7L596,462l-2.3,0.7l-3.8-5.6l-3.3-0.8l-7.3,0.6l-1.2-2.2l-1.4-0.5l-0.1-1.4l0.7-2.4l-0.3-2.5l-1.2-1.5l-0.6-2.9l-2.9-0.4
		l1.8-3.8l0.9-4.6l1.8-2.4l2.3-1.8l1.6-3.2l3.7-1.1l-0.2,1.5l-3.4,0.8l1.7,2.9l-0.3,3.4l-2.7,3.7l1.9,5.1l2.5-0.4l1.5-4.7l-1.7-2.2
		v-4.9l7.2-2.6l-0.6-3l2.1-2.1l1.7,4.6l4,0.1l3.4,3.5l0.2,2.2h5l6.1-0.6l3.1,2.8l4.2,0.8l3.3-2l0.1-1.6l7.1-0.4l6.7-0.1l-4.9,1.9
		l1.8,3.1l4.5,0.4l4.2,3.2l0.7,5.1l2.9-0.1L648.7,448.1L648.7,448.1z"
					/>
					<path
						className={Styles.country}
						d="M655.1,837.9l6,13.6h5l3,0.2l-0.5,2.4l-3.2,1.9l-2.4-0.2l-3.1-0.5l-4.2-1.8l-5.4-0.9l-7.5-3.4l-6.4-3.2
		l-9.8-6.9l4.6,1.3l8.6,4.1l7.3,2.2l1.2-2.8l-0.3-4.2l3.4-2.6L655.1,837.9z"
					/>
					<path
						className={Styles.country}
						d="M614.4,647.8l0.9,1.6l-1.2,6.7l-5.6,3.1l1.6,10.7l-0.8,2.1l2,2.5l-3.3,4l-2.6,6l-0.9,5.9l1.7,6.2l-2.1,6.6
		l4.9,11.1l1.6,1.2l1.4,5.9l-1.6,6.2l1.4,5.4l-3,4.2l1.6,5.8l3.3,6.3l-2.5,2.3l0.2,5.7l0.8,6.5l3.3,7.8l-1.7,1.3l3.7,7.3l3.1,2.4
		l-0.8,2.7l2.8,1.2l1.4,2.4l-1.8,1.2l1.8,3.7l1.1,8.3l-0.7,5.4l1.8,3.2l-0.1,3.9l-2.7,2.8l3.1,6.5l2.6,2.3l3.1-0.4l1.9,4.6l3.5,3.6
		l12.1,0.8l4.9,1h-4.3l-1.7,1.5l-3.4,2.2l1.7,5.7l-1.9,0.1l-6.3-1.9l-7.5-4.3l-7.6-3.5l-3.2-3.9l-0.3-3.6l-4.2-4.2l-5-10.6l-0.2-6
		l3.4-4.8l-8.3-1.9l2.7-5.5l-2-10.5l6.5,2.2l-1.8-13.1l-4-1.7l1,7.9l-3.5-0.9l-1.5-9l-2-11.8l1.1-4.4l-3.3-6.2l-2.4-7.2l2.2-0.3
		l0.6-10.3l1.2-10.3l0.1-9.5l-3.3-9.7l0.6-5.3l-2.1-7.9l1.8-7.8l-0.9-12.4v-13.4l0.1-14.3l-1.5-10.5l-2.1-9.1l2.8-1.6l1.1-3.3
		l3.2,4.4l1.2,4.6l3.2,2.7l-1.1,6.2l3.7,7.2l3.1,8.9L614.4,647.8z"
					/>
					<path
						id="GF"
						className={Styles.country}
						d="M677.3,487l1.5-2.8l0.5-2.9l1-2.7l-2.1-3.8l-0.3-4.4l3.1-5.5l1.9,0.7l4.1,1.5l5.9,5.4l0.8,2.6
		l-3.4,5.9l-1.8,4.7l-2.2,2.5l-2.7,0.4l-0.8-1.8l-1.3-0.2l-1.7,1.7L677.3,487L677.3,487z"
					/>
					<path
						id="AW"
						className={Styles.country}
						d="M592.9,422l-0.5-0.2l-0.5-0.5l0.1-0.6l0.2,0.3l0.4,0.4l0.3,0.5V422L592.9,422z"
					/>
					<path
						id="CW"
						className={Styles.country}
						d="M599,424.5h-0.3l-0.4-0.3l-0.3-0.1l-0.3-0.3l-0.1-0.2l-0.3-0.1l-0.2-0.4l-0.3-0.3l0.1-0.5l0.5,0.3
		l0.1,0.5l0.4,0.4l0.7,0.2l0.2,0.3l0.3,0.4L599,424.5L599,424.5z"
					/>
					<path
						className={Styles.country}
						d="M697.4,836.2l0.4-0.1v0.4l-0.8-0.3l0.3-0.3l0.2-0.1L697.4,836.2z"
					/>
					<path
						className={Styles.country}
						d="M696.7,834.8l0.2,0.2l0.4,0.1l0.1,0.4l-0.3,0.1l-0.1-0.4l-0.2-0.2L696.7,834.8z"
					/>
					<path
						className={Styles.country}
						d="M702.9,834l0.2,0.1l-0.1,0.4l-0.3-0.1l-0.2-0.3l0.3-0.2L702.9,834z"
					/>
					<path
						className={Styles.country}
						d="M690.8,833.1v0.2l0.1,0.3l-0.4,0.1l-0.3-0.1l-0.2-0.2l-0.3-0.1l-0.1-0.4h0.2l0.1-0.2l0.3-0.1l0.2,0.1l-0.3,0.3
		l0.3,0.1l0.3-0.3L690.8,833.1z"
					/>
					<path
						className={Styles.country}
						d="M693.7,831.8l-0.4-0.2l-0.5-0.3L692,831l0.1-0.4l-0.7-0.1l-0.3-0.1l-0.3-0.4l0.2-0.1l0.8,0.3l0.8,0.4l0.5,0.1
		l0.4,0.1l0.3-0.3l0.1-0.3l0.2,0.1l0.4-0.2l0.1,0.2h0.5v0.3h0.3l0.9-0.2l0.1,0.2h0.3l0.4-0.1l-0.3-0.2l-0.1-0.3h0.4l0.6,0.2l0.1,0.4
		l-0.4,0.5l0.1,0.3l-0.2,0.2h-0.4l-0.3,0.8l-0.3,0.3l-0.3,0.6l-0.1,0.3l-0.5,0.3l-0.1-0.2h-0.4v0.3l-0.8-0.1L694,834l0.3,0.4
		l-0.1,0.1l-0.2,0.4h-0.4l-0.3,0.4l-0.3,0.1h-0.2l-0.2-0.3l-0.4-0.3v-0.2h-0.5l0.6,0.4h-0.4l-0.5-0.3l-0.2-0.1l-0.4-0.2l-0.3-0.2
		V834l0.7,0.2l0.6-0.2l-0.5-0.2l-0.1-0.1h0.9l0.4,0.1l0.3,0.2v-0.2l0.4-0.1l0.1-0.3l-0.4-0.2l-0.2-0.5l0.1-0.2h0.6l0.2,0.1l0.4-0.1
		l-0.1-0.3h-0.8v0.1l-0.4,0.2h-0.6l-0.5-0.3v-0.3l0.7,0.2l0.6-0.2h0.3L693.7,831.8l0.4,0.2v-0.1L693.7,831.8z"
					/>
					<path
						className={Styles.country}
						d="M695.3,829.9h0.3l0.3,0.3l-0.3,0.1h-0.3V829.9z"
					/>
					<path
						className={Styles.country}
						d="M694.3,829.9H694l-0.1-0.3l0.4,0.1V829.9z"
					/>
					<path
						className={Styles.country}
						d="M692.9,829.7l0.6-0.1l0.2,0.3l-0.3,0.4l-0.2-0.1l-0.3,0.1l-0.3-0.2l0.3-0.2l0.3,0.2l0.1-0.2l-0.5-0.1
		L692.9,829.7z"
					/>
					<path
						className={Styles.country}
						d="M700.1,829.8h0.6l0.1-0.1l0.5-0.1l0.4,0.2l0.4,0.3l0.1,0.3v0.1l-0.5,0.1l-0.2-0.3l-0.2-0.1l-0.1,0.3l0.2,0.1
		l0.1,0.3l0.3-0.2l0.2,0.1l0.1,0.4l0.3,0.1l0.1-0.1l0.5,0.3l0.1-0.4l-0.8-0.2l0.1-0.5l0.3-0.2l1.5-0.1l0.7,0.6l0.2,0.1v0.4l-0.6-0.1
		l-0.5-0.2l-0.6,0.2l0.3,0.2l0.4,0.1l0.9,0.1l0.3,0.1l0.1,0.2l-0.2,0.5h-0.5l-0.1,0.1l-0.7,0.1l-0.5,0.2l0.3,0.2l-0.9,0.4l-0.4-0.1
		l-0.5,0.1h-0.7l-0.6-0.2l-0.6-0.4v0.3l0.3,0.2l0.6,0.2l0.3,0.3l0.2-0.1l0.4,0.1l0.4,0.3l-0.2,0.1l0.2,0.5H702l-0.3-0.3l-0.4-0.1
		l-0.5,0.4l-0.2-0.3l-0.3-0.2H700l-0.5-0.2l-0.2,0.1l0.2,0.4h0.3l0.2,0.2l-0.2,0.1l0.7,0.6l-0.2,0.1l-0.4-0.2h-0.3l-0.3-0.3
		l-0.3-0.1l-0.3-0.1l-0.1,0.6l0.5,0.1v0.3l-0.1,0.2l-0.4-0.1l-0.1-0.2l-0.5-0.2l-0.1-0.2h-0.2l-0.4-0.5l0.3-0.4v-0.2l-0.4-0.5v-0.2
		l0.5-0.2l-0.1-0.2l0.5-0.1l-0.1-0.3l0.6-0.4l0.6-0.2l0.4,0.4v0.4l0.3-0.1l-0.5-0.6l0.1-0.3l-0.6-0.2l-0.4-0.3l0.1-0.2l0.4,0.2
		l0.1-0.5l-0.7-0.2l0.1-0.3l0.2,0.1l0.3-0.2h0.3l0.2-0.3l-0.3-0.3h0.4L700.1,829.8z"
					/>
					<path
						className={Styles.country}
						d="M695.1,829.4h0.7l0.2,0.2l0.2-0.2l0.2,0.1l0.2,0.3l-0.2,0.1l-0.4-0.1l-0.4-0.2l-0.8-0.1l0.1-0.2L695.1,829.4z"
					/>
					<path
						className={Styles.country}
						d="M688.3,828.3l-0.5-0.1l0.1-0.2L688.3,828.3z"
					/>
					<path
						id="GD"
						className={Styles.country}
						d="M639.2,424.5l-0.4,0.3l-0.2-0.1l-0.1-0.5l0.2-0.5l0.3-0.4h0.3l0.1,0.3l-0.1,0.7L639.2,424.5z"
					/>
					<path
						className={Styles.country}
						d="M642.8,432.2l-0.2,0.5l-0.3,0.4l-0.2,0.1l-0.1,0.4l0.2,0.6l-0.2,0.1v0.7l0.2,0.3l0.2,0.2l-0.2,0.2l-0.1,0.3
		v0.7l-0.2,0.1l-0.4,0.2l-0.5,0.1h-0.3l-0.5,0.1l-0.5-0.1l-0.4,0.1l-0.3-0.1l-0.5,0.2l-0.4-0.2h-0.6l-0.3,0.2l-0.3-0.1l0.5-0.2
		l0.3-0.3l0.4-0.1l0.4-0.3l0.2-0.3l0.6,0.1l0.3-0.4l-0.2-0.8l0.2-0.4V434v-0.2l-0.3-0.4l-0.3-0.1l-0.4-0.1l-0.1-0.1l0.4-0.3l1.1-0.1
		l0.2-0.2h1.1l0.1-0.1l1.2-0.2H642.8z"
					/>
					<path
						className={Styles.country}
						d="M645.2,429l-0.1,0.5l-0.3,0.1l-0.4,0.3h-0.2l-0.7,0.4l-0.2-0.2l0.3-0.3l0.7-0.5L645.2,429z"
					/>
					<path
						id="BQBO"
						className={Styles.country}
						d="M602,424.6h-0.2l-0.1-0.5l0.1-0.3l-0.1-0.3l-0.4-0.1l-0.3-0.3l0.1-0.3l1.2,0.6l-0.1,0.2v0.4
		l-0.2,0.2V424.6L602,424.6z"
					/>
					<rect
						id="_x31_"
						x="673.5"
						y="724.1"
						className={Styles.country}
						width="0"
						height="0"
					/>
				</g>
				<g
					id="Asia"
					className={Styles.continent}
					data-active={active == "Asia"}
					onClick={() => setActiveContinent("Asia")}
				>
					<path
						className={Styles.country}
						d="M1218.8,61.3l-2.2,0.4l-13.5-0.7l-2.3-2.3l-7.9-1.4l-2-2.9l3.5-1.1l-1.6-2.8l5.4-4.4l-4-0.6l6.7-4.5l-2.5-2.3
		l6.8-2.6l10.3-3.2l11.7-0.9l5-1.8l6.7-0.6l4.2,1.9l-1.3,1.5l-11.4,2.5l-9.9,2.3l-8.7,4.8l-2.8,5l-3.3,5l3.1,4.3L1218.8,61.3z"
					/>

					<path
						className={Styles.country}
						d="M1367.1,23.1l-18.1,1.8l-1.3-6.1l2.2-0.5l3.1,0.3l12.6,2.6L1367.1,23.1z"
					/>
					<path
						className={Styles.country}
						d="M1164.8,13.1l-4.3,0.5l-2.9,0.4v0.7l-3.6,0.7l-4.5-1l1.1-1.4l-7.8-0.1l6.3-0.8h5.2l1.5,1.1l1.3-1l2.8-0.7
		l5.8,0.9L1164.8,13.1z"
					/>
					<path
						className={Styles.country}
						d="M1345.1,20.4L1338,21l-11.7-1.3l-8.1-1.7l-6.6-3.2l-5.9-0.8l5.4-2.9l6.2-0.9l9.6,2l13.7,4.2L1345.1,20.4z"
					/>
					<path
						className={Styles.country}
						d="M1661.7,231l-1.4-1.1l-1.9-3.3l2.5-0.1l-3.9-7.5l-4.7-5.4l2.9-2.2l6.9,1.1l-0.6-6.2l-2.8-6.8l0.3-2.3l-1.3-5.7
		l-6.9,1.9l-2.6,2.4h-7.6l-6-5.8l-9-4.5l-10-2l-6.2-6l-4.5-3.8l-3.8-2.7l-7.7-6.2l-6-2.2l-8.6-1.9l-6.2,0.2l-5.1,1.1l-1.7,3.1
		l3.7,1.4l2.5,3.4l-1.3,2l0.2,6.5l1.9,2.8l-4.4,3.9l-7.4-2.4l-5.6,0.6l-3.9-2.1l-3.4-0.7l-4.4,4.4l-5.9,1l-3.6,1.6l-6.8-1h-4.6
		l-4.9-3.2l-6.6-2.9l-5.4-0.9l-5.7,0.8l-4,1.2l-8.5-2.6l-3.6-4.6l-6.7-1.6l-4.8-0.8l-7-2.5l-1.3,6.4l4,3.6l-2.4,4.4l-8-1.6l-5-0.2
		l-4.8-2.9l-5.2-0.1l-5.3-1.9l-5.9,2.9l-6.7,5.3l-4.7,1.1l-1.7,0.5l-4.4-3.8l-6,0.9l-3.3-2.7l-4-1.2l-4.1-3.6l-3.3-1.1l-6.2,1.6
		l-8.4-3.5l-1.1,3.2l-18.3-15.6l-8.4-4.7l0.8-2l-9.2,5.8l-4.4,0.3l-1.1-3.3l-7.1-2.1l-4.3,1.5l-4.4-6.3l-9.1-1.3l-3.1,2.5l-10.9,2.3
		l-1.7,1.5l-17,2.1l-1.4,2.1l5.1,4.2l-4,1.6l1.5,1.6l-3.5,3l9.4,4.3l-0.2,2.9l-7-0.3l-0.8,1.9l-7.3-3.2l-7.7,0.1l-4.4,2.6l-6.6-2.5
		l-12-4.3l-7.6,0.2l-8.1,6.7l0.6,4.5l-6-3.6l-2.2,6.9l1.8,1.2l-1.7,4.8l5.3,4.2l3.6-0.2l4.3,4.2l0.2,3.2l2.8,1.1l-1.4,3.7l-4.6,1
		l-3.6,6.5l6,6.1l0.4,4.2l7.3,7.5l-2.5,2.6l-0.6,1.6l-2.4-0.5l-4.3-3.8l-1.5-0.2l-3.6-1.5l-2.1-2.6l-5.1-1.3l-2.9,1l-1.2-1.2
		l-7.6-3.1l-7.7-1l-4.6-1.1l-0.4,0.8l-7.6-5.4l-6.2-2.4l-5.1-3.7l3.6-1l3.2-5.2l-3.3-2.5l6.9-2.6l-0.4-1.4l-4.3,1l-0.4-2.7l2.2-1.8
		l4.8-0.4l0.3-2.1l-1.8-3.5l1.3-3.2l-0.4-1.9l-7.7-2l-2.9,0.1l-3.6-2.9l-3.6,1l-6.7-2.2l-0.1-1.2l-2.3-2.7l-3.9-0.3l-0.8-1.9
		l0.9-1.3l-3.8-3.5l-4.9,0.6l-1.5-0.3l-1,1.4l-1.8-0.2l-2-4l-1.5-2l0.8-0.6l3.9,0.2l1.6-1.3l-1.7-1.6l-3.4-1.1v-1.1l-2.1-1.1l-3.8-4
		l0.7-1.6l-1.1-2.9l-4.8-1.4l-2.4,0.7l-0.9-1.5l-5.3-1.5l-2.1-3.6l-1-2.9l-2.5-1.3l1.6-1.9l-2.4-5.6l2.6-3.4l-0.9-1l4.5-3.3
		l-5.4-2.8l8-7.4l3.4-3.4l0.9-2.9l-7.4-3.9l0.9-3.8l-4.9-4.2l1.7-4.8l-6.5-6.3l2.9-4.2l-7.3-3.7l-0.4-3.8l3.2-0.5l6.4-2.1l3.7-1.9
		l7.7,3.2l11.7,1.3l17.7,6.2l4.1,2.6l1.5,3.7l-3.7,2.9l-6.5,1.5l-20.2-4.2l-2.9,0.7l8.1,4.1l1,2.6l1.9,5.8l6.1,1.7l3.8,1.5l-0.2-2.8
		l-3.4-2.4l2.1-2.2l11.6,3.6l3.2-1.4l-4.3-4.2l8.1-5.4l4.1,0.3l4.6,1.9l1.1-3.8l-4.7-3.3l0.8-3.3l-4.3-3.4l12.3,1.8l3.6,3l-5.1,0.7
		l1.2,3.1l4.1,1.9l6-1.2l-0.4-3.5l7.7-2.7l12.4-4.6l3.2,0.2l-2.5,3.3l5.4,0.6l2.1-1.9l7.7-0.1l5-2.3l6.4,3.3l2.8-3.6l-6-3.1l1.2-1.8
		l13,1.6l6.6,1.7l18.6,6.2l1.1-2.8l-5.9-2.9l-0.8-1.1l-5.3-0.6l-0.2-2.5l-4.7-4.2l-1.1-1.7l4.3-4.7l-0.6-4.8l2.2-1l11.4,1.4l2.9,2.9
		l-0.9,4.2l3.7,1.7l3.8,3.7l3.8,7.4l6.7,3.3l0.5,3.7l-3.5,7.8l5.3,0.8l0.5-2l3.8-1.4l-0.5-2.7l1.9-2.7l-4.4-3.1l-0.4-3.6l-4.8-0.4
		l-2.9-3l-0.4-5.4l-8.2-4.3l4.7-3.5l-3.7-3.7l1.9-0.1l4.2,2.8l2.1,5.1l5,1l-4.6-3.8l5.1-2.1l8.1-0.3l9.6,3l-6.9-4.3l-4.9-5.5l5.9-1
		l9.5,0.2l7.8-0.7l-5.6-2.6l1.3-3.3l4.2-0.2l4.9-2.4l9.3-0.7l-0.2-1.4l9.3-0.4l4.3,1.1l5.4-2.6l7,0.1l-1.5-2.2l0.9-2l6.2-2l8.3,1.6
		l-3.5,1.2l9.4,0.7l4.1,2.4l1.9-1.2l10.9,0.1l11.6,2.4l5.5,1.8l2.5,2.6l-2.4,1.5l-6.6,2.8l-1.1,1.5l5.6,0.7l7.2,1.3l2.4-1l5.8,3.3
		l0.2-1.3l5.2-0.8l13.6,0.8l3.8,2.4l17.4,0.8l-4.8-3.9l9.5,0.9l6.1-0.1l9.9,2.7l6,3.3l0.4,2.2l10.1,4.2l9,2.1l-3-5.5l9.4,2.3l5-1.4
		l9.8,1.6l1-1.4l7.6,0.7l-9.4-4.9l2.1-2.2l40.4,3.4l7.9,3.1l16.3,4l14.8-1l9.3,0.9l6.6,2.2l5.1,3.9l7.3,1.5l3.9-1.1l7-0.1l9.3,1
		l6.9-0.6l14.3,4.8l2.5-1.7l-8.5-3.4l-1.8-2.4l15.3,1.5l8-0.3l15.8,2.5l9.5,2.4l33.2,22.1l-2,2.5l-6.2-0.4l8.2,3l9.1,4.7l4.2,1.5
		l3.8,2.4l1,1.5l-10-1.2l-6.6,4.3l-3,0.7l-1.6,4.1l-2,3.6l1.7,2.7l-11.5-4.1l-6.1,4.6l-4.7-2.2l-1.4,2.6l-7-0.9l3.1,3.9l1.1,5.8
		l2.9,2.4l6.8,1.3l9.1,8.7l-4.1,0.3l3.4,5l4.7,2.6l-5,3.1l5.4,7l-5.7,1.5l4.5,6.2l-1.7,5.8l-5.7-4.3l-10.5-8.9l-16.2-13.6l-6.5-8.3
		l0.1-3.6l-2.8-2.8l5.7-1.3l-0.2-7.5l0.7-6l2.5-4.7l-6.6-8.2l-4.7,0.5l3.2,4.8l-3.6,6.4l-12.3-7.2l-9.1,2v9.8l7.7,3.6l-8.5,1.6
		l-6.4,0.6l-4.3-4.3l-8-0.9l-2.5,2.9l-15.1-1l-13.2,1.7l-3.5,11.7l-5,14.2l8.2,0.8l5.7,3.8l5.8,1.3l0.4-3l5.6,0.4l12.8,6.7l4.7,5.2
		l1.4,6.2l5.5,7.4l5.3,9.9l-1,9.1l1.3,4.3l-2,7.4l-2.1,7.3l-0.9,3.7l-4.6,3.7l-3.1,0.1l-5.2-3.1l-4.1,4.7L1661.7,231z"
					/>
					<path
						id="AF"
						className={Styles.country}
						d="M1383,261.6l1.5,1.8l-2.9,0.8l-2.4,1.1l-5.9,0.8l-5.3,1.3l-2.4,2.8l1.9,2.7l1.4,3.2l-2,2.7l0.8,2.5
		l-0.9,2.3l-5.2-0.2l3.1,4.2l-3.1,1.7l-1.4,3.8l1.1,3.9l-1.8,1.8l-2.1-0.6l-4,0.9l-0.2,1.7h-4.1l-2.3,3.7l0.8,5.4l-6.6,2.7l-3.9-0.6
		l-0.9,1.4l-3.4-0.8l-5.3,1l-9.6-3.3l3.9-5.8l-1.1-4.1l-4.3-1.1l-1.2-4.1l-2.7-5.1l1.6-3.5l-2.5-1l0.5-4.7l0.6-8l5.9,2.5l3.9-0.9
		l0.4-2.9l4-0.9l2.6-2l-0.2-5.1l4.2-1.3l0.3-2.2l2.9,1.7l1.6,0.2h3l4.3,1.4l1.8,0.7l3.4-2l2.1,1.2l0.9-2.9l3.2,0.1l0.6-0.9l-0.2-2.6
		l1.7-2.2l3.3,1.4l-0.1,2l1.7,0.3l0.9,5.4l2.7,2.1l1.5-1.4l2.2-0.6l2.5-2.9l3.8,0.5L1383,261.6L1383,261.6z"
					/>
					<path
						id="AE"
						className={Styles.country}
						d="M1296.2,336.7l1.3,5.1h-2.8v4.2l1.1,0.9l-2.4,1.3l0.2,2.6l-1.3,2.6v2.6l-1,1.4l-16.9-3.2l-2.7-6.6
		l-0.3-1.4l0.9-0.4l0.4,1.8l4.2-1l4.6,0.2l3.4,0.2l3.3-4.4l3.7-4.1l3-4L1296.2,336.7L1296.2,336.7z"
					/>
					<path
						className={Styles.country}
						d="M1235.3,236.2l2.5-2.6l3.5,3.3l3.6,4.6l2.5,0.3l1.9,1.7l-4.2,0.5l0.1,5l-0.4,2.2l-1.7,1.5l0.8,3.1l-1.3,0.4
		l-3.9-3.4l1.2-3.1l-1.9-1.9l-1.9,0.5l-5.3,4.7l-0.9-4.5l-2.5-1l-2.6-1.7l1.1-2.1l-3.2-2.2l0.7-1.6l-2.2-1.1l-1.4-1.6l1.1-1.1
		l4.2,1.9l2.9,0.3l0.6-0.7l-3.3-3.5l1.2-0.8l1.5,0.2l4.3,3.8l2.4,0.5L1235.3,236.2z"
					/>
					<path
						id="BD"
						className={Styles.country}
						d="M1500.6,360.3l0.6,4.6l-2.1-1l1.1,5.2l-2.1-3.3l-0.8-3.3l-1.5-3.1l-2.8-3.7l-5.2-0.3l0.9,2.7l-1.2,3.5
		l-2.6-1.3l-0.6,1.2l-1.7-0.7l-2.2-0.6l-1.6-5.3l-2.6-4.8l0.3-3.9l-3.7-1.7l0.9-2.3l3-2.4l-4.6-3.4l1.2-4.4l4.9,2.8l2.7,0.3l1.2,4.5
		l5.4,0.9l5.1-0.1l3.4,1.1l-1.6,5.4l-2.4,0.4l-1.2,3.6l3.6,3.4l0.3-4.2h1.5L1500.6,360.3L1500.6,360.3z"
					/>
					<path
						id="BT"
						className={Styles.country}
						d="M1488.8,323.5l2.6,2.1l0.5,3.9l-4.5,0.2l-4.7-0.4l-3.2,1l-5.5-2.5l-0.4-1.2l2.6-4.8l2.6-1.6l4.3,1.4
		l2.9,0.2L1488.8,323.5L1488.8,323.5z"
					/>
					<path
						className={Styles.country}
						d="M1602.2,381.9l-4.3,3.1l-4.9-2l-1-5.5l2.2-2.9l5.8-1.8l3.3,0.1l1.6,2.5l-2,2.8L1602.2,381.9z"
					/>
					<path
						className={Styles.country}
						d="M1625.6,185.5l9,4.5l6,5.8h7.6l2.6-2.4l6.9-1.9l1.3,5.7l-0.3,2.3l2.8,6.8l0.6,6.2l-6.9-1.1l-2.9,2.2l4.7,5.4
		l3.9,7.5l-2.5,0.1l1.9,3.3l-5.5-3.8v3.6l-6.4,2.7l2.8,3.4l-4.6-0.3l-3.6-2l-1.1,4.6l-3.9,3.4l-2.1,4.1l-6.3,1.8l-2.4,3l-4.8,1.8
		l1.3-3l-2.3-2.5l2-4.3l-4.5-3.3l-3.4,2.2l-3.6,4.5l-1.3,4.1l-5,0.3l-1.3,3l4.8,4.3l4.8,1.1l1.4,2.8l5.1,1.9l3.8-4.6l5.9,2.5
		l3.5,0.2l2.3,3.3l-6.7,1.8l-1,3.5l-3.8,3.2l-0.9,4.5l7.1,3.5l4.6,6.3l5.5,5.9l5.5,4.9l1.6,4.8l-2.8,1.8l2.4,3.4l3.9,2l0.8,5.2
		l0.1,5.1l-2.8,0.6l-2.1,6.9l-2.3,8.5l-3.4,7.6l-6.4,5.9l-6.6,5.5l-6.1,0.7l-2.9,2.8l-2.3-2l-2.5,3.1l-7.2,3.3l-5.8,0.9l-0.7,6.8
		l-3.1,0.4l-2.2-4.7l0.9-2.4l-7.8-2.1l-2.4,1.1l-5.9-1.7l-3.1-2.6l0.3-3.7l-5.3-1.2l-3.1-2.4l-4.1,3.4l-5.3,0.8l-4.4-0.1l-2.7,1.6
		l-2.7,0.9l2,7.4l-3-0.2l-0.8-1.5l-0.5-2.7l-3.8,1.9l-2.6-1.2l-4.5-2.4l0.8-5.3l-3.7-1.3l-2.4-5.9l-5.6,1.1l-0.7-7.6l4.2-5.4
		l-0.9-5.3l-1.3-4.9l-2.7-1.5l-2.7-3.8l-3.1,0.5l-6.1-1l1.2-2.7l-3.6-4l-3.2,2.7l-4.9-1.5l-5.4,4l-3.9,4.8l-4.2,0.8l-2.8-1.7
		l-2.9-0.2l-4.3-1.4l-2.6,1.6l-2.6,4.8l-1.5-5.1l-3.1,1.4l-6.5-0.7l-6.5-1.4l-5-2.9l-4.5-1.2l-2.5-3.1l-3.3-0.9l-6.4-4.2l-4.8-2
		l-1.9,1.5l-8.6-4.5l-6.2-4l-3.2-7.1l4.1,0.9l-0.6-3.3l-3-3.3l-0.8-5.2l-7.7-7.6l-9.5-2.5l-2.9-5l-4.7-3l-1.5-1.8l-1.8-3.6l-0.5-2.5
		l-3.7-1.5l-1.5,0.7l-3.1-6l1.1-1.4l-1.2-1.5l4.1-3l3.2-1.3l5.8,0.9l0.6-4.1l6.4-0.7l1-2.6l6.9-3.4l0.2-1.4l-1.7-3.7l2.9-1.6
		l-8.8-11l9.1-2.5l2-1.4l-1-11.3l10.8,2.1l1.6-2.9l-2.5-6.2l3.8-0.6l1.9-4.2l1.7-0.5l3.3,4.4l5.7,3.3l8.2,2.3l5.8,5.1l1.4,7.3l3,2.8
		l6.5,1.1l7.2,0.8l8,4l3.4,0.7l5,5.8l4.7,3.8l5.6-0.2l11.3,1.5l6.4-0.9l5.6,0.9l9.4,3.9h6.2l3.3,1.9l4.4-3.4l7.2-2.2l7.6-0.2
		l4.9-2.2l2-3.4l2.4-2.2l-1.9-2.1l-2.9-2.4l0.5-4.1l3.2,0.6l5.9,1.3l3.2-3.4l6.4-2.4l1.3-4.2l2.5-1.8l6.8-0.8l4.4,0.7l-0.8-2.2
		l-7.2-4.4l-5.2-2l-2.5,2.3l-5.5-1l-2.3,0.8l-2.8-2.5l-0.3-6.3l-0.6-4.7l7.4,2.4l4.4-3.9l-1.9-2.8l-0.2-6.5l1.3-2l-2.5-3.4l-3.7-1.4
		l1.7-3.1l5.1-1.1l6.2-0.2l8.6,1.9l6,2.2l7.7,6.2l3.8,2.7l4.5,3.8l6.2,6L1625.6,185.5z"
					/>
					<path
						id="GE"
						className={Styles.country}
						d="M1215.7,227.9l5.1,1.3l2.1,2.6l3.6,1.5l-1.2,0.8l3.3,3.5l-0.6,0.7l-2.9-0.3l-4.2-1.9l-1.1,1.1l-7,1
		l-5.6-3.2l-5.5,0.3l0.3-2.7l-2.1-4.3l-3.4-2.4l-3-0.7l-2.2-1.9l0.4-0.8l4.6,1.1l7.7,1l7.6,3.1l1.2,1.2L1215.7,227.9L1215.7,227.9z"
					/>
					<path
						className={Styles.country}
						d="M1667.5,567.6l-2.4,0.1l-7.1-4.5l5.4-1.3l2.8,2l1.8,1.9L1667.5,567.6z"
					/>
					<path
						className={Styles.country}
						d="M1692.3,558.9l0.5,1.3l-0.1,1.9l-4.1,4.8l-5,1.4l-0.6-0.7l0.7-2.2l2.8-3.9L1692.3,558.9z"
					/>
					<path
						className={Styles.country}
						d="M1652.7,553.8l1.9,1.7l3.6-0.5l1.2,2.7l-6.7,1.3l-3.9,0.9l-3.1-0.1l2.2-3.7h3.2L1652.7,553.8z"
					/>
					<path
						className={Styles.country}
						d="M1681,553.8l-1.1,3.6l-8.6,1.8l-7.5-0.8l0.2-2.4l4.6-1.3l3.4,1.9l3.8-0.5L1681,553.8z"
					/>
					<path
						className={Styles.country}
						d="M1600.8,545.3l10.8,0.7l1.4-2.7l10.3,3.1l1.8,4.2l8.4,1.2l6.7,3.8l-6.6,2.4l-6.1-2.6l-5.1,0.2l-5.8-0.5
		l-5.2-1.1l-6.4-2.5l-4.1-0.6l-2.4,0.8l-10.2-2.7l-0.8-2.7l-5.1-0.5l4.2-6.1l6.8,0.4l4.4,2.5l2.4,0.5L1600.8,545.3z"
					/>
					<path
						className={Styles.country}
						d="M1748.7,541.7l-3.2,4.4l-0.2-4.8l1.1-2.3l1.3-2.2l1.2,1.9L1748.7,541.7z"
					/>
					<path
						className={Styles.country}
						d="M1707.3,524l-2.2,2.2l-3.8-1.2l-1-2.8l5.7-0.3L1707.3,524z"
					/>
					<path
						className={Styles.country}
						d="M1725.7,521.7l1.8,4.9l-4.6-2.7l-4.7-0.5l-3.3,0.4l-3.9-0.2l1.5-3.5l7-0.3L1725.7,521.7z"
					/>
					<path
						className={Styles.country}
						d="M1785.5,518.5l-1,20.9l-1.6,21l-4.6-5.3l-5.6-1.3l-1.5,1.8l-7.2,0.2l2.8-5.2l3.7-1.8l-1-7l-2.3-5.3l-10.7-5.5
		l-4.6-0.5l-8.3-6l-1.8,3.2l-2.2,0.5l-1.1-2.3l0.1-2.8l-4.2-3.2l6.2-2.3l4,0.1l-0.4-1.7h-8.3l-2.2-3.8l-5-1.2l-2.3-3.2l7.6-1.5
		l2.9-2.1l9.1,2.6l0.9,2.4l1.3,10.4l5.7,3.8l5-6.8l6.6-3.8h5l4.8,2.2l4.1,2.3L1785.5,518.5z"
					/>
					<path
						className={Styles.country}
						d="M1696.4,492.7l-4.5,6.4l-4.3,1.2l-5.4-1.2l-9.5,0.3l-4.9,0.9l-0.8,4.9l5,5.7l3.1-2.9l10.6-2.2l-0.5,2.9
		l-2.5-0.9l-2.5,3.8l-5.1,2.5l5.1,8.2l-1.1,2.2l4.8,7.4l-0.3,4.2l-3.1,1.9l-2.1-2.3l3-5.2l-5.7,2.5l-1.3-1.8l0.8-2.5l-3.9-3.8
		l0.7-6.2l-3.9,1.9l0.2,7.5l-0.2,9.2l-3.7,0.9l-2.3-1.8l1.9-5.9l-0.6-6.2l-2.4-0.1l-1.6-4.4l2.5-4.2l0.9-5.1l3-9.7l1.1-2.6l4.8-4.8
		l4.5,1.9l7.1,0.9l6.5-0.3l5.6-4.6L1696.4,492.7z"
					/>
					<path
						className={Styles.country}
						d="M1716,494.6l-0.3,5.6l-2.9-0.7l-0.9,3.9l2.3,3.4l-1.6,0.8l-2.2-4.1l-1.7-8.2l1-5.1l1.8-2.3l0.5,3.5l3.4,0.5
		L1716,494.6z"
					/>
					<path
						className={Styles.country}
						d="M1608,488.9l1,4.3l3.9,3.7l3.7-1.3l3.6,0.4l3.3-3.2l2.7-0.6l5.4,1.8l4.6-1.4l2.6-8.9l2.1-2.2l1.7-7.3h6.5
		l5,1.1l-3,5.8l4.4,6l-0.9,3l6.4,5.9l-6.7,0.8l-1.8,4.4l0.2,5.8l-5.5,4.4l-0.4,6.4l-2.5,9.8l-0.7-2.3l-6.6,2.9l-2.1-3.9l-4-0.4
		l-2.8-2.1l-6.8,2.4l-1.9-3.2l-3.8,0.4l-4.6-0.7l-0.6-8.6l-2.8-1.8l-2.7-5.5l-0.8-5.6l0.6-6L1608,488.9z"
					/>
					<path
						className={Styles.country}
						d="M1585.2,539.4l-6.2,0.1l-4.5-5.3l-7.1-5.3l-2.3-3.9l-4.1-5.2l-2.7-4.8l-4.2-9l-4.9-5.4l-1.7-5.5l-2.2-5l-5.2-4
		l-3.1-5.5l-4.4-3.6l-6.2-7.1l-0.6-3.3l3.6,0.3l8.9,1.2l5.2,6.3l4.6,4.4l3.2,2.6l5.5,6.9l5.8,0.1l4.8,4.4l3.4,5.4l4.3,3l-2.3,5.2
		l3.3,2.2l2,0.2l0.9,4.5l1.9,3.5l4.1,0.6l2.6,4.1l-1.7,8L1585.2,539.4z"
					/>
					<path
						id="IN"
						className={Styles.country}
						d="M1427.6,308l-2.8,3l-0.9,6l5.8,2.4l5.8,3.1l7.8,3.6l7.7,0.9l3.8,3.2l4.3,0.6l6.9,1.5l4.6-0.1l0.1-2.5
		l-1.5-4.1l-0.2-2.7l3.1-1.4l1.5,5.1l0.4,1.2l5.5,2.5l3.2-1l4.7,0.4l4.5-0.2l-0.5-3.9l-2.6-2.1l4.2-0.8l3.9-4.8l5.4-4l4.9,1.5
		l3.2-2.7l3.6,4l-1.2,2.7l6.1,1l1,2.4l-1.7,1.2l1.4,3.9l-4.2-1.1l-6.2,4.4l0.9,3.7l-2,5.4l0.3,3.1l-1.6,5.3l-4.6-1.5l0.9,6.7l-1,2.2
		l1,2.7l-2.5,1.5l-4.4-10.2h-1.5l-0.3,4.2l-3.6-3.4l1.2-3.6l2.4-0.4l1.6-5.4l-3.4-1.1l-5.1,0.1l-5.4-0.9l-1.2-4.5l-2.7-0.3l-4.9-2.8
		l-1.2,4.4l4.6,3.4l-3,2.4l-0.9,2.3l3.7,1.7l-0.3,3.9l2.6,4.8l1.6,5.3l-0.5,2.4l-3.8-0.1l-6.6,1.3l0.9,4.8l-2.4,3.8l-7.5,4.4
		l-5.3,7.5l-3.8,4.1l-5,4.2l0.3,2.9l-2.6,1.6l-4.8,2.3l-2.6,0.3l-1.2,4.9l1.9,8.4l0.7,5.3l-1.9,6.1l0.7,10.9l-2.9,0.3l-2.3,4.9
		l1.9,2.2l-5.1,1.8l-1.7,4.3l-2.2,1.9l-5.6-6l-3.1-9l-2.5-6.5l-2.2-3l-3.4-6.2l-2-8l-1.4-4l-5.9-8.8l-3.5-12.5l-2.6-8.2l-0.8-7.8
		l-1.7-6l-7.7,3.9l-4-0.8l-8.1-7.8l2.4-2.3l-1.9-2.5l-7.1-5.5l3.2-4.3h12.1l-1.8-5.5l-3.5-3.2l-1.4-5l-4-2.8l4.9-6.8l6.5,0.5
		l4.5-6.7l2.2-6.5l3.9-6.5l-1-4.6l3.8-3.7l-5.1-3.1l-2.9-4.4l-3.3-5.6l2-2.8l8.5,1.6l5.7-1l3.8-5.4l7.7,7.6l0.8,5.2l3,3.3l0.6,3.3
		l-4.1-0.9l3.2,7.1l6.2,4L1427.6,308L1427.6,308z"
					/>
					<path
						id="IR"
						className={Styles.country}
						d="M1229,253.2l1.8-0.2l5.3-4.7l1.9-0.5l1.9,1.9l-1.2,3.1l3.9,3.4l1.3-0.4l2.5,4.8l5.3,1.3l4.3,3.2
		l7.7,1.1l8-1.7l0.2-1.5l4.4-1.2l3-3.7l3.6,0.2l2-1.2l3.9,0.6l6.6,3.3l4.3,0.7l7.3,5.6l4,0.3l1.7,5.3l-0.6,8l-0.5,4.7l2.5,1
		l-1.6,3.5l2.7,5.1l1.2,4.1l4.3,1.1l1.1,4.1l-3.9,5.8l3.2,3.4l2.8,3.9l5.7,2.8l1,5.6l2.7,1.1l0.9,2.9l-7.5,3.4l-1.1,7.4l-10.6-1.9
		l-6.2-1.5l-6.3-0.8l-3.3-7.9l-2.8-1.1l-4.1,1.1l-5.1,3.1l-7-2.1l-6.1-5l-5.5-1.8l-4.4-6.1l-5.2-8.5l-2.8,1l-3.7-2.1l-1.7,2.5
		l-3.5-3.4l-0.5-3.4h-1.7l0.2-4.7l-3.5-4.8l-7.1-3.6l-4.6-6.1l0.5-5l2.3-2.2l-0.9-3.7l-3.8-2l-4.7-7.6l-3.8-5.1l0.7-2l-2.9-7.3
		l3.3-1.9l1.2,2.5l3.2,2.9L1229,253.2L1229,253.2z"
					/>
					<path
						id="IL"
						className={Styles.country}
						d="M1179.1,288.2l0.4,2.6l-0.6,1h0.1l-0.7,2l-2.1-0.8l-0.7,4.2l1.5,0.7l-1.3,0.9l-0.1,1.7l2.5-0.8
		l0.4,2.5l-1.8,10.2l-0.7-1.6l-3.9-9.4l1.4-2.1l-0.4-0.4l1.1-3l0.6-4.8l0.6-1.7h0.1h1.8l0.4-1.1L1179.1,288.2L1179.1,288.2z"
					/>
					<path
						id="JO"
						className={Styles.country}
						d="M1198.1,295.3l-0.9,1l-10.4,3.2l6,6.5l-1.6,1l-0.7,2.2l-4.1,0.9l-1.1,2.3l-2.1,2l-6.2-1.1l-0.3-0.9
		l1.8-10.2l-0.4-2.5l0.6-1.9l-0.4-4l0.7-2l6.3,2.6l9.7-6.9L1198.1,295.3z"
					/>
					<path
						className={Styles.country}
						d="M1708.5,282.6l1.6,2.2l-1.3,3.9l-3.1-2.1l-2.1,1.5l0.1,3.7l-4.3-1.8l-1.2-3l1.3-3.9l3.4,0.8l1-2.7
		L1708.5,282.6z"
					/>
					<path
						className={Styles.country}
						d="M1733.1,263.4l0.6,5.1l2.5,3.2l-0.6,4.5l-5.4,3l-9.2,0.4l-4.4,7.4l-4.7-2.5l-2.4-4.8l-8.6,1.4l-5.1,3l-6.2,0.2
		l7.4,4.7l0.8,10.9l-2.5,2.7l-3.6-2.5l-0.9-5.8l-4.1-1.8l-4-4.4l4.3-2l1.1-4.1l3.9-3.3l2-4.4l9.7-1.9l6.3,1.3v-11.4l5.1,3.1l4.5-6.4
		l1.7-2.5l-1-7.8l-5.1-7.2l-0.2-4l4.8-1.2l8.2,8.9l2.8,5.1l-1.3,6.5L1733.1,263.4z"
					/>
					<path
						id="KZ"
						className={Styles.country}
						d="M1338.3,160.5l4.4-0.3l9.2-5.8l-0.8,2l8.4,4.7l18.3,15.6l1.1-3.2l8.4,3.5l6.2-1.6l3.3,1.1l4.1,3.6
		l4,1.2l3.3,2.7l6-0.9l4.4,3.8l-1.9,4.2l-3.8,0.6l2.5,6.2l-1.6,2.9l-10.8-2.1l1,11.3l-2,1.4l-9.1,2.5l8.8,11l-2.9,1.6l1.7,3.7
		l-3.5-1l-3.4-2.3l-7.9-0.6l-8.6-0.2l-1.6,0.7l-8.2-2.7l-2.5,1.4l0.5,3.7l-9.2-2.2l-3.1,0.9l-0.3,2.8l-2.6,1.2l-5.4,4.4l-0.9,4.6h-2
		l-2.3-3l-6.7-0.2l-2.5-5.2l-2.6-0.1l-1.5-6.4l-7.6-4.6l-8.6,0.5l-5.7,0.9l-6.6-5.7l-4.8-2.4l-9.2-4.5l-1.1-0.5l-12,3.7l6.2,23.4
		l-2.6,0.3l-4.8-5l-3.9-1.8l-5.6,1.3l-1.8,2.2l-0.6-1.6l0.6-2.6l-1.5-2.2l-6.5-2.2l-3.7-5.7l-3.2-1.6l-0.6-2.1l5.1,0.6l-1-4.6l4.1-1
		l4.7,0.9l-0.7-6.1l-1.9-3.9l-5,0.3l-4.7-1.5l-5.1,2.7l-4.4,1.4l-2.8-1.1l-0.2-3.2l-4.3-4.2l-3.6,0.2l-5.3-4.2l1.7-4.8l-1.8-1.2
		l2.2-6.9l6,3.6l-0.6-4.5l8.1-6.7l7.6-0.2l12,4.3l6.6,2.5l4.4-2.6l7.7-0.1l7.3,3.2l0.8-1.9l7,0.3l0.2-2.9l-9.4-4.3l3.5-3l-1.5-1.6
		l4-1.6l-5.1-4.2l1.4-2.1l17-2.1l1.7-1.5l10.9-2.3l3.1-2.5l9.1,1.3l4.4,6.3l4.3-1.5l7.1,2.1L1338.3,160.5L1338.3,160.5z"
					/>
					<path
						id="KH"
						className={Styles.country}
						d="M1589.8,410.6l1.8,4.3l0.1,7.7l-9,5l2.8,3.8l-5.9,0.5l-4.6,2.6l-4.8-0.9l-2.6-3.4l-3.5-6.6l-2.1-7.8
		l3.1-5.3l7.1-1.2l5.3,0.9l5,2.5l2-4.4L1589.8,410.6L1589.8,410.6z"
					/>
					<path
						id="KR"
						className={Styles.country}
						d="M1652.9,259.5v-0.6l2.5,0.2l0.6-2.8l3.6-0.4l2-0.4V254l8.3,7.5l3.3,4.2l3.4,7.4l-0.5,3.5l-4.3,1.2
		l-3.1,2.7l-4.6,0.5l-2.1-3.5l-1.1-4.8l-5.3-6.6l3.4-1.1L1652.9,259.5L1652.9,259.5z"
					/>
					<path
						id="KW"
						className={Styles.country}
						d="M1247.5,309.4l1.5,2.8l-0.3,1.5l2.4,4.8l-3.9,0.2l-1.7-3.1l-5-0.6l3.3-6.2L1247.5,309.4L1247.5,309.4z
		"
					/>
					<path
						id="LA"
						className={Styles.country}
						d="M1589.8,410.6l-5.3-2.3l-2,4.4l-5-2.5l1.5-2.9l-0.4-5.4l-5.3-5.6l-1.3-6.4l-5-5.2l-4.3-0.4l-0.8,2.2
		l-3.2,0.2l-1.9-1.1l-5.3,3.8l-1-5.8l0.4-6.7l-3.8-0.3l-0.9-3.9l-2.7-2l0.8-2.3l4.1-4.2l0.8,1.5l3,0.2l-2-7.4l2.7-0.9l4,5.1l3.5,5.8
		h6.8l3,5.6l-3.3,1.7l-1.2,2.3l7.3,3.9l5.7,7.6l4.4,5.6l4.9,4.5l2,4.5L1589.8,410.6L1589.8,410.6z"
					/>
					<path
						id="LB"
						className={Styles.country}
						d="M1179.1,288.2l-1.4,0.1l-0.4,1.1h-1.8l1.3-5.3l2.2-4.5v-0.2l2.5,0.3l1.2,2.5l-2.7,2.5L1179.1,288.2
		L1179.1,288.2z"
					/>
					<path
						id="LK"
						className={Styles.country}
						d="M1445.9,462l-4.8,1.5l-2.9-5.1l-1.4-9.2l2-10.4l4.1,3.5l2.8,4.5l3.1,6.7l-0.6,6.7L1445.9,462
		L1445.9,462z"
					/>
					<path
						id="MM"
						className={Styles.country}
						d="M1548.4,364.2l-4.1,4.2l-0.8,2.3l-3,1.5l-2.8,2.8l-3.9,0.3l-1.5,6.9l-2.2,1.2l3.5,5.6l4.1,4.7l2.9,4.3
		l-1.4,5.5l-1.8,1.2l1.8,3.2l4.3,5.1l1,3.6l0.2,3l2.7,5.9l-2.6,6l-2.2,6.6l-0.9-4.8l1.3-4.9l-2.2-3.8l-0.2-7l-2.6-3.4l-2.7-7.6
		l-2-8.1l-3.1-5.4l-3.2,3.3l-5.8,4.5l-3.3-0.5l-3.6-1.5l0.9-8l-2-6l-5.3-7.4l0.3-2.3l-3.4-0.9l-4.6-5.2l-1.1-5.2l2.1,1l-0.6-4.6
		l2.5-1.5l-1-2.7l1-2.2l-0.9-6.7l4.6,1.5l1.6-5.3l-0.3-3.1l2-5.4l-0.9-3.7l6.2-4.4l4.2,1.1l-1.4-3.9l1.7-1.2l-1-2.4l3.1-0.5l2.7,3.8
		l2.7,1.5l1.3,4.9l0.9,5.3l-4.2,5.4l0.7,7.6l5.6-1.1l2.4,5.9l3.7,1.3l-0.8,5.3l4.5,2.4l2.6,1.2l3.8-1.9L1548.4,364.2L1548.4,364.2z"
					/>
					<path
						id="MN"
						className={Styles.country}
						d="M1496.2,181.5l4-1.2l5.7-0.8l5.4,0.9l6.6,2.9l4.9,3.2h4.6l6.8,1l3.6-1.6l5.9-1l4.4-4.4l3.4,0.7
		l3.9,2.1l5.6-0.6l0.6,4.7l0.3,6.3l2.8,2.5l2.3-0.8l5.5,1l2.5-2.3l5.2,2l7.2,4.4l0.8,2.2l-4.4-0.7l-6.8,0.8l-2.5,1.8l-1.3,4.2
		l-6.4,2.4l-3.2,3.4l-5.9-1.3l-3.2-0.6l-0.5,4.1l2.9,2.4l1.9,2.1l-2.4,2.2l-2,3.4l-4.9,2.2l-7.6,0.2l-7.2,2.2l-4.4,3.4l-3.3-1.9
		h-6.2l-9.4-3.9l-5.6-0.9l-6.4,0.9l-11.3-1.5l-5.6,0.2l-4.7-3.8l-5-5.8l-3.4-0.7l-8-4l-7.2-0.8l-6.5-1.1l-3-2.8l-1.4-7.3l-5.8-5.1
		l-8.2-2.3l-5.7-3.3l-3.3-4.4l4.7-1.1l6.7-5.3l5.9-2.9l5.3,1.9l5.2,0.1l4.8,2.9l5,0.2l8,1.6l2.4-4.4l-4-3.6l1.3-6.4l7,2.5l4.8,0.8
		l6.7,1.6l3.6,4.6L1496.2,181.5L1496.2,181.5z"
					/>
					<path
						className={Styles.country}
						d="M1564.3,461.9l1.4,0.6l3.5,3.9l2.5,4.3l0.6,4.3l-0.5,2.9l0.6,2.2l0.5,3.8l2.1,1.8l2.3,5.7v2.1l-4,0.5l-5.5-4.8
		l-6.8-5.1l-0.8-3.3l-3.4-4.3l-1-5.3l-2.2-3.5l0.4-4.7l-1.4-2.7l0.9-1.1l4.8,2.8l0.6,3.3l3.7-0.8L1564.3,461.9z"
					/>
					<path
						className={Styles.country}
						d="M1654.1,475.3l-5-1.1h-6.5l-1.7,7.3l-2.1,2.2l-2.6,8.9l-4.6,1.4l-5.4-1.8l-2.7,0.6l-3.3,3.2l-3.6-0.4l-3.7,1.3
		l-3.9-3.7l-1-4.3l4.2,2.2l4.3-1.2l1-5.4l2.4-1.2l6.8-1.4l3.8-5.1l2.6-4l2.8,3.3l1.1-2.2l2.7,0.2l0.1-4.1l0.1-3.2l4.1-4.4l2.6-5
		l2.3-0.1l3.1,3.3l0.4,2.8l3.8,1.8l4.8,1.9l-0.2,2.5l-3.8,0.3l1.1,3.2L1654.1,475.3z"
					/>
					<path
						id="NP"
						className={Styles.country}
						d="M1469,322.9l0.2,2.7l1.5,4.1l-0.1,2.5l-4.6,0.1l-6.9-1.5l-4.3-0.6l-3.8-3.2l-7.7-0.9l-7.8-3.6
		l-5.8-3.1l-5.8-2.4l0.9-6l2.8-3l1.9-1.5l4.8,2l6.4,4.2l3.3,0.9l2.5,3.1l4.5,1.2l5,2.9l6.5,1.4L1469,322.9L1469,322.9z"
					/>
					<path
						className={Styles.country}
						d="M1283.8,394.9l-2.2-4.5l-5.2-10.6l16.3-6.4l2.6-12.8l-3-4.6v-2.6l1.3-2.6l-0.2-2.6l2.4-1.3l-1.1-0.9v-4.2h2.8
		l3,4.4l3.3,2.3l4.1,0.9l3.4,1.1l2.9,3.7l1.7,2.1l2,0.9l0.2,1.4l-1.7,3.8l-0.7,1.8l-2.2,2.1l-1.7,4.4l-2.5-0.4l-1,1.6l-0.7,3.2
		l1.1,4.3l-0.5,0.8h-2.5l-3.3,2.4l-0.3,3.1l-1.2,1.4l-3.5-0.1l-2,1.6l0.2,2.6l-2.6,1.8l-3.1-0.6l-3.6,2.2L1283.8,394.9z"
					/>
					<path
						className={Styles.country}
						d="M1296.2,336.7l-1.3-2.2l1.4-2.1l0.7,0.5l-0.2,2.7L1296.2,336.7z"
					/>
					<path
						id="PK"
						className={Styles.country}
						d="M1401.6,273.9l-3.8,5.4l-5.7,1l-8.5-1.6l-2,2.8l3.3,5.6l2.9,4.4l5.1,3.1l-3.8,3.7l1,4.6l-3.9,6.5
		l-2.2,6.5l-4.5,6.7l-6.5-0.5l-4.9,6.8l4,2.8l1.4,5l3.5,3.2l1.8,5.5h-12.1l-3.2,4.3l-4.2-1.6l-2.2-4.6l-4.9-4.9l-10,1.2l-9,0.1
		l-7.6,0.9l1.1-7.4l7.5-3.4l-0.9-2.9l-2.7-1.1l-1-5.6l-5.7-2.8l-2.8-3.9l-3.2-3.4l9.6,3.3l5.3-1l3.4,0.8l0.9-1.4l3.9,0.6l6.6-2.7
		l-0.8-5.4l2.3-3.7h4.1l0.2-1.7l4-0.9l2.1,0.6l1.8-1.8l-1.1-3.9l1.4-3.8l3.1-1.7l-3.1-4.2l5.2,0.2l0.9-2.3l-0.8-2.5l2-2.7l-1.4-3.2
		l-1.9-2.7l2.4-2.8l5.3-1.3l5.9-0.8l2.4-1.1l2.9-0.8l4.7,3l2.9,5L1401.6,273.9L1401.6,273.9z"
					/>
					<path
						className={Styles.country}
						d="M1700.5,447.8l0.9,4.3l0.6,3.6l-1.6,5.8l-2.5-6.5l-2.4,3.3l2.1,4.7l-1.4,3l-6.9-3.7l-1.9-4.7l1.5-3l-3.8-3.1
		l-1.6,2.7l-2.6-0.3l-4,3.6l-1-1.9l1.8-5.4l3.4-1.8l2.9-2.4l2.2,2.9l4.2-1.7l0.7-2.9l4-0.2l-0.8-4.9l4.9,3l0.7,3.2L1700.5,447.8z"
					/>
					<path
						className={Styles.country}
						d="M1685.9,435.8l-1.8,2.1l-1.4,4.1l-1.6,1.9l-3.9-4.4l1-1.8l1.3-1.8l0.2-3.9l3.1-0.4l-0.5,4.3l3.6-6.2V435.8z"
					/>
					<path
						className={Styles.country}
						d="M1655.6,442l-7.1,6.1l2.4-4.5l3.8-4l3-4.4l2.3-6.4l1.6,5.3l-3.5,3.5L1655.6,442z"
					/>
					<path
						className={Styles.country}
						d="M1673.1,425.5l3.6,2h3.5l0.2,2.6l-2.3,2.8l-3.4,1.9l-0.5-3l0.1-3.3L1673.1,425.5z"
					/>
					<path
						className={Styles.country}
						d="M1693.2,423.7l2.4,7.2l-4.5-1.7l0.3,2.2l1.8,3.9l-2.6,1.5l-0.6-4.6l-1.8-0.3l-1.2-3.9l3.3,0.5l-0.3-2.4l-4-4.9
		l5.4,0.1L1693.2,423.7z"
					/>
					<path
						className={Styles.country}
						d="M1670.2,417.9l-0.9,5.6l-2.7-3.2l-3.5-4.9l4.8,0.2L1670.2,417.9z"
					/>
					<path
						className={Styles.country}
						d="M1663.7,383l3.8,1.9l1.4-1.7l0.8,1.6l-0.4,2.7l2.6,4.6l-0.6,5.3l-2.9,2.1l-0.1,5.2l2,5.1l3,0.7l2.4-0.7
		l7.5,3.5l-0.1,3.5l2.1,1.6l-0.3,2.9l-4.7-3.1l-2.5-3.4l-1.2,2.4l-4-3.9l-5,1l-3-1.4l-0.1-2.7l1.6-1.6l-1.9-1.5l-0.4,2.3l-3.3-3.7
		l-1.3-2.8l-1.1-6.1l2.6,2.1l-1.1-10.1l0.8-5.8H1663.7z"
					/>
					<path
						className={Styles.country}
						d="M1846.7,537l-3,0.5l-1,1.8l-3.2,1.6l-3,1.5h-3.1l-4.5-1.9l-3.1-1.8l0.6-2l5,1l3.2-0.5l1-3.1l0.9-0.2l0.3,3.4
		l3.2-0.4l1.8-2.2l3.3-2.3l-0.4-3.8l3.4-0.1l1.1,1l-0.4,3.6L1846.7,537z"
					/>
					<path
						className={Styles.country}
						d="M1782.9,560.4l1.6-21l1-20.9l9.6,4.4l10.3,3.7l3.7,3.3l3,3.2l0.6,3.8l9.2,4l1.1,3.4l-5.2,0.7l0.9,4.3l4.7,4.2
		l3,6.8l3.3-0.2l-0.6,2.8l4.3,1.1l-1.8,1.2l5.7,2.7l-0.9,1.9l-3.8,0.4l-1.2-1.6l-4.7-0.8l-5.6-0.9l-4-4.1l-2.8-3.6l-2.5-5.6
		l-7.1-2.8l-4.9,1.8l-3.7,2.2l0.3,4.7l-4.6,2.2l-3.1-1.1L1782.9,560.4z"
					/>
					<path
						className={Styles.country}
						d="M1853.6,530.7l-1.8,1.7l-0.9-3.8l-1.1-2.4l-2.5-2.1l-3.1-2.8l-4-1.9l1.6-1.5l3,1.8l1.9,1.4l2.3,1.5l2.1,2.7
		l2.1,2.1L1853.6,530.7z"
					/>
					<path
						id="KP"
						className={Styles.country}
						d="M1660.3,229.9l1.4,1.1l-2.2-0.4l-1,2.2l-0.3,2.1l2.8,4.6l-1.9,1.4l-0.3,1.1l-0.9,1.9l-2.9,1.1
		l-1.4,1.7l1.3,2.7l-0.3,0.7l2.6,1.1l4.4,2.8v1.5l-2,0.4l-3.6,0.4l-0.6,2.8l-2.5-0.2v0.6l-3.2-1.2l-0.1,1.2l-1.3,0.5l-0.7-1.2
		l-1.7-0.6l-1.9-1l0.1-2.8l0.9-0.8l-1-1.1l-0.4-3.5l-0.9-1l-3.4-0.7l-3.4-1.7l2.1-4.1l3.9-3.4l1.1-4.6l3.6,2l4.6,0.3l-2.8-3.4
		l6.4-2.7v-3.6L1660.3,229.9L1660.3,229.9z"
					/>
					<path
						id="PS"
						className={Styles.country}
						d="M1178.3,293.8l0.4,4l-0.6,1.9l-2.5,0.8l0.1-1.7l1.3-0.9l-1.5-0.7l0.7-4.2L1178.3,293.8L1178.3,293.8z"
					/>
					<path
						id="QA"
						className={Styles.country}
						d="M1270.1,343.7l-1.5,0.5l-1.8-1.3l-0.8-4.7l1.1-3.3l1.5-0.7l1.8,2l0.5,3.7L1270.1,343.7L1270.1,343.7z"
					/>
					<path
						id="SA"
						className={Styles.country}
						d="M1240.5,315l5,0.6l1.7,3.1l3.9-0.2l2.7,5.6l2.9,1.4l1.2,2.3l4,2.7l0.7,2.6l-0.4,2.2l0.9,2.1l1.8,1.8
		l0.9,2.1l1,1.6l1.8,1.3l1.5-0.5l1.3,2.5l0.3,1.4l2.7,6.6l16.9,3.2l1-1.4l3,4.6l-2.6,12.8l-16.3,6.4l-15.9,2.5l-5,2.9l-3.5,6.7
		l-2.6,1.1l-1.5-2.1l-2.1,0.3l-5.5-0.7l-1.1-0.6l-6.4,0.1l-1.5,0.6l-2.4-1.6l-1.3,3.1l0.8,2.7l-2.4,2.1l-0.9-2.8l-1.8-1.9l-0.5-2.6
		l-3.1-2.3l-3.3-5.4l-1.9-5.2l-4.1-4.4l-2.5-1.1l-4.1-6.1l-0.9-4.4v-3.8l-3.6-7.2l-2.8-2.5l-3-1.3l-2.1-3.7l0.2-1.4l-1.8-3.4
		l-1.7-1.4l-2.5-4.8l-3.8-5.1l-3.1-4.4h-2.7l0.5-3.5l0.1-2.3l0.4-2.6l6.2,1.1l2.1-2l1.1-2.3l4.1-0.9l0.7-2.2l1.6-1l-6-6.5l10.4-3.2
		l0.9-1l6.8,1.8l8.6,4.5l16.8,12.9L1240.5,315L1240.5,315z"
					/>
					<path
						id="SY"
						className={Styles.country}
						d="M1195,287.5l-9.7,6.9l-6.3-2.6h-0.1l0.6-1l-0.4-2.6l0.9-3.5l2.7-2.5l-1.2-2.5l-2.5-0.3l-1.1-4.9l1-2.7
		l1.3-1.4l1.2-1.4l-0.2-3.5l1.9,1.2l5.6-1.8l3,1.2h4.4l5.7-2.4l2.9,0.1l5.9-1l-2.1,4l-2.7,1.6l1.2,4.7l-1,7.7L1195,287.5L1195,287.5
		z"
					/>
					<path
						id="TH"
						className={Styles.country}
						d="M1577.5,410.2l-5.3-0.9l-7.1,1.2l-3.1,5.3l2.1,7.8l-5.3-3l-4.8,0.2l0.3-5.1h-4.9l0.2,7.1l-2.2,9.4
		l-1.4,5.7l0.7,4.6l3.7,0.2l2.7,5.9l1.3,5.5l3.4,3.7l3.4,0.7l3.1,3.4l-1.7,2.6l-3.7,0.8l-0.6-3.3l-4.8-2.8l-0.9,1.1l-2.3-2.4
		l-1.2-3.2l-3.2-3.6l-2.9-3.1l-0.7,3.8l-1.3-3.6l0.4-4l1.2-6.1l2.2-6.6l2.6-6l-2.7-5.9l-0.2-3l-1-3.6l-4.3-5.1l-1.8-3.2l1.8-1.2
		l1.4-5.5l-2.9-4.3l-4.1-4.7l-3.5-5.6l2.2-1.2l1.5-6.9l3.9-0.3l2.8-2.8l3-1.5l2.7,2l0.9,3.9l3.8,0.3l-0.4,6.7l1,5.8l5.3-3.8l1.9,1.1
		l3.2-0.2l0.8-2.2l4.3,0.4l5,5.2l1.3,6.4l5.3,5.6l0.4,5.4L1577.5,410.2L1577.5,410.2z"
					/>
					<path
						id="TJ"
						className={Styles.country}
						d="M1357,243.6l-1.4,1.9l-6-1l0.6,3.6l5.5-0.5l7.1,2.1l9.6-1l3.1,6l1.5-0.7l3.7,1.5l0.5,2.5l1.8,3.6h-5.4
		l-3.8-0.5l-2.5,2.9l-2.2,0.6l-1.5,1.4l-2.7-2.1l-0.9-5.4l-1.7-0.3l0.1-2l-3.3-1.4l-1.7,2.2l0.2,2.6l-0.6,0.9l-3.2-0.1l-0.9,2.9
		l-2.1-1.2l-3.4,2l-1.8-0.7l1.3-6.5l-2.4-4.8l-4.2-1.5l0.6-2.8l4.4,0.3l1.5-3.5l0.5-4.1l6.5-1.5l-0.2,3l1.3,1.7L1357,243.6
		L1357,243.6z"
					/>
					<path
						id="TM"
						className={Styles.country}
						d="M1338.3,262l-1.6-0.2l-2.9-1.7l-0.3,2.2l-4.2,1.3l0.2,5.1l-2.6,2l-4,0.9l-0.4,2.9l-3.9,0.9l-5.9-2.5
		l-1.7-5.3l-4-0.3l-7.3-5.6l-4.3-0.7l-6.6-3.3l-3.9-0.6l-2,1.2l-3.6-0.2l-3,3.7l-4.4,1.2l-1.9-4.5l-0.6-6.7l-4.6-2.2l0.4-4.3
		l-3.5-0.4l-0.1-5.4l5.3,1.6l4.1-2l-4.7-3.9l-2.4-3.6l-3.8,1.6l0.6,4.7l-2.6-4.1l1.8-2.2l5.6-1.3l3.9,1.8l4.8,5l2.6-0.3l5.9-0.1
		l-1.7-3.2l3.8-2.2l3.4-3.7l7.9,3.4l1.9,5l2.3,1.3l5.5-0.3l2.1,1.2l4.3,6.6l7.1,4.4l4.2,3l6.3,3.1l7.7,2.8L1338.3,262L1338.3,262z"
					/>
					<path
						id="TL"
						className={Styles.country}
						d="M1692.7,562.1l0.1-1.9l-0.5-1.3l0.8-1.5l4.9-1.4l4-0.3l1.8-0.8l2.1,0.8l-2.2,1.8l-6.1,2.8
		L1692.7,562.1L1692.7,562.1z"
					/>
					<path
						className={Styles.country}
						d="M1201.7,235.3l5.5-0.3l5.6,3.2l1.3,2.2l0.1,3.1l4.2,1.6l2.4,1.8l-3.3,1.9l2.9,7.3l-0.7,2l3.8,5.1l-2.4,1.1
		l-2.1-1.6l-6.3-0.9l-2.1,1l-5.9,1l-2.9-0.1l-5.7,2.4h-4.4l-3-1.2l-5.6,1.8l-1.9-1.2l0.2,3.5l-1.2,1.4l-1.3,1.4l-2.3-2.9l1.7-2.4
		l-3.2,0.6l-4.6-1.5l-3.2,3.7l-8,0.7l-4.7-3.4l-5.7-0.2l-1,2.6l-3.6,0.8l-5.4-3.4l-5.8,0.1l-3.8-6.4l-4.2-3.5l2-5l-3.6-3.1l5.1-6.1
		l8-0.2l1.6-4.9l10,0.9l5.6-4.1l5.8-1.8l8.5-0.2l9.8,4.5l7.9,2.5l5.8-1l4.6,0.6L1201.7,235.3z"
					/>
					<path
						id="TW"
						className={Styles.country}
						d="M1657.9,355.5l-1.4,5.3l-4-5.5l-1.5-4.7l1.9-6.3l3.3-4.9l3,1.9l-0.1,3.9L1657.9,355.5L1657.9,355.5z"
					/>
					<path
						id="UZ"
						className={Styles.country}
						d="M1352.7,230.7l1.7,0.6l-3,4.1l4.6,2.4l3.2-1.6l7.2,3.4l-5.3,4.6l-4.1-0.6l-2.1,0.1l-1.3-1.7l0.2-3
		l-6.5,1.5l-0.5,4.1l-1.5,3.5l-4.4-0.3l-0.6,2.8l4.2,1.5l2.4,4.8l-1.3,6.5l-4.3-1.4h-3l-0.8-3.9l-7.7-2.8l-6.3-3.1l-4.2-3l-7.1-4.4
		l-4.3-6.6l-2.1-1.2l-5.5,0.3l-2.3-1.3l-1.9-5l-7.9-3.4l-3.4,3.7l-3.8,2.2l1.7,3.2l-5.9,0.1l-6.2-23.4l12-3.7l1.1,0.5l9.2,4.5
		l4.8,2.4l6.6,5.7l5.7-0.9l8.6-0.5l7.6,4.6l1.5,6.4l2.6,0.1l2.5,5.2l6.7,0.2l2.3,3h2l0.9-4.6l5.4-4.4L1352.7,230.7L1352.7,230.7z"
					/>
					<path
						id="VN"
						className={Styles.country}
						d="M1586.5,363.5l-6.5,5.4l-3.7,6.1l-0.6,4.5l5.3,6.7l6.5,8.4l5.7,4l4.1,5.1l4,11.9l0.4,11.3l-4.3,4.2
		l-6.1,4.2l-4.2,5.3l-6.6,6l-2.3-4.1l1.2-4.4l-4.4-3.6l4.6-2.6l5.9-0.5l-2.8-3.8l9-5l-0.1-7.7l-1.8-4.3l0.2-6.4l-2-4.5l-4.9-4.5
		l-4.4-5.6l-5.7-7.6l-7.3-3.9l1.2-2.3l3.3-1.7l-3-5.6h-6.8l-3.5-5.8l-4-5.1l2.7-1.6l4.4,0.1l5.3-0.8l4.1-3.4l3.1,2.4l5.3,1.2
		l-0.3,3.7l3.1,2.6L1586.5,363.5L1586.5,363.5z"
					/>
					<path
						id="YE"
						className={Styles.country}
						d="M1283.8,394.9l-4,1.7l-0.9,2.9v2.2l-5.4,2.7l-8.8,3l-4.7,4.5l-2.5,0.4l-1.7-0.4l-3.2,2.7l-3.5,1.2
		l-4.7,0.3l-1.4,0.4l-1.1,1.7l-1.5,0.5l-0.8,1.6l-2.8-0.2l-1.7,0.9l-4-0.3l-1.6-3.8v-3.5l-1-1.9l-1.3-4.7l-1.8-2.6l1.1-0.4l-0.7-2.9
		l0.6-1.2l-0.4-2.8l2.4-2.1l-0.8-2.7l1.3-3.1l2.4,1.6l1.5-0.6l6.4-0.1l1.1,0.6l5.5,0.7l2.1-0.3l1.5,2.1l2.6-1.1l3.5-6.7l5-2.9
		l15.9-2.5l5.2,10.6L1283.8,394.9L1283.8,394.9z"
					/>
					<path
						id="BH"
						className={Styles.country}
						d="M1264.1,333.3l0.3,0.1l0.2-0.1l0.4,0.7l-0.1,0.2l0.1,0.9v0.7l-0.2,0.4l-0.1-0.4l-0.6-0.8l0.1-0.4
		l-0.2-0.7v-0.4L1264.1,333.3L1264.1,333.3z"
					/>
					<path
						id="KG"
						className={Styles.country}
						d="M1400.5,230.2l-0.2,1.4l-6.9,3.4l-1,2.6l-6.4,0.7l-0.6,4.1l-5.8-0.9l-3.2,1.3l-4.1,3l1.2,1.5l-1.1,1.4
		l-9.6,1l-7.1-2.1l-5.5,0.5l-0.6-3.6l6,1l1.4-1.9l4.1,0.6l5.3-4.6l-7.2-3.4l-3.2,1.6l-4.6-2.4l3-4.1l-1.7-0.6l0.3-2.8l3.1-0.9
		l9.2,2.2l-0.5-3.7l2.5-1.4l8.2,2.7l1.6-0.7l8.6,0.2l7.9,0.6l3.4,2.3L1400.5,230.2L1400.5,230.2z"
					/>
					<path
						id="IQ"
						className={Styles.country}
						d="M1223.5,263.2l4.7,7.6l3.8,2l0.9,3.7l-2.3,2.2l-0.5,5l4.6,6.1l7.1,3.6l3.5,4.8l-0.2,4.7h1.7l0.5,3.4
		l3.5,3.4l-3.3-0.3l-3.7-0.6l-3.3,6.2l-10.2-0.5l-16.8-12.9l-8.6-4.5l-6.8-1.8l-3.1-7.8l11-6.7l1-7.7l-1.2-4.7l2.7-1.6l2.1-4l2.1-1
		l6.3,0.9l2.1,1.6L1223.5,263.2L1223.5,263.2z"
					/>
					<path
						className={Styles.country}
						d="M1229,253.2l-3.8-0.9l-3.2-2.9l-1.2-2.5l1-0.1l1.9,1.7h2.3l0.2,1L1229,253.2z"
					/>
					<path
						id="AM"
						className={Styles.country}
						d="M1230.8,253l-1.8,0.2l-2.8-3.7l-0.2-1h-2.3l-1.9-1.7l-1,0.1l-2.4-1.8l-4.2-1.6l-0.1-3.1l-1.3-2.2l7-1
		l1.4,1.6l2.2,1.1l-0.7,1.6l3.2,2.2l-1.1,2.1l2.6,1.7l2.5,1L1230.8,253L1230.8,253z"
					/>
				</g>
				<g
					id="Africa"
					className={Styles.continent}
					data-active={active === "Africa"}
					onClick={() => setActiveContinent("Africa")}
				>
					<path
						id="MW"
						className={Styles.country}
						d="M1182.3,588.9h0.4l1.9,2.1l2.2,4.6l0.1,8.3l-2.5,1.3l-1.9,4.5l-3.5-4l-0.2-4.5l1.3-3l-0.2-2.6
		l-2.2-1.6l-1.5,0.6l-3.2-3.1l-2.9-1.6l2-6l1.8-2.2l-0.9-5.4l1.3-5.2l1-1.7l-1.3-5.4l-2.6-2.9l5.5,1.2l1,1.7l-0.1,0.8l1.8,4.1
		l0.2,7.7l-1.8,3.6l1.6,4.7l-0.2,2.8l1.2,1.9l-0.1,2.4l0.9,1.4l1-1.6l1.9,2.5l0.2-0.8l-1-3.4l-1.1-0.3L1182.3,588.9L1182.3,588.9z"
					/>
					<g>
						<path
							className={Styles.country}
							d="M1121.2,572l0.6,2l-0.7,3.1l0.9,3l-0.9,2.4l0.4,2.2l-11.7-0.1l-0.8,20.5l3.6,5.2l3.6,4l-10.4,2.6l-13.5-0.9
			l-3.8-3l-22.7,0.2l-0.8,0.5l-3.3-2.9l-3.6-0.2l-3.4,1.1l-2.7,1.2l-0.5-4l0.9-5.7l2-5.9l0.3-2.7l1.9-5.8l1.4-2.6l3.3-4.2l1.9-2.9
			l0.6-4.7l-0.3-3.7l-1.6-2.3l-1.5-3.9l-1.3-3.8l0.3-1.4l1.7-2.5l-1.6-6.2l-1.2-4.3l-2.8-4.1l0.6-1.2l2.3-0.9l1.7,0.1l2-0.7
			l16.7,0.1l1.3,4.7l1.6,3.9l1.3,2.1l2.1,3.3l3.8-0.5l1.8-0.9l3.1,0.9l0.9-1.6l1.5-3.7l3.5-0.3l0.3-1.1h2.9l-0.5,2.3l6.8-0.1
			l0.1,4.1l1.1,2.4l-0.9,3.9l0.4,4l1.8,2.4l-0.4,7.6l1.4-0.6l2.4,0.2l3.5-1L1121.2,572z"
						/>
						<path
							className={Styles.country}
							d="M1055.3,539l-1.5-4.8l2.3-2.8l1.7-1.1l2.1,2.2l-2,1.4l-1,1.6l-0.2,2.8L1055.3,539z"
						/>
						<path
							id="BI"
							className={Styles.country}
							d="M1154.9,530.4l-0.6,0.1v-0.3l-2-6.1l0-0.1l-0.1-1l-1.4-2.9l3.5,0.5l1.7-3.7l3.1,0.4l0.3,2.5l1.2,1.5
			v2.1l-1.4,1.3l-2.3,3.4L1154.9,530.4L1154.9,530.4z"
						/>
						<path
							id="BJ"
							className={Styles.country}
							d="M1006.7,427l-0.2,2.1l1.3,3.8l-1.1,2.6l0.6,1.7l-2.8,4l-1.7,2l-1.1,4l0.2,4.1l-0.3,10.3l-4.7,0.8
			l-1.4-4.4l0.3-14.8l-1.2-1.3l-0.2-3.2l-2-2.2l-1.7-1.9l0.7-3.4l2-0.7l1.1-2.8l2.8-0.6l1.2-1.9l1.9-1.9h2L1006.7,427L1006.7,427z"
						/>
						<path
							id="BF"
							className={Styles.country}
							d="M988.5,406l-0.5,3.1l0.8,2.9l3.1,4.2l0.2,3.1l6.5,1.5l-0.1,4.4l-1.2,1.9l-2.8,0.6l-1.1,2.8l-2,0.7
			l-4.9-0.1l-2.6-0.5l-1.8,1l-2.5-0.5l-9.8,0.3l-0.2,3.7l0.8,4.8l-3.9-1.6l-2.6,0.2l-2,1.6l-2.5-1.3l-1-2.2l-2.5-1.4l-0.4-3.7
			l1.6-2.7l-0.2-2.2l4.5-5.3l0.9-4.4l1.5-1.6l2.7,0.9l2.4-1.3l0.8-1.7l4.3-2.8l1.1-2l5.3-2.7l3.1-0.9l1.4,1.2H988.5L988.5,406z"
						/>
						<path
							id="BW"
							className={Styles.country}
							d="M1127.6,615.7l1.9,5.1l1.1,1.2l1.6,3.7l6.1,7l2.3,0.7l-0.1,2.3l1.5,4.1l4.3,1l3.4,2.9l-8.1,4.7
			l-5.2,4.8l-2,4.3l-1.8,2.4l-3,0.5l-1.2,3.1l-0.6,2l-3.6,1.4l-4.5-0.3l-2.5-1.8l-2.3-0.7l-2.8,1.4l-1.5,3.1l-2.7,1.9l-2.8,2.9
			l-4,0.7l-1.1-2.3l0.6-3.9l-3-6.1l-1.4-1l0.6-18.7l5.5-0.2l0.8-22.9l4.2-0.2l8.7-2.3l2,2.7l3.7-2.5h1.7l3.2-1.5L1127.6,615.7
			L1127.6,615.7z"
						/>
						<path
							id="CF"
							className={Styles.country}
							d="M1121.3,446.5l3.9,2.5l3.1,2.6l0.1,2.1l3.9,3.3l2.4,2.8l1.4,3.8l4.3,2.6l0.9,2l-1.8,0.7l-3.7-0.1
			l-4.2-0.7l-2.1,0.5l-0.9,1.6l-1.8,0.2l-2.2-1.4l-6.3,3.2l-2.6-0.6l-0.8,0.5l-1.6,3.9l-4.3-1.3l-4.1-0.6l-3.6-2.4l-4.7-2.2l-3,2.1
			l-2.2,3.2l-0.5,4.5l-3.6-0.3l-3.9-1.1l-3.3,3.4l-3,6l-0.6-1.9l-0.3-2.9l-2.6-2.1l-2.1-3.3l-0.5-2.3l-2.7-3.4l0.5-1.9l-0.6-2.7
			l0.4-5l1.4-1.1l2.8-6.5l4.6-0.5l1-1.7l1,0.2l1.4,1.4l7.1-2.4l2.4-2.5l2.9-2.3l-0.6-2.2l1.6-0.6l5.5,0.4l5.2-3l4-7l2.8-2.6l3.6-1.1
			l0.7,2.7l3.3,4v2.7l-0.8,2.6l0.4,2l1.9,1.9L1121.3,446.5L1121.3,446.5z"
						/>
						<path
							id="CI"
							className={Styles.country}
							d="M955.9,435.2l2.5,1.4l1,2.2l2.5,1.3l2-1.6l2.6-0.2l3.9,1.6l1.5,9.2l-2.4,5.3l-1.5,7.3l2.4,5.5
			l-0.2,2.6h-2.6l-3.9-1.2H960l-6.7,1.2l-3.9,1.8l-5.6,2.4l-1.1-0.2l0.4-5.3l0.6-0.8l-0.2-2.5l-2.4-2.7l-1.8-0.4l-1.6-1.8l1.2-2.9
			l-0.5-3.1l0.2-1.8h0.9l0.4-2.8l-0.4-1.3l0.5-0.9l2.1-0.7l-1.4-5.2l-1.3-2.6l0.5-2.2l1.1-0.5l0.8-0.6l1.5,1h4.4l1-1.8l1,0.1
			l1.6-0.7l0.9,2.7l1.3-0.8L955.9,435.2z"
						/>
						<path
							id="CM"
							className={Styles.country}
							d="M1072.8,454.2l-2.8,6.5l-1.4,1.1l-0.4,5l0.6,2.7l-0.5,1.9l2.7,3.4l0.5,2.3l2.1,3.3l2.6,2.1l0.3,2.9
			l0.6,1.9l-0.4,3.4l-4.5-1.5l-4.6-1.7l-7.1-0.2l-0.7-0.4l-3.4,0.8l-3.4-0.8l-2.7,0.4l-9.3-0.1l0.9-5.1l-2.3-4.3l-2.6-1l-1.1-2.9
			l-1.5-0.9l0.1-1.8l1.4-4.6l2.7-6.2h1.6l3.4-3.8l2.1-0.1l3.2,2.7l3.9-2.2l0.5-2.7l1.3-2.6l0.8-3.2l3-2.6l1.1-4.5l1.2-1.5l0.8-3.3
			l1.4-4.1l4.7-5l0.3-2.1l0.6-1.2l-2.3-2.5l0.2-2.1l1.5-0.3l2.3,4.1l0.5,4.2l-0.2,4.3l3.2,5.8h-3.2l-1.6,0.4l-2.6-0.6l-1.2,3
			l3.4,3.8l2.5,1.1l0.8,2.6l1.8,4.4L1072.8,454.2L1072.8,454.2z"
						/>
						<path
							id="CD"
							className={Styles.country}
							d="M1141.3,468.2l3.5,5.3l2.6,0.8l1.5-1.1l2.6,0.4l3.1-1.3l1.4,2.7l5.1,4.3l-0.3,7.5l2.3,0.9l-1.9,2.2
			l-2.1,1.8l-2.2,3.3l-1.2,3l-0.3,5.1l-1.3,2.5l-0.1,4.8l-1.6,1.8l-0.2,3.8l-0.8,0.5l-0.6,3.6l1.4,2.9l0.1,1l-1.2,10.3l1.5,3.6
			l-1,2.7l1.8,4.6l3.4,3.5l0.7,3.5l1.6,1.7l-0.3,1.1l-0.9-0.3l-7.7,1.1l-1.5,0.8l-1.7,4.1l1.2,2.8l-1.1,7.6l-0.9,6.4l1.5,1.2
			l3.9,2.5l1.6-1.2l0.2,6.9h-4.3l-2.2-3.5l-2-2.8l-4.3-0.9l-1.2-3.3l-3.5,2l-4.4-0.9l-1.9-2.9l-3.5-0.6l-2.7,0.1l-0.3-2l-1.9-0.1
			l-2.6-0.4l-3.5,1l-2.4-0.2l-1.4,0.6l0.4-7.6l-1.8-2.4l-0.4-4l0.9-3.9l-1.1-2.4l-0.1-4.1l-6.8,0.1l0.5-2.3h-2.9l-0.3,1.1l-3.5,0.3
			l-1.5,3.7l-0.9,1.6l-3.1-0.9l-1.8,0.9l-3.8,0.5l-2.1-3.3l-1.3-2.1l-1.6-3.9l-1.3-4.7l-16.7-0.1l-2,0.7l-1.7-0.1l-2.3,0.9l-0.8-2
			l1.4-0.7l0.2-2.8l1-1.6l2-1.4l1.5,0.7l2-2.5l3.1,0.1l0.3,1.8l2.1,1.1l3.4-4l3.3-3.1l1.4-2.1l-0.1-5.3l2.5-6.2l2.6-3.3l3.7-3.1
			l0.7-2l0.1-2.4l0.9-2.2l-0.3-3.7l0.7-5.7l1.1-4l1.7-3.4l0.3-3.9l0.5-4.5l2.2-3.2l3-2.1l4.7,2.2l3.6,2.4l4.1,0.6l4.3,1.3l1.6-3.9
			l0.8-0.5l2.6,0.6l6.3-3.2l2.2,1.4l1.8-0.2l0.9-1.6l2.1-0.5l4.2,0.7l3.7,0.1L1141.3,468.2L1141.3,468.2z"
						/>
						<path
							id="CG"
							className={Styles.country}
							d="M1090.9,479.3l-0.3,3.9l-1.7,3.4l-1.1,4l-0.7,5.7l0.3,3.7l-0.9,2.2l-0.1,2.4l-0.7,2l-3.7,3.1
			l-2.6,3.3l-2.5,6.2l0.1,5.3l-1.4,2.1l-3.3,3.1l-3.4,4l-2.1-1.1l-0.3-1.8l-3.1-0.1l-2,2.5l-1.5-0.7l-2.1-2.2l-1.7,1.1l-2.3,2.8
			l-4.6-6.8l4.3-3.6l-2.1-4.2l2-1.6l3.8-0.8l0.4-2.9l3.1,3.1l5,0.3l1.7-3l0.7-4.3l-0.6-5l-2.7-3.8l2.5-7.5l-1.4-1.2l-4.2,0.5
			l-1.6-3.3l0.4-2.8l7.1,0.2l4.6,1.7l4.5,1.5l0.4-3.4l3-6l3.3-3.4l3.9,1.1L1090.9,479.3L1090.9,479.3z"
						/>
						<path
							id="DZ"
							className={Styles.country}
							d="M1031,264.6l-1,3.3l1,6.1l-1.1,5.3l-3.2,3.6l0.6,4.8l4.5,3.9l0.1,1.5l3.4,2.6l2.6,11.5l1.9,5.7l0.4,3
			l-0.8,5.2l0.4,3l-0.6,3.5l0.6,4l-2.2,2.7l3.4,4.7l0.2,2.7l2.1,3.6l2.5-1.2l4.5,3l2.5,4l-18.8,12.3l-16,12.6l-7.8,2.8l-6.2,0.7
			l-0.1-4.1l-2.6-1.1l-3.5-1.8l-1.3-3l-18.7-14l-18.6-14l-20.5-15.6l0.1-1.2l0.1-0.4l0.1-7.6l8.9-4.8l5.4-1l4.5-1.7l2.1-3.2l6.4-2.5
			l0.3-4.8l3.1-0.6l2.5-2.3l7.1-1.1l1-2.5l-1.4-1.4L977,284l-0.3-3.9l-1.9-4.1l5.1-3.5l5.8-1.1l3.3-2.6l5.1-2l9-1.1l8.8-0.5l2.7,0.9
			l4.9-2.5l5.7-0.1l2.2,1.5L1031,264.6L1031,264.6z"
						/>
						<path
							id="EG"
							className={Styles.country}
							d="M1172.1,301.4l3.9,9.4l0.7,1.6l-1.3,2.6l-0.7,4.8l-1.2,3.4l-1.2,1.1l-2-2.1l-2.7-2.8l-4.7-9.2
			l-0.5,0.6l2.8,6.7l3.9,6.5l4.9,10l2.3,3.5l2,3.6l5.4,7.1l-1,1.1l0.4,4.2l6.8,5.8l1.1,1.3h-22.1h-21.5h-22.3l-1-23.7l-1.3-22.8
			l-2-5.2l1.1-3.9l-1-2.8l1.7-3.1l7.2-0.1l5.4,1.7l5.5,1.9l2.6,1l4-2l2.1-1.8l4.7-0.6l3.9,0.8l1.8,3.2l1.1-2.1l4.4,1.5l4.3,0.4
			L1172.1,301.4L1172.1,301.4z"
						/>
						<path
							id="ER"
							className={Styles.country}
							d="M1228.9,420.3l-1.7,1.6l-2.4-0.6l-2-2.1l-2.5-3.7l-2.6-2.1l-1.5-2.2l-5-2.6l-3.9-0.1l-1.4-1.3
			l-3.2,1.5l-3.6-2.9l-1.5,4.8l-6.6-1.4l-0.7-2.5l2-9.5l0.3-4.2l1.7-2l4-1.1l2.7-3.6l3.6,7.4l1.9,5.9l3.2,3.1l8,6.1l3.3,3.6l3.2,3.8
			l1.8,2.2L1228.9,420.3L1228.9,420.3z"
						/>
						<path
							id="ET"
							className={Styles.country}
							d="M1207.3,408.5l3.9,0.1l5,2.6l1.5,2.2l2.6,2.1l2.5,3.7l2,2.1l-1.9,2.8l-1.8,3l0.5,1.8l0.2,2l3.2,0.1
			l1.3-0.5l1.3,1.2l-1.2,2.2l2.2,3.6l2.2,3.1l2.2,2.3l18.7,7.6l4.8-0.1l-15.6,19.3l-7.3,0.3l-5,4.5l-3.6,0.1l-1.5,2.1h-3.9l-2.3-2.2
			l-5.2,2.7l-1.6,2.7l-3.8-0.6l-1.3-0.7l-1.3,0.2l-1.8-0.1l-7.2-5.4h-4l-1.9-2.1l-0.1-3.6l-2.9-1.1l-3.5-7l-2.6-1.5l-1-2.6l-3-3.1
			l-3.5-0.5l1.9-3.6l3-0.2l0.8-1.9l-0.2-5v-0.8l1.5-6.7l2.6-1.8l0.5-2.6l2.3-5l3.3-3.1l2-6.4l0.7-5.5l6.6,1.4l1.5-4.8l3.6,2.9
			l3.2-1.5L1207.3,408.5L1207.3,408.5z"
						/>
						<path
							id="GA"
							className={Styles.country}
							d="M1060.5,487.3l-0.4,2.8l1.6,3.3l4.2-0.5l1.4,1.2l-2.5,7.5l2.7,3.8l0.6,5l-0.7,4.3l-1.7,3l-5-0.3
			l-3.1-3.1l-0.4,2.9l-3.8,0.8l-2,1.6l2.1,4.2l-4.3,3.6l-5.8-6.5l-3.7-5.3l-3.5-6.6l0.2-2.2l1.3-2l1.3-4.7l1.2-4.8l1.9-0.3h8.2v-7.7
			l2.7-0.4l3.4,0.8l3.4-0.8L1060.5,487.3L1060.5,487.3z"
						/>
						<path
							id="GH"
							className={Styles.country}
							d="M986.5,431.1l-0.4,2l2.3,3.3v4.7l0.6,5l1.4,2.4l-1.3,5.7l0.5,3.2l1.5,4.1l1.3,2.3l-8.9,3.7l-3.2,2.2
			l-5.1,1.9l-5-1.8l0.2-2.6l-2.4-5.5l1.5-7.3l2.4-5.3l-1.5-9.2l-0.8-4.8l0.2-3.7l9.8-0.3l2.5,0.5l1.8-1L986.5,431.1L986.5,431.1z"
						/>
						<path
							id="GN"
							className={Styles.country}
							d="M921.5,421.9l0.3,2.4h0.9l1.5-0.9l0.9,0.2l1.6,1.7l2.4,0.5l1.5-1.4l1.9-0.9l1.3-0.9l1.1,0.2l1.3,1.4
			l0.6,1.8l2.3,2.7l-1.1,1.6l-0.3,2.1l1.2-0.6l0.7,0.7l-0.3,1.9l1.7,1.9l-1.1,0.5l-0.5,2.2l1.3,2.6l1.4,5.2l-2.1,0.7l-0.5,0.9
			l0.4,1.3l-0.4,2.8h-0.9l-1.6-0.2l-1.1,2.6h-1.6l-1.1-1.4l0.4-2.6l-2.4-3.9l-1.4,0.7l-1.3,0.2l-1.5,0.3l0.1-2.3l-0.9-1.7l0.2-1.9
			l-1.2-2.7l-1.6-2.3h-4.5l-1.3,1.2l-1.6,0.2l-1,1.4l-0.6,1.7l-3.1,2.9l-2.4-3.8l-2.2-2.5l-1.4-0.9l-1.4-1.3l-0.6-2.8l-0.8-1.4
			l-1.7-1.1l2.6-3.1l1.7,0.1l1.5-1l1.2-0.1l0.9-0.8l-0.4-2.1l0.6-0.7l0.1-2.2l2.7,0.1l4.1,1.5l1.2-0.1l0.4-0.7l3.1,0.5L921.5,421.9
			L921.5,421.9z"
						/>
						<path
							id="GM"
							className={Styles.country}
							d="M891.6,417.4l0.8-2.9l6.1-0.1l1.3-1.6l1.8-0.1l2.2,1.6h1.7l1.9-1l1.1,1.8l-2.5,1.5l-2.4-0.2l-2.4-1.3
			l-2.1,1.5h-1l-1.4,0.9L891.6,417.4L891.6,417.4z"
						/>
						<path
							id="GW"
							className={Styles.country}
							d="M909.2,421l-0.1,2.2l-0.6,0.7l0.4,2.1l-0.9,0.8l-1.2,0.1l-1.5,1l-1.7-0.1l-2.6,3.1l-2.9-2.6l-2.4-0.5
			l-1.3-1.8l0.1-1l-1.7-1.3l-0.4-1.4l3-1l1.9,0.2l1.5-0.8L909.2,421L909.2,421z"
						/>
						<path
							id="GQ"
							className={Styles.country}
							d="M1050.3,487.3v7.7h-8.2l-1.9,0.3l-1.1-0.9l1.9-7.2L1050.3,487.3L1050.3,487.3z"
						/>
						<path
							id="KE"
							className={Styles.country}
							d="M1223.5,476.7l-4.9,7.2l0.2,23.4l3.3,5.3l-4,2.6l-1.4,2.7l-2.2,0.4l-0.8,4.6l-1.9,2.6l-1.1,4.2
			l-2.3,2.1l-8.1-6.4l-0.3-3.7l-20.5-13.1l0.4-4.7l-1.4-2.5v-0.3l1.6-2.6l2.8-4.2l2.1-4.7l-2.6-7.4l-0.7-3.2l-2.7-4.5l3.4-3.8
			l3.8-4.2l2.9,1.1l0.1,3.6l1.9,2.1h4l7.2,5.4l1.8,0.1l1.3-0.2l1.3,0.7l3.8,0.6l1.6-2.7l5.2-2.7l2.3,2.2L1223.5,476.7L1223.5,476.7z
			"
						/>
						<path
							id="LR"
							className={Styles.country}
							d="M938.6,452.5l-0.2,1.8l0.5,3.1l-1.2,2.9l1.6,1.8l1.8,0.4l2.4,2.7l0.2,2.5l-0.6,0.8l-0.4,5.3l-1.5,0.1
			l-5.8-3.1l-5.2-4.9l-4.8-3.5l-3.8-4.1l1.4-2.1l0.3-1.9l2.6-3.4l2.6-3l1.3-0.2l1.4-0.7l2.4,3.9l-0.4,2.6l1.1,1.4h1.6l1.1-2.6
			L938.6,452.5L938.6,452.5z"
						/>
						<path
							id="LY"
							className={Styles.country}
							d="M1122.6,299.1l-1.7,3.1l1,2.8l-1.1,3.9l2,5.2l1.3,22.8l1,23.7l0.5,12.8h-6.4v2.7l-22.6-12.3
			l-22.5-12.3l-5.5,3.5l-3.8,2.4l-3.2-3.5l-8.8-2.8l-2.5-4l-4.5-3l-2.5,1.2l-2.1-3.6l-0.2-2.7l-3.4-4.7l2.2-2.7l-0.6-4l0.6-3.5
			l-0.4-3l0.8-5.2l-0.4-3l-1.9-5.7l2.6-1.4l0.4-2.8l-0.6-2.6l3.6-2.5l1.6-2.1l2.6-1.8l0.1-4.9l6.4,2.2l2.3-0.6l4.5,1.1l7.3,2.9
			l2.8,5.7l4.9,1.2l7.8,2.7l6,3.2l2.5-1.7l2.5-2.9l-1.6-4.9l1.5-3.2l3.7-3l3.7-0.8l7.4,1.3l2,2.8l2,0.1l1.8,1.1l5.4,0.7
			L1122.6,299.1L1122.6,299.1z"
						/>
						<path
							id="LS"
							className={Styles.country}
							d="M1139.1,697.9l-2,0.7l-3.7-5l3.2-4l3.1-2.5l2.7-1.4l2.2,2l1.7,2l-1.9,3.1l-1.1,2.1l-3.1,1
			L1139.1,697.9L1139.1,697.9z"
						/>
						<path
							id="MA"
							className={Styles.country}
							d="M974.8,276l1.9,4.1l0.3,3.9l1.9,6.8l1.4,1.4l-1,2.5l-7.1,1.1l-2.5,2.3l-3.1,0.6l-0.3,4.8l-6.4,2.5
			l-2.1,3.2l-4.5,1.7l-5.4,1l-8.9,4.8l-0.1,7.6H938l0.1,3.4l-3.4,0.2l-1.8,1.5h-2.5l-2-0.9l-4.6,0.7l-1.9,5l-1.8,0.5l-2.7,8.1
			l-7.9,6.9l-2,8.9l-2.4,2.9l-0.7,2.3l-12.5,0.5h-0.1l0.3-3l2.2-1.7l1.9-3.4l-0.3-2.2l2-4.5l3.2-4.1l1.9-1l1.6-3.7l0.2-3.5l2.1-3.9
			l3.8-2.4l3.6-6.5l0.1-0.1l2.9-2.5l5.1-0.7l4.4-4.4l2.8-1.7l4.7-5.4l-1.2-7.9l2.2-5.6l0.9-3.4l3.6-4.3l5.4-2.9l4.1-2.7l3.7-6.6
			l1.8-4l3.9,0.1l3.1,2.7l5.1-0.4l5.5,1.4L974.8,276L974.8,276z"
						/>
						<path
							id="MG"
							className={Styles.country}
							d="M1267.9,588.9l0.4,7.7l1.3,3l-0.7,3.1l-1.2,1.8l-1.6-3.7l-1.2,1.9l0.8,4.7l-0.7,2.8l-1.7,1.4
			l-0.7,5.5l-2.7,7.5l-3.4,8.8l-4.3,12.2l-2.9,8.9l-3.1,7.5l-4.6,1.5l-5.1,2.7l-3-1.6l-4.2-2.3l-1.2-3.4v-5.7l-1.5-5.1l-0.2-4.7
			l1.3-4.6l2.6-1.1l0.2-2.1l2.9-4.9l0.8-4.1l-1.1-3l-0.8-4.1l-0.1-5.9l2.2-3.6l1-4.1l2.8-0.2l3.2-1.3l2.2-1.2l2.4-0.1l3.4-3.6l4.9-4
			l1.8-3.2l-0.6-2.8l2.4,0.8l3.3-4.4l0.3-3.9l2-2.9l1.8,2.8l1.4,2.7L1267.9,588.9L1267.9,588.9z"
						/>
						<path
							id="ML"
							className={Styles.country}
							d="M1010.2,378.8l0.1,14.8l-3.1,4.3l-0.4,4l-5,1l-7.7,0.5l-2,2.3l-3.6,0.3h-3.6l-1.4-1.2l-3.1,0.9
			l-5.3,2.7l-1.1,2l-4.3,2.8l-0.8,1.7l-2.4,1.3l-2.7-0.9l-1.5,1.6l-0.9,4.4l-4.5,5.3l0.2,2.2l-1.6,2.7l0.4,3.7l-2.4,1l-1.3,0.8
			l-0.9-2.7l-1.6,0.7l-1-0.1l-1,1.8h-4.4l-1.5-1l-0.8,0.6l-1.7-1.9l0.3-1.9l-0.7-0.7l-1.2,0.6l0.3-2.1l1.1-1.6l-2.3-2.7l-0.6-1.8
			l-1.3-1.4l-1.1-0.2l-1.3,0.9l-1.9,0.9l-1.5,1.4l-2.4-0.5l-1.6-1.7l-0.9-0.2l-1.5,0.9h-0.9l-0.3-2.4l0.3-2l-0.5-2.4l-2-1.8
			l-1.1-3.7l-0.2-4l1.9-1.2l1-3.8l1.8-0.1l3.9,1.8l3.2-1.3l2.1,0.4l0.9-1.4l22.5-0.1l1.3-4.5l-1-0.8l-2.5-27.7l-2.4-27.7l8.5-0.1
			l18.6,14l18.7,14l1.3,3l3.5,1.8l2.6,1.1l0.1,4.1L1010.2,378.8L1010.2,378.8z"
						/>
						<path
							id="MZ"
							className={Styles.country}
							d="M1166.7,673.5h-4.1l-0.3-2.9l-0.6-2.9l-0.4-2.3l1.4-7.1l-1.1-4.6l-2.2-9l6.2-7.3l1.7-4.6l0.8-0.6
			l0.9-3.8l-0.8-1.9l0.4-4.8l1.3-4.4l0.4-8.2l-2.8-2l-2.7-0.5l-1.1-1.6l-2.6-1.3l-4.7,0.1l-0.2-2.4l-0.4-4.6l17.2-5.3l3.2,3.1
			l1.5-0.6l2.2,1.6l0.2,2.6l-1.3,3l0.2,4.5l3.5,4l1.9-4.5l2.5-1.3l-0.1-8.3l-2.2-4.6l-1.9-2.1h-0.4l-0.6-7.3l1.5-6.1l2.2-0.2
			l6.7,1.8l1.5-0.8l3.9-0.2l2.1-1.9l3.4,0.1l6.2-2.5l4.6-3.7l0.9,2.8l-0.5,6.4l0.5,5.7l-0.2,10l0.8,3.1l-1.9,4.6l-2.4,4.5l-3.7,4
			l-5.3,2.4l-6.5,3.1l-6.6,6.9l-2.2,1.2l-4.2,4.6l-2.3,1.4l-0.8,4.6l2.4,4.9l0.9,3.7v2l1-0.4l-0.5,6.3l-1.1,3l1.2,1.1l-1,2.7
			l-2.4,2.3l-4.7,2.1l-6.9,3.5l-2.5,2.4l0.3,2.7l1.3,0.4L1166.7,673.5L1166.7,673.5z"
						/>
						<path
							id="MR"
							className={Styles.country}
							d="M959.2,341.5l-8.5,0.1l2.4,27.7l2.5,27.7l1,0.8l-1.3,4.5l-22.5,0.1l-0.9,1.4l-2.1-0.4l-3.2,1.3
			l-3.9-1.8l-1.8,0.1l-1,3.8L918,408l-3.6-4.4l-3.4-4.8l-3.6-1.7l-2.7-1.8h-3.1l-2.8,1.4l-2.7-0.5l-2,2l-0.4-3.4l1.6-3.2l0.8-6
			l-0.4-6.4l-0.6-3.2l0.6-3.2l-1.4-3l-2.8-2.8l1.3-2.1h21.7l-0.9-9.3l1.5-3.3l5.2-0.5l0.2-16.5l18,0.4l0.2-9.8L959.2,341.5
			L959.2,341.5z"
						/>
						<path
							id="NA"
							className={Styles.country}
							d="M1116.2,614.3l4.6-1.4l3.6,0.3l2.2,1.5v0.5l-3.2,1.5h-1.7l-3.7,2.5l-2-2.7l-8.7,2.3l-4.2,0.2
			l-0.8,22.9l-5.5,0.2l-0.6,18.7l-1.1,23.7l-5,3.3l-2.9,0.5l-3.4-1.2l-2.5-0.5l-0.8-2.7l-2-1.8l-2.8,3.2l-3.9-4.9l-2-4.6l-1-6.3
			l-1.2-4.6l-1.6-9.9l0.1-7.7l-0.6-3.5l-2.1-2.7l-2.8-5.3l-2.8-7.7l-1.1-4l-4.4-6.3l-0.3-4.9l2.7-1.2l3.4-1.1l3.6,0.2l3.3,2.9
			l0.8-0.5l22.7-0.2l3.8,3l13.5,0.9L1116.2,614.3L1116.2,614.3z"
						/>
						<path
							id="NE"
							className={Styles.country}
							d="M1068.6,355l1.6,10l2.2,1.7l0.1,2l2.4,2.2l-1.2,2.8l-1.8,13l-0.2,8.4l-7,6l-2.3,8.5l2.4,2.4v4.1
			l3.7,0.1l-0.6,3.1l-1.5,0.3l-0.2,2.1l-1,0.1l-3.9-7l-1.4-0.3l-4.3,3.6l-4.4-1.9l-3-0.3l-1.6,0.9l-3.3-0.2l-3.3,2.7l-2.9,0.2
			l-6.8-3.3l-2.7,1.5l-2.9-0.1l-2.1-2.4l-5.6-2.4l-6.1,0.8l-1.4,1.3l-0.8,3.7l-1.6,2.6l-0.4,5.8l-4.3-3.7h-2l-1.9,1.9l0.1-4.4
			l-6.5-1.5l-0.2-3.1l-3.1-4.2l-0.8-2.9l0.5-3.1l3.6-0.3l2-2.3l7.7-0.5l5-1l0.4-4l3.1-4.3l-0.1-14.8l7.8-2.8l16-12.6l18.8-12.3
			l8.8,2.8l3.2,3.5L1068.6,355L1068.6,355z"
						/>
						<path
							id="NG"
							className={Styles.country}
							d="M1066.2,421.7l2.3,2.5l-0.6,1.2l-0.3,2.1l-4.7,5l-1.4,4.1l-0.8,3.3l-1.2,1.5l-1.1,4.5l-3,2.6
			l-0.8,3.2l-1.3,2.6l-0.5,2.7l-3.9,2.2l-3.2-2.7l-2.1,0.1l-3.4,3.8h-1.6l-2.7,6.2l-1.4,4.6l-5.9,2.3l-2.1-0.3l-2.2,1.4l-4.5-0.1
			l-3.1-4.1l-1.9-4.6l-4-4.2h-4.2h-5l0.3-10.3l-0.2-4.1l1.1-4l1.7-2l2.8-4l-0.6-1.7l1.1-2.6l-1.3-3.8l0.2-2.1l0.4-5.8l1.6-2.6
			l0.8-3.7l1.4-1.3l6.1-0.8l5.6,2.4l2.1,2.4l2.9,0.1l2.7-1.5l6.8,3.3l2.9-0.2l3.3-2.7l3.3,0.2l1.6-0.9l3,0.3l4.4,1.9l4.3-3.6
			l1.4,0.3l3.9,7L1066.2,421.7L1066.2,421.7z"
						/>
						<path
							id="RW"
							className={Styles.country}
							d="M1158.8,509.1l2.2,3.6l-0.3,3.8l-1.6,0.8l-3.1-0.4l-1.7,3.7l-3.5-0.5l0.6-3.6l0.8-0.5l0.2-3.8
			l1.6-1.8l1.4,0.7L1158.8,509.1L1158.8,509.1z"
						/>
						<path
							id="EH"
							className={Styles.country}
							d="M938.9,324.3l-0.1,0.4l-0.1,1.2l-0.2,9.8l-18-0.4l-0.2,16.5l-5.2,0.5l-1.5,3.3l0.9,9.3h-21.7
			l-1.3,2.1l0.3-2.7h0.1l12.5-0.5l0.7-2.3l2.4-2.9l2-8.9l7.9-6.9l2.7-8.1l1.8-0.5l1.9-5l4.6-0.7l2,0.9h2.5l1.8-1.5l3.4-0.2l-0.1-3.4
			L938.9,324.3L938.9,324.3z"
						/>
						<path
							id="SD"
							className={Styles.country}
							d="M1191,409.2l-0.7,5.5l-2,6.4l-3.3,3.1l-2.3,5l-0.5,2.6l-2.6,1.8l-1.5,6.7v0.8l-0.8-0.2l0.1-3.2
			l-0.8-2.2l-2.9-2.5l-0.9-4.6l0.6-4.8l-2.6-0.4l-0.4,1.4l-3.4,0.4l1.5,1.8l0.5,3.9l-3,3.5l-2.7,4.6l-2.9,0.7l-4.8-3.7l-2.1,1.3
			l-0.5,1.8l-2.9,1.3l-0.2,1.3h-5.6l-0.8-1.3l-4.1-0.3l-2,1.1l-1.6-0.5l-2.9-3.8l-1-1.7l-4.1,0.9l-1.5,2.9l-1.3,5.8l-2,1.2l-1.7,0.7
			l-0.5-0.3l-1.9-1.9l-0.4-2l0.8-2.6V437l-3.3-4l-0.7-2.7v-1.6l-2.1-1.9l-0.1-3.7l-1.3-2.5l-1.9,0.4l0.5-2.4l1.4-2.6l-0.7-2.7l1.8-2
			l-1.2-1.5l1.3-3.9l2.5-4.8l4.8,0.5l-1.1-25.5v-2.7h6.4l-0.5-12.8h22.3h21.5h22.1l2.1,6.3l-1.2,1.1l1.2,6.7l2.5,7.6l2.2,1.6
			l3.2,2.4l-2.7,3.6l-4,1.1l-1.7,2l-0.3,4.2l-2,9.5L1191,409.2L1191,409.2z"
						/>
						<path
							id="SS"
							className={Styles.country}
							d="M1178.1,441.1l0.2,5l-0.8,1.9l-3,0.2l-1.9,3.6l3.5,0.5l3,3.1l1,2.6l2.6,1.5l3.5,7l-3.8,4.2l-3.4,3.8
			l-3.5,3h-4l-4.5,1.5l-3.6-1.5l-2.3,1.8l-5.1-4.3l-1.4-2.7l-3.1,1.3l-2.6-0.4l-1.5,1.1l-2.6-0.8l-3.5-5.3l-0.9-2l-4.3-2.6l-1.4-3.8
			l-2.4-2.8l-3.9-3.3l-0.1-2.1l-3.1-2.6l-3.9-2.5l1.7-0.7l2-1.2l1.3-5.8l1.5-2.9l4.1-0.9l1,1.7l2.9,3.8l1.6,0.5l2-1.1l4.1,0.3
			l0.8,1.3h5.6l0.2-1.3l2.9-1.3l0.5-1.8l2.1-1.3l4.8,3.7l2.9-0.7l2.7-4.6l3-3.5l-0.5-3.9l-1.5-1.8l3.4-0.4l0.4-1.4l2.6,0.4l-0.6,4.8
			l0.9,4.6l2.9,2.5l0.8,2.2l-0.1,3.2L1178.1,441.1L1178.1,441.1z"
						/>
						<path
							id="SN"
							className={Styles.country}
							d="M918,408l0.2,4l1.1,3.7l2,1.8l0.5,2.4l-0.3,2l-0.8,0.4l-3.1-0.5l-0.4,0.7l-1.2,0.1l-4.1-1.5l-2.7-0.1
			l-10.4-0.3l-1.5,0.8l-1.9-0.2l-3,1l-0.8-4.9l5.1,0.1l1.4-0.9h1l2.1-1.5l2.4,1.3l2.4,0.2l2.5-1.5l-1.1-1.8l-1.9,1h-1.7l-2.2-1.6
			l-1.8,0.1l-1.3,1.6l-6.1,0.1l-2.3-5l-2.7-2.2l2.5-1.3l2.8-4.5l1.4-3.3l2-2l2.7,0.5l2.8-1.4h3.1l2.7,1.8l3.6,1.7l3.4,4.8L918,408
			L918,408z"
						/>
						<path
							id="SL"
							className={Styles.country}
							d="M928.5,447.9l-2.6,3l-2.6,3.4l-0.3,1.9l-1.4,2.1l-1.5-0.5l-4-2.6l-3-3.4l-0.9-2.4l-0.7-4.7l3.1-2.9
			l0.6-1.7l1-1.4l1.6-0.2l1.3-1.2h4.5l1.6,2.3l1.2,2.7l-0.2,1.9l0.9,1.7l-0.1,2.3L928.5,447.9L928.5,447.9z"
						/>
						<path
							id="SZ"
							className={Styles.country}
							d="M1161.7,667.7l0.6,2.9l0.3,2.9l-1.4,2.8l-3.2,0.7l-3.1-3.5l0.1-2.2l1.7-2.4l0.6-1.9l1.7-0.4
			L1161.7,667.7L1161.7,667.7z"
						/>
						<path
							id="TD"
							className={Styles.country}
							d="M1119.2,376.1l1.1,25.5l-4.8-0.5l-2.5,4.8l-1.3,3.9l1.2,1.5l-1.8,2l0.7,2.7l-1.4,2.6l-0.5,2.4
			l1.9-0.4l1.3,2.5l0.1,3.7l2.1,1.9v1.6l-3.6,1.1l-2.8,2.6l-4,7l-5.2,3l-5.5-0.4l-1.6,0.6l0.6,2.2l-2.9,2.3l-2.4,2.5l-7.1,2.4
			l-1.4-1.4l-1-0.2l-1,1.7l-4.6,0.5l0.8-1.8l-1.8-4.4l-0.8-2.6l-2.5-1.1l-3.4-3.8l1.2-3l2.6,0.6l1.6-0.4h3.2l-3.2-5.8l0.2-4.3
			l-0.5-4.2l-2.3-4.1l0.6-3.1l-3.7-0.1V412l-2.4-2.4l2.3-8.5l7-6l0.2-8.4l1.8-13l1.2-2.8l-2.4-2.2l-0.1-2l-2.2-1.7l-1.6-10l5.5-3.5
			l22.5,12.3L1119.2,376.1L1119.2,376.1z"
						/>
						<path
							id="TG"
							className={Styles.country}
							d="M991.4,431.2l-0.7,3.4l1.7,1.9l2,2.2l0.2,3.2l1.2,1.3l-0.3,14.8l1.4,4.4l-4.5,1.4l-1.3-2.3l-1.5-4.1
			l-0.5-3.2l1.3-5.7l-1.4-2.4l-0.6-5v-4.7l-2.3-3.3l0.4-2L991.4,431.2L991.4,431.2z"
						/>
						<path
							id="TN"
							className={Styles.country}
							d="M1048.2,289.1l-0.1,4.9l-2.6,1.8l-1.6,2.1l-3.6,2.5l0.6,2.6l-0.4,2.8l-2.6,1.4l-2.6-11.5l-3.4-2.6
			l-0.1-1.5l-4.5-3.9l-0.6-4.8l3.2-3.6l1.1-5.3l-1-6.1l1-3.3l5.7-2.5l3.7,0.7v3.3l4.4-2.4l0.4,1.2l-2.5,3.2l0.1,2.9l1.9,1.6
			l-0.5,5.6l-3.5,3.2l1.2,3.5l2.8,0.1l1.4,3.1L1048.2,289.1L1048.2,289.1z"
						/>
						<path
							id="TZ"
							className={Styles.country}
							d="M1167,508.4l-0.2,3.9l-1.1,4.5l1.6,2.5l2.5-1.5l3.3-0.4l0.7,0.8l3.3-1.6l-2.3-2.2l1.9-2.9l2.8-2.9
			l20.5,13.1l0.3,3.7l8.1,6.4l-2.8,8l0.3,3.6l3.5,2.3l0.2,1.7l-1.7,3.9l0.3,1.9l-0.4,3.1l1.8,4l2.2,6.4l2,1.4l-4.6,3.7l-6.2,2.5
			l-3.4-0.1l-2.1,1.9l-3.9,0.2l-1.5,0.8l-6.7-1.8l-2.2,0.2l0.1-0.1l-1.8-2.4l-0.3-6.8l-2.9-3.4l-0.4,1.2l-1-1.7l-5.5-1.2l-3.2-1.9
			l-3.6-1.1l-2.2-1.1l-0.3-0.2l-2.7-6.6l-0.4-3.9l-4.5-4.4l1.4-2.4l-1.1-2.6l0.2-2.7l-1-0.9l0.3-2.8l0.6-0.1l2-2.3l2.3-3.4l1.4-1.3
			v-2.1l-1.2-1.5l-0.3-2.5l1.6-0.8l0.3-3.8l-2.2-3.6l2-0.8L1167,508.4L1167,508.4z"
						/>
						<path
							id="UG"
							className={Styles.country}
							d="M1179,474.5l2.7,4.5l0.7,3.2l2.6,7.4l-2.1,4.7l-2.8,4.2l-1.6,2.6v0.3l-0.2-0.4l-3-1.3l-2.4,1.6
			l-3.6,0.9l-2.6,3.7l0.3,2.5l-6.2-0.1l-2,0.8l-3.4,2l-1.4-0.7l0.1-4.8l1.3-2.5l0.3-5.1l1.2-3l2.2-3.3l2.1-1.8l1.9-2.2l-2.3-0.9
			l0.3-7.5l2.3-1.8l3.6,1.5l4.5-1.5h4L1179,474.5L1179,474.5z"
						/>
						<path
							id="ZM"
							className={Styles.country}
							d="M1162.1,556.8l0.3,0.2l2.2,1.1l3.6,1.1l3.2,1.9l2.6,2.9l1.3,5.4l-1,1.7l-1.3,5.2l0.9,5.4l-1.8,2.2
			l-2,6l2.9,1.6l-17.2,5.3l0.4,4.6l-4.3,0.9l-3.3,2.5l-0.8,2.2l-2,0.6l-5.1,5.2l-3.2,4.2l-1.9,0.1l-1.8-0.7l-6.2-0.7l-1-0.5v-0.5
			l-2.2-1.5l-3.6-0.3l-4.6,1.4l-3.6-4l-3.6-5.2l0.8-20.5l11.7,0.1l-0.4-2.2l0.9-2.4l-0.9-3l0.7-3.1l-0.6-2l1.9,0.1l0.3,2l2.7-0.1
			l3.5,0.6l1.9,2.9l4.4,0.9l3.5-2l1.2,3.3l4.3,0.9l2,2.8l2.2,3.5h4.3l-0.2-6.9l-1.6,1.2l-3.9-2.5l-1.5-1.2l0.9-6.4l1.1-7.6l-1.2-2.8
			l1.7-4.1l1.5-0.8l7.7-1.1l0.9,0.3l-0.3,1.4l1.9,0.5l1.2,1.3l1-0.3L1162.1,556.8L1162.1,556.8z"
						/>
						<path
							id="ZW"
							className={Styles.country}
							d="M1159.4,644.7l-2.9-0.7l-1.9,0.8l-2.7-1.1h-2.2l-3.4-2.9l-4.3-1l-1.5-4.1l0.1-2.3l-2.3-0.7l-6.1-7
			l-1.6-3.7l-1.1-1.2l-1.9-5.1l6.2,0.7l1.8,0.7l1.9-0.1l3.2-4.2l5.1-5.2l2-0.6l0.8-2.2l3.3-2.5l4.3-0.9l0.2,2.4l4.7-0.1l2.6,1.3
			l1.1,1.6l2.7,0.5l2.8,2l-0.4,8.2l-1.3,4.4l-0.4,4.8l0.8,1.9l-0.9,3.8l-0.8,0.6l-1.7,4.6L1159.4,644.7L1159.4,644.7z"
						/>
						<path
							id="SO"
							className={Styles.country}
							d="M1222.1,512.6l-3.3-5.3l-0.2-23.4l4.9-7.2l1.5-2.1l3.6-0.1l5-4.5l7.3-0.3l15.6-19.3l-4.8,0.1
			l-18.7-7.6l-2.2-2.3l-2.2-3.1l-2.2-3.6l1.2-2.2l1.9-3.5l1.9,1.2l1.2,2.7l2.7,2.7h2.8l5.2-1.7l6.1-0.7l4.9-2l2.8-0.4l2-1.2l3.2-0.2
			l1.8-0.2l2.5-0.9l3-0.7l2.5-2.2h2.2l0.2,1.8l-0.4,3.7l0.2,3.4l-1.1,2.3l-1.4,7l-2.4,7.1l-3.3,8.2l-4.6,9.4l-4.7,7.2l-6.6,8.8
			l-5.6,5.2l-8.4,6.4l-5.3,4.8l-6.2,7.8l-1.3,3.4L1222.1,512.6L1222.1,512.6z"
						/>
						<path
							id="ZA"
							className={Styles.country}
							d="M1159.4,644.7l2.2,9l1.1,4.6l-1.4,7.1l0.4,2.3l-2.7-1.1l-1.7,0.4l-0.6,1.9l-1.7,2.4l-0.1,2.2l3.1,3.5
			l3.2-0.7l1.4-2.8h4.1l-1.7,4.7l-1,5.3l-1.7,2.9l-4,3.3l-1.1,0.9l-2.6,3.3l-1.8,3.3l-3.5,4.6l-6.7,6.6l-4.1,3.8l-4.3,3l-5.9,2.4
			l-2.7,0.4l-0.9,1.8l-3.2-1l-2.7,1.2l-5.7-1.2l-3.3,0.8l-2.2-0.4l-5.8,2.6l-4.6,1l-3.5,2.4l-2.4,0.2l-2.1-2.3l-1.8-0.1l-2.2-2.9
			l-0.3,0.9l-0.6-1.7l0.3-3.8l-1.5-4.3l1.8-1.2l0.1-4.9l-3.3-6l-2.4-5.4v-0.1l-3.6-8.3l2.8-3.2l2,1.8l0.8,2.7l2.5,0.5l3.4,1.2
			l2.9-0.5l5-3.3l1.1-23.7l1.4,1l3,6.1l-0.6,3.9l1.1,2.3l4-0.7l2.8-2.9l2.7-1.9l1.5-3.1l2.8-1.4l2.3,0.7l2.5,1.8l4.5,0.3l3.6-1.4
			l0.6-2l1.2-3.1l3-0.5l1.8-2.4l2-4.3l5.2-4.8l8.1-4.7h2.2l2.7,1.1l1.9-0.8L1159.4,644.7L1159.4,644.7z M1139.1,697.9l1.1-2l3.1-1
			l1.1-2.1l1.9-3.1l-1.7-2l-2.2-2l-2.7,1.4l-3.1,2.5l-3.2,4l3.7,5L1139.1,697.9L1139.1,697.9z"
						/>
						<path
							className={Styles.country}
							d="M1233.2,581.3l-0.9-0.1l-0.2-0.4v-0.3l0.5,0.1l0.7,0.5L1233.2,581.3z"
						/>
						<path
							className={Styles.country}
							d="M1236.9,579.4l0.2,1v0.7l-0.1,0.2l-0.2-0.3l-0.4-0.3l-0.1-0.2l-0.3-0.1l-0.6-0.4l0.1-0.1l0.5,0.2l0.3-0.1
			l0.2-0.4v-0.2l0.3-0.1L1236.9,579.4z"
						/>
						<path
							className={Styles.country}
							d="M1231.2,578.4l-0.5-0.4l-0.3-0.1l-0.3-0.2l-0.2-0.6l0.1-0.3v-0.2l0.2-1.2l-0.1-0.1l0.2-0.4l0.5-0.1l0.2,0.3
			l-0.2,1.2l0.1,0.3l0.2,0.4l0.1,0.5l0.2,0.6L1231.2,578.4z"
						/>
						<path
							className={Styles.country}
							d="M849.7,406.6l-0.2,0.1l-0.4-0.1l-0.3-0.3l-0.1-0.3l0.2-0.4l0.5-0.3h0.4l0.2,0.7l-0.1,0.4L849.7,406.6z"
						/>
						<path
							className={Styles.country}
							d="M853.2,403.5l0.2,0.1v0.2l0.2,0.2l0.4,0.5h0.2l0.2,0.3l0.2,0.5l0.2,0.2l-0.3,0.5l-0.3,0.1l-0.5-0.1l-0.3-0.1
			l-0.3-0.3v-0.2l-0.2-0.1l-0.1-0.4l0.1-0.2v-0.3l0.2-0.4l-0.2-0.5H853.2z"
						/>
						<path
							className={Styles.country}
							d="M856.3,403.5l0.3,0.1l0.1,0.6l-0.1,0.4l-0.4,0.2l-0.4-0.4l0.2-0.4l-0.1-0.2L856.3,403.5z"
						/>
						<path
							className={Styles.country}
							d="M858.3,397.8l0.3-0.2l0.5,0.2l0.1,0.2v0.7l-0.4,0.3l-0.3,0.2h-0.3l-0.5-0.2v-0.5l0.2-0.2v-0.6l0.1-0.1
			L858.3,397.8z"
						/>
						<path
							className={Styles.country}
							d="M850.3,395l0.4-0.1l0.3,0.2h0.4l0.3,0.2l0.1,0.2h-0.5l-0.7-0.3l-0.2,0.1l-0.3,0.6l-0.3-0.6l-0.1-0.1l-0.1-0.4
			l0.3-0.1L850.3,395z"
						/>
						<path
							className={Styles.country}
							d="M858.1,394.5l0.1,0.1l-0.1,0.6l-0.2,0.1v-0.5l-0.2-0.3v-0.3l-0.1-0.3l0.4-0.3l0.2,0.2l-0.1,0.6V394.5z"
						/>
						<path
							className={Styles.country}
							d="M1307.7,630.8l0.4,0.9l-0.2,0.6l-0.4,0.4l0.1,0.3l-0.3,0.3l-0.5,0.2h-0.5l-0.6-0.1l-0.1,0.1l-0.3-0.3l0.2-0.2
			l0.1-0.4l0.1-0.7l0.2-0.4l0.5-0.4l0.1-0.2l0.2-0.5l0.4-0.3l0.4,0.1L1307.7,630.8z"
						/>
						<path
							className={Styles.country}
							d="M1339.5,628.7l-0.3-0.3l0.4-0.2l0.3-0.1l0.2,0.1l-0.1,0.2L1339.5,628.7z"
						/>
						<path
							className={Styles.country}
							d="M1023.9,501.3h-0.2l-0.2,0.3h-0.2l-0.1-0.4l-0.2-0.8v-0.3l0.4-0.5l0.3-0.1l0.3-0.3l0.4,0.1l0.2,0.4l0.1,0.3
			l-0.1,0.4l-0.2,0.3l-0.3,0.4L1023.9,501.3z"
						/>
						<path
							className={Styles.country}
							d="M1028.5,491.5l-0.1,0.3l-0.2,0.1l-0.3-0.1v-0.3l0.2-0.1v-0.3l0.2-0.2l0.3,0.1L1028.5,491.5z"
						/>
						<path
							className={Styles.country}
							d="M1249,562.1l-0.3,0.2h-0.5l-0.4,0.2h-0.4v-0.2h0.5l0.4-0.1l0.4-0.3h0.2L1249,562.1z"
						/>
						<path
							className={Styles.country}
							d="M1248.5,561.8l-0.2,0.1h-0.4v-0.2L1248.5,561.8z"
						/>
						<path
							className={Styles.country}
							d="M1300.4,531.5l0.4,0.4l-0.2,0.3l-0.2-0.3l-0.3-0.2l0.2-0.5L1300.4,531.5z"
						/>
						<path
							className={Styles.country}
							d="M888.4,323.4l-0.3,0.5l-0.4,0.5l-0.3-0.4H887l-0.2-0.2l0.2-0.3l0.4,0.1l0.4-0.4l0.3-0.2l0.2,0.1L888.4,323.4z
			"
						/>
						<path
							className={Styles.country}
							d="M902,321.1v0.5l0.2,0.4l-0.2,0.7l0.1,0.3l-0.4,0.4l-0.5,0.2l-0.2,0.2l-0.6-0.2l-0.5-0.5l-0.2-0.4v-0.6
			l0.6-0.4l0.1-0.5V321l0.6,0.1h0.4l0.3,0.1L902,321.1z"
						/>
						<path
							className={Styles.country}
							d="M892.1,321.9h-0.2l-0.3-0.2l-0.2-0.3l0.1-0.4l0.1-0.3h0.3h0.3l0.5,0.4l0.1,0.3l-0.5,0.5H892.1z"
						/>
						<path
							className={Styles.country}
							d="M898.2,318.4v0.2l-0.6,0.3l-0.4,0.5l-0.3,0.2v0.4l-0.4,0.7l-0.1,0.4l-0.5,0.6l-0.1,0.2h-0.2l-0.6,0.2
			l-0.1-0.1l-0.1-0.4l-0.3-0.4l-0.1-0.2l-0.2-0.3v-0.3l-0.4-0.6l0.5-0.3l0.3,0.2l0.6-0.2h0.4l0.5-0.2l0.5-0.4v-0.2l0.6-0.3h0.6
			l0.3-0.1L898.2,318.4z"
						/>
						<path
							className={Styles.country}
							d="M908.4,321.2l-0.3,0.4l-0.4,0.1l-0.3-0.1H907v-0.2h0.3l0.6-0.2l0.4-0.3l0.3-0.3l0.1-0.5l0.1-0.3l0.2-0.5
			l0.3-0.4l0.3-0.6l0.2-0.8l0.2-0.2l0.4-0.1l0.3,0.3l0.1,0.5l-0.1,0.5l-0.1,0.5v0.5l-0.1,0.1l-0.3,0.7l-0.3,0.3l-0.6,0.1l-0.7,0.3
			L908.4,321.2z"
						/>
						<path
							className={Styles.country}
							d="M888.8,316.7l0.3-0.1l0.2,0.3l0.2,0.5l-0.2,0.3l0.1,0.4l-0.6,1l-0.1-0.1l-0.1-0.4l-0.4-0.9l-0.1-0.3l-0.1-0.2
			l0.2-0.4l0.3-0.2L888.8,316.7z"
						/>
						<path
							className={Styles.country}
							d="M912.9,314.7v0.4l-0.2,0.5l-0.7,0.5l-0.5,0.1l-0.4,0.5l-0.5-0.2v-0.1l0.2-0.4v-0.4l0.2-0.3l0.3-0.2h0.3
			l0.3-0.3h0.5l0.1-0.1l0.2-0.5l0.2-0.1l0.2,0.2L912.9,314.7z"
						/>
						<path
							id="YT"
							className={Styles.country}
							d="M1240.2,583.1l0.2,0.3l0.5,0.2v0.3l-0.2,0.2l0.1,0.2l-0.3,0.6l0.1,0.2l-0.3,0.1l-0.2-0.3v-0.3
			l0.2-0.2l-0.2-0.7l-0.1-0.1l-0.1-0.2L1240.2,583.1z"
						/>
						<path
							id="RE"
							className={Styles.country}
							d="M1295,635.8h0.4l0.4,0.2l0.3,0.3v0.3l0.1,0.5l0.3,0.2l0.2,0.2l0.1,0.2l-0.2,0.6l-0.1,0.4l-0.2,0.2
			l-0.4,0.1h-0.9l-0.2-0.2l-0.8-0.4l-0.3-0.5v-0.3l-0.3-0.6l0.1-0.4l0.2-0.2l0.2-0.4h0.1l0.5-0.2L1295,635.8L1295,635.8z"
						/>
					</g>
				</g>
				<g
					id="North_America"
					className={Styles.continent}
					data-active={active == "North America"}
					onClick={() => setActiveContinent("North America")}
				>
					<path
						id="BZ"
						className={Styles.country}
						d="M487.8,399.8h-1.7l1.3-7.2l0.7-5.1l0.1-1l0.7-0.3l0.9,0.8l2.5-3.9l1.1-0.1l-0.1,1h1l-0.3,1.8l-1.3,2.7
		l0.4,1l-0.9,2.3l0.3,0.6l-1,3.3l-1.3,1.7l-1.1,0.2L487.8,399.8L487.8,399.8z"
					/>
					<path
						className={Styles.country}
						d="M665.9,203.6l3.4,0.9l4.7-0.2l-3.3,2.6l-2,0.4l-5.5-2.7l-0.6-2.1l2.5-1.9L665.9,203.6z"
					/>
					<path
						className={Styles.country}
						d="M680.3,187.6l-2.4,0.1l-5.8-1.9l-3.5-3l1.9-0.5l5.9,1.6l4.2,2.6L680.3,187.6z"
					/>
					<path
						className={Styles.country}
						d="M372.4,191.3l-3.1,0.9l-6.3-2.8v-2.2l-2.9-2.2l0.3-1.8l-4.3-1.1l0.6-3.4l1.5-1.4l4.1,1.3l2.4,1l4.1,0.6
		l0.2,2.2l0.4,2.9l3.2,2.6L372.4,191.3z"
					/>
					<path
						className={Styles.country}
						d="M711.5,177.8l-5,5.4l3.9-2.1l2.9,1.4l-2.4,2.1l3.8,1.7l2.8-1.5l4.3,1.9l-2.8,4.6l3.8-1.1l-0.3,3.3l0.5,3.9
		l-3.6,5.6l-2.4,0.2l-2.9-1.2l2.5-5.1l-1.2-0.8l-7.3,5.4l-3-0.2l4.4-3l-4.4-1.5l-5.5,0.4L690,197l-0.2-1.8l3.8-2.3l-1.7-1.6l5.4-3.8
		l8.2-9.9l4.2-3.5l5-2.1l2.1,0.2l-1.5,1.7L711.5,177.8z"
					/>
					<path
						className={Styles.country}
						d="M351.5,156.4l1.5,0.8l5-0.5l-7.2,6.9l0.3,5h-1.9l-0.7-2.8l0.5-2.9l-0.8-1.9l1.3-2.7L351.5,156.4z"
					/>
					<path
						className={Styles.country}
						d="M634.9,108.9l-3.6,3l-1.7-0.5l-0.1-1.7l0.4-0.4l2.8-1.7l1.7,0.1L634.9,108.9z"
					/>
					<path
						className={Styles.country}
						d="M625.2,105.7l-7.2,3.2l-3-0.2v-1.5l5-2.7l6,0.1L625.2,105.7z"
					/>
					<path
						className={Styles.country}
						d="M622.1,88.9l-0.9,2.5l2.7-0.9l1.5,1.5l3.5,2l3.8,1.7l-1.4,2.7l3.5-0.4l1.9,1.9l-5,1.8l-5.9-1.4l-0.8-2.6
		l-6.3,3.1l-8.2,2.9l0.7-3.3l-6.3,0.5l5.7-2.8l3.6-4.5l5.1-5.1L622.1,88.9z"
					/>
					<path
						className={Styles.country}
						d="M667,80.6l-4.9,0.3l0.7-2.7l3.8-3.1l4.3-0.7l2.3,1.5l-1.5,2.3l-0.9,0.8L667,80.6z"
					/>
					<path
						className={Styles.country}
						d="M592.5,69.9l-4.1,1.9l-4.2-1.6l-3.9,0.5l-3.4-2.4l5-1.7l4.9-2.3l3,1.5l1.6,1l0.4,1L592.5,69.9z"
					/>
					<path
						className={Styles.country}
						d="M645.5,212.5l-2.2-3.6l2.9-8.5l-1.6-1.8l-3.7,1l-1.1-1.6l-5.5,4.7l-3.2,4.9l-2.8,2.9l-2.5,1l-1.7,0.3l-1.1,1.5
		h-9.3l-7.8,0.1l-2.7,1.1l-6.8,4.4v-0.1l-0.9-0.4l-2,0.9l-1.9,1.3l-1.8-1.1l-4.7,0.8l-3.9,0.9l-1.9,0.8l-2.3,2.1l1.8,0.7l1.7-0.4
		h0.3l-0.3,1.9l-4.8,0.7l-2.8,0.8l-1.7,1l-2.6-0.6l-1.6,0.3l-2.9,1.8l-4.6,2l-2.7-0.4l2-2.2l3.7-3.5l4.1-2.1l1.1-1.8l0.9-3l3.8-3.5
		l0.9-4l1.1,3.9l3.8,0.9l2.4-2.1l-1.4-4.8l-0.9-2l-4-1.2l-3.8-0.7h-3.9l-3.4-0.8l-0.4-1.4l-1.4,0.9l-1.2-0.2l1.9-2.1l-1.8-0.8
		l1.9-2.4l-1.2-1.8l1.7-1.8l-5.2-0.9l-0.1-3.6l-0.8-0.8l-3.3-0.2l-4.1-1.2l-1.5,0.8l-1.8,1.5l-3.3,1l-3.1,2.5l-5.4-1.7l-4.4,0.8
		l-3.9-1.9l-4.6-1l-3.3-0.4l-1-1l0.9-3.4h-1.7l-1.3,2.4h-10.2h-17h-16.8h-14.9h-14.8h-14.7h-15.1h-4.8h-14.7h-14.1H377l-5.4-6.1
		l-1.6-2.7l-7-2.6l1.3-5.5l3.6-3.7l-4.1-2.7l3.1-4.9l-2.1-4.4l2.5-3.2l5.1-2.9l3.2-3.8L371,142l1.4-6.9l1.1-4.2l-1.6-2.7l-0.8-2.4
		l0.6-3.1l-6.5,1.9l-7.6,3.3l-0.3-3.8l-0.5-2.6l-2.8-1.6l-4.2-0.2L385.4,87L410,66.6l6,1.3l3.3,2.6L423,71l6.3-2.2l7-1.7l5.3,0.6
		l8.9-2.3l8.2-1.3l0.2,2.2l4.5-1.3l3.9-2.5l2.1,0.6l1.4,4.8l9.5-3.7l-3.9,4.1l6-0.9l3.2-1.5l4.6,0.3l3.9,2.2l7.5,2l4.7,0.9l4.4-0.3
		l2.9,2.8l-8.5,2.7l6.4,1.1l11.9-0.6l4.4-1l1.4,3.3l7.1-2.7l-2.1-2.4l4.5-1.8l5.2-0.3l3.9-0.5l2.1,1.3l1.5,2.9l5-0.4l5.3,2.5
		l7.2-0.9l6,0.1l2.4-3.4l4.5-0.9l4.9,1.8l-4.3,5.2l6.2-4.4l3.2,0.2l6.4-5.5l-1.6-3.3l-2.9-2.2l5.5-5.9l8.2-3.8l4.5,0.9l2,2.3l0.4,6
		l-5.8,2.6l6.7,1.1l-4.4,5.5l8.9-4.2l2.2,3.5l-4.3,4l1.3,3.7l7.3-3.9l6.5-4.8l4.7-5.9l5.5,0.4l5.4,0.8l3.6,2.7l-1.7,2.7l-5.1,2.9
		l0.9,2.9l-2.4,2.7l-10.9,3.9l-6.5,0.9l-3.2-1.7l-3.3,2.8l-7.4,4.7l-3,2.5l-7.7,3.8l-6.5,0.4l-5.1,2.4l-2.9,3.8l-5.7,0.7l-8.7,4.7
		l-9.4,6.5l-5,4.6l-4.9,6.9l6,1l-1.5,5.5l-0.8,4.6l7.3-1.2l7,2.6l3.3,2.3l1.7,2.8l4.9,1.7l3.6,2.5l7.6,0.4l4.8,0.6l-3.6,5.2
		l-1.7,6.1l0.1,6.9l4.4,5.9l4.7-2l5.6-6.4l2.3-9.6l-1.7-3.2l9-2.9l7.5-4.2l4.8-4.2l1.7-4L630,133l-3.2-4.5l8.9-6.2l1-5.3l3.9-9
		l3.8-1.4l6.7,1.6l4.2,0.6l4.5-1.6l3.1,2l3.6,3.4l0.2,2.2l7.7,0.5l-2.6,4.9l-2.3,7.4l3.8,1l1.6,3.5l8.2-3.3l7.5-6.6l4.2-2.7l1.1,5.3
		l2.6,7.5l2,7.2l-3.4,3.8l4.8,3.4l2.9,3.4l6.9,1.6l2.4,1.9v5.2l3.4,0.8l1.1,2.3l-2,6.9l-4.3,2.3l-4.2,2.2l-8.8,2.2l-7.9,5l-8.6,1.1
		l-10.1-1.4h-7.3l-5.3,0.4l-5.7,4.5l-7.4,2.8l-10.1,8.2l-7.9,5.8l4.7-1l10.9-8.3l12.3-5.2l7.6-0.6l3.3,3.1l-6.1,4.2l-0.6,6.7
		l0.1,4.8l5.6,3.1l8.6-0.9l7.2-7.1l-1,4.6l2.5,2.3l-7.4,4.1l-12.4,3.8l-5.8,2.5l-7.2,4.6l-3.7-0.5l1.5-5.3l10.4-5.3l-8.1,0.2
		L645.5,212.5z"
					/>
					<path
						className={Styles.country}
						d="M539,48.7l-4.7,2.4l10.5-1.5l2.6,2.6l7.3-2.7l1.8,1.7l-2.2,5.1l4.3-2.1l2.8-5.3l4.3-0.8l3,0.8l2.2,2.1
		l-2.7,5.1l-2.4,3.7l4.2,2.6l5,2.6l-2.6,2.3l-7.1,0.5l0.8,2l-3.2,2l-6.7-0.8l-5.7-1.5l-5.1,0.3l-9.5,1.9l-11.3,0.8l-7.9,0.5l0.3-2.6
		l-4.1-1.4l-4.3,0.6l-0.6-4.3l3.3-0.6l7.3-0.9l5.5,0.3l6.2-1l-6.5-1.2l-9.1,0.4l-5.7-0.1v-1.9l11.7-2.2l-6.3,0.1l-5.4-1.4l7.8-3.9
		l5.2-2.1l14.2-3.1L539,48.7z"
					/>
					<path
						className={Styles.country}
						d="M578.5,47.2l-7,3.4l-2.3-3.6l2.1-0.8l5.5-0.2L578.5,47.2z"
					/>
					<path
						className={Styles.country}
						d="M687.1,48.8l-0.7,1.4l-4.1-0.1l-4.1-0.1l-4.9,0.7l-0.9-0.4l-2.1-2.7l1.7-1.8l2.2-0.4l8.4,0.6L687.1,48.8z"
					/>
					<path
						className={Styles.country}
						d="M647.3,48.5l0.3,3.2l7.2-4.1l11.7-2.1l2.3,5.3l-3.2,3.4l9-1.5l5.3-2.1l6.8,2.6l3.7,2.5l-1.1,2.3l8.2-1.2
		l1.9,3.4l8.4,2.1l2.2,2.1l0.9,5.1l-9.1,2.5l7.7,3.6l5.9,1.2l3.3,5l6.5,0.4l-3.3,3.9L711,92.6l-4-2.4l-3.8-5.4l-5.9,0.7l-2.3,3.3
		l2.7,3.2l4.5,2.6l1,1.5l-0.1,5.7l-3.6,4.1l-4.7-1.6l-8.6-4.5l3.6,4.9l2.8,3.5l-0.3,2l-10.9-2.3l-7.6-3.4l-3.8-2.7l2.4-1.7l-4.8-2.9
		l-4.7-2.7l-0.9,1.6L648.9,97l-2.4-1.9l5.4-4.2l8.1-0.1l9.3-0.7l-0.2-2l3.1-2.8l8.8-5.4l0.4-2.5l-0.5-1.8l-4.7-2.7l-7.1-1.8l3.5-1.4
		l-2-3.4l-3.4-0.3l-1.9-1.8l-3.3,1.6l-7.9,0.7l-14-1.2l-7.4-1.6l-5.9-0.8l-1.9-1.9l6.3-2.4h-5.7l3.3-5.3l7.1-4.6l6-2.1l11.2-1.4
		L647.3,48.5z"
					/>
					<path
						className={Styles.country}
						d="M596.9,45l3.6,1.1l7.6-0.7l-0.5,1.5l-6.1,2.5l3.8,2.2l-5.2,4.7l-8.4,2l-3.4-0.4l-0.9-2l-5.9-4l1.7-1.7l7.4,0.6
		l-0.9-3.3L596.9,45z"
					/>
					<path
						className={Styles.country}
						d="M619.5,50.5l-7.8,3.9l-4.4-0.2l1.8-4.6l2.6-2.5l4.2-2.2l5.2-1.4l7.9,0.2l6.2,1.2l-10.1,4.6L619.5,50.5z"
					/>
					<path
						className={Styles.country}
						d="M503.7,57.7l-13.5,2.6l0.6-2.3l-6-2.8l4.4-2.2l7.5-3.8l7.6-3.4l0.4-3.1l14-0.8l4.2,1.1l9.5,0.3l1.9,1.4
		l1.6,2.2l-6.5,1.3l-13.9,3.6l-9.2,3.7L503.7,57.7z"
					/>
					<path
						className={Styles.country}
						d="M628.9,39.3l-4.1,1.9l-5.2-0.4l-3.2-1.3l4.5-2.2l6.9-1.4l1.4,1.8L628.9,39.3z"
					/>
					<path
						className={Styles.country}
						d="M620.1,30.6l0.2,2.3l-2.8,2.5l-5.8,3.8l-6.9,0.5l-3.2-0.8L605,36l-6.6,0.3l4.3-3.8l3.8,0.2l7.7-1.7l4.9,0.3
		L620.1,30.6z"
					/>
					<path
						className={Styles.country}
						d="M580.7,33.2l-0.7,1.7l4.4-0.8l3.6,0.2l-2.3,2.5l-5.1,2.3l-13.9,0.8l-12.3,2.2l-6,0.1l1.5-1.6l10.7-2.3
		l-17.9,0.6l-4.2-0.9l11.7-4.8l5.4-1.4l8.3,1.7l2.9,2.9l6.1,0.4l0.7-4.7l5.8-1.8l3.1,0.5L580.7,33.2z"
					/>
					<path
						className={Styles.country}
						d="M636.4,28.8l2.3,1.6h7.4l1.4,1.6l-2.8,1.8l3.1,1.1l1.2,1.2l4.9,0.2l5.1,0.4l7.1-1l8.1-0.5l5.8,0.4l2.3,1.9
		l-1,2l-3.6,1.4L671,42l-4.3-0.7l-11.8,0.8l-8,0.1l-5.6-0.6l-8.6-1.6l1.6-2.8l2.1-2.4l-1.4-2.2l-7-0.6l-2.6-1.5l3.8-2L636.4,28.8z"
					/>
					<path
						className={Styles.country}
						d="M560.7,26.2l-5.8,3.7l-5.3,1.7l-3.8,0.2l-9.9,2.1l-7.1,0.8l-3.6-1.1l11.7-3.7l12.2-3.1l5.5,0.1L560.7,26.2z"
					/>
					<path
						className={Styles.country}
						d="M641.9,26.8l-1.8,0.1l-6.6-0.3l0.7-1.3l7.2,0.1l1.6,0.8L641.9,26.8z"
					/>
					<path
						className={Styles.country}
						d="M583.1,25.9l-8.8,1.4l-3.3-1.5l5.1-1.5l6-0.5l4.1,0.7L583.1,25.9z"
					/>
					<path
						className={Styles.country}
						d="M590.9,21.7l-5.7,0.9h-6l1-0.7l5.7-1.3l1.7,0.2L590.9,21.7z"
					/>
					<path
						className={Styles.country}
						d="M637.7,24.2l-6.6,1l-1.6-1.1l0.6-1.7l2.2-1.9l4.4,0.2l1.7,0.3l2.3,1.6L637.7,24.2z"
					/>
					<path
						className={Styles.country}
						d="M623.9,23l-1.1,1.9l-5.3-0.5l-4-1.5l-7.8-0.2l5.3-1.3l-2.8-1.1l2.2-1.8l6.1,0.6l7.5,1.7L623.9,23z"
					/>
					<path
						className={Styles.country}
						d="M678,16.9l2.7,1.5l-6.5,1.3l-10.6,3.5l-6.8,0.3l-6.8-0.6l-1.6-1.9l2.1-1.6l4.3-1.2h-6.6l-2-1.5l0.4-1.9
		l4.9-1.9l4.1-1.3l4-0.3l-0.3-1l8.3-0.2l1.6,2.2l4.7,0.9l4.8,0.9L678,16.9z"
					/>
					<path
						className={Styles.country}
						d="M757.2,2.9l8.7,0.3l6.7,0.5l5.2,1l-1.1,1l-9.8,1.7l-9,0.8l-4,0.9h7.5l-10.5,2.5l-6.8,1.2l-9.3,3.5l-8.1,0.7
		l-3.1,1l-11.3,0.4l4.4,0.6l-3.3,0.8l0.7,2.3l-5,1.6l-6.9,1.3l-3.5,1.8l-6.6,1.5l-0.5,1.1l6.5-0.2l-1.1,1.2l-12.7,2.9l-8.5-1.4
		l-11.7,0.8l-5-0.6l-6.8-0.3l2.1-2.3l8-1.1l2-3.4l2.6-0.3l7.5,2l-1.7-3l-4.9-0.9l5-1.8l7.6-1.1l2.8-1.6l-3-1.7l1.1-2.2l9.4,0.1
		L693,15l7.3-1.6l-7.3-0.5l-12.6,0.3l-4.4-1.4l-0.8-1.7l-2.5-1.2l1.1-1.3l6-0.8l4.2-0.1l7.4-0.6l6.8-1.5l3.9,0.2l2.4,1.1l5-2
		l5.2-0.6l6.5-0.4l10.4-0.2l1.3,0.4l10.3-0.6l7,0.2L757.2,2.9z"
					/>
					<path
						id="CR"
						className={Styles.country}
						d="M514.6,431.6l1.2,3.5l2,2.6l2.5,2.7l-2.2,0.6l-0.1,2.6l1.1,0.9l-0.9,0.8l0.2,1.1l-0.6,1.3l-0.3,1.3
		l-3-1.4l-1.1-1.4l0.7-1.1l-0.1-1.4l-1.5-1.5l-2.2-1.3l-1.8-0.8l-0.3-1.9l-1.4-1.1l0.2,1.8l-1.2,1.6l-1.2-1.8l-1.7-0.7l-0.7-1.2
		l0.1-2l0.9-2l-1.5-0.9l1.4-1.3l0.9-0.8l3.6,1.7l1.3-0.8l1.8,0.5l0.8,1.3l1.7,0.5L514.6,431.6L514.6,431.6z"
					/>
					<path
						id="CU"
						className={Styles.country}
						d="M544.8,355.7l1.9,2.3l5.2-0.7l1.8,1.5l4.2,4l3.2,2.9l1.8-0.1l3.2,1.3l-0.6,1.8l4,0.3l3.9,2.6l-0.8,1.5
		l-3.8,0.8l-3.8,0.3l-3.7-0.5l-8.1,0.6l4.2-3.5l-2.1-1.7l-3.6-0.4l-1.7-1.9l-0.8-3.6l-3.2,0.2l-5-1.7l-1.5-1.4l-7.1-1l-1.8-1.2
		l2.3-1.6l-5.4-0.3l-4.4,3.3l-2.3,0.1l-1,1.6l-2.8,0.7l-2.3-0.7l3.2-1.9l1.5-2.4l2.7-1.4l3-1.2l4.3-0.6l1.4-0.8l4.7,0.5l4.4,0.1
		L544.8,355.7L544.8,355.7z"
					/>
					<path
						id="DO"
						className={Styles.country}
						d="M585.7,386l0.3-1.8l-1.3-1.9l1.5-1.1l0.7-2.5l-0.1-3.4l0.8-1.1h4.3l3.2,1.6l1.5-0.1l0.7,2.3l3.1-0.2
		l-0.4,1.9l2.5,0.3l2.5,2.3l-2.3,2.6l-2.6-1.4l-2.6,0.3l-1.8-0.3l-1.1,1.2l-2.2,0.4l-0.7-1.6l-1.9,0.9l-2.7,4.4l-1.3-1L585.7,386
		L585.7,386z"
					/>
					<path
						id="GT"
						className={Styles.country}
						d="M488.1,387.5l-0.7,5.1l-1.3,7.2h1.7l1.7,1.2l0.6-1l1.5,0.8l-2.8,2.5l-2.9,1.8l-0.5,1.2l0.3,1.3
		l-1.3,1.6l-1.4,0.4l0.3,0.8l-1.2,0.7l-2,1.6l-0.3,0.9l-2.8-1.1l-3.5-0.1l-2.4-1.3l-2.8-2.6l0.4-1.9l0.8-1.5l-0.7-1.2l3.3-5.2h7.2
		l0.4-2.2l-0.8-0.4l-0.5-1.4l-1.9-1.5l-1.8-2.1l2.5-0.1l0.5-3.6h5.2L488.1,387.5L488.1,387.5z"
					/>
					<path
						id="HN"
						className={Styles.country}
						d="M519.6,405.5l-1.9-0.1l-0.9,0.9l-2,0.8h-1.4l-1.3,0.8l-1.1-0.2l-0.9-1l-0.6,0.2l-0.9,1.5l-0.5-0.1
		l-0.2,1.4l-2.1,1.7l-1.2,0.8L504,413l-1.5-1.3l-1.4,1.7h-1.2l-1.3,0.1l-0.2,3.2h-0.8l-0.8,1.5l-1.8,0.3l-0.8-2l-1.7-0.6l0.7-2.6
		l-0.7-0.7l-1.2-0.4l-2.5,0.7l-0.1-0.8l-1.6-1.1l-1.1-1.2l-1.6-0.6l1.3-1.6l-0.3-1.3l0.5-1.2l2.9-1.8l2.8-2.5l0.6,0.3l1.3-1.1
		l1.6-0.1l0.5,0.5l0.9-0.3l2.6,0.6l2.6-0.2l1.8-0.7l0.8-0.7l1.7,0.3l1.3,0.4l1.5-0.1l1.2-0.6l2.5,0.9l0.8,0.2l1.6,1.2l1.5,1.4l1.9,1
		L519.6,405.5L519.6,405.5z"
					/>
					<path
						id="HT"
						className={Styles.country}
						d="M586.8,375.3l0.1,3.4l-0.7,2.5l-1.5,1.1l1.3,1.9l-0.3,1.8l-3.6-1.1l-2.7,0.4l-3.4-0.4l-2.7,1.2l-2.8-2
		l0.7-2.1l5.1,0.9l4.1,0.5l2.2-1.4l-2.3-2.8l0.4-2.5l-3.5-1l1.5-1.7l3.4,0.2L586.8,375.3z"
					/>
					<path
						id="JM"
						className={Styles.country}
						d="M556.5,387.1l-1.8,1.1l-3-1.1l-2.9-2.3l0.8-1.5l2.4-0.4l1.3,0.2l3.7,0.6l2.7,1.5l0.8,1.8L556.5,387.1
		L556.5,387.1z"
					/>
					<path
						id="MX"
						className={Styles.country}
						d="M449.3,335.9l2.2-0.2l-3.2,5.7l-1.8,4.6l-1.8,8.6l-1.1,3.1l0.4,3.5l1.3,3.2l0.4,4.9l3,4.8l0.8,3.7
		l1.7,3.1l5.7,1.7l1.9,2.7l5.2-1.8l4.3-0.6l4.4-1.2l3.6-1.1l3.9-2.6l1.8-3.7l1.2-5.4l1.2-1.9l4-1.7l6.1-1.5l4.9,0.3l3.4-0.6l1.2,1.4
		l-0.6,3.1l-3.5,3.8l-1.8,3.9l0.9,1.1l-1.2,2.8l-2.1,5l-1.2-1.7l-1.1,0.1l-1.1,0.1l-2.5,3.9l-0.9-0.8l-0.7,0.3l-0.1,1l-5.2-0.1h-5.2
		l-0.5,3.6l-2.5,0.1l1.8,2.1l1.9,1.5l0.5,1.4l0.8,0.4l-0.4,2.2h-7.2l-3.3,5.2l0.7,1.2l-0.8,1.5l-0.4,1.9l-5.6-6.9l-2.6-2.1l-4.4-1.7
		l-3.2,0.5l-4.8,2.4l-2.9,0.6l-3.7-1.7l-4.1-1.2l-4.8-2.9l-4.1-0.9l-5.9-3l-4.3-3.1l-1.1-1.7l-3.1-0.4l-5.4-2l-1.9-2.9l-5.4-3.7
		l-2.2-4l-0.8-3.2l1.9-0.6l-0.3-1.8l1.6-1.7l0.4-2.2l-1.5-2.9v-2.5l-1.3-3.3l-3.8-6.4l-4.6-5l-1.9-4l-4.1-2.6l-0.7-1.6l1.7-3.9
		l-2.4-1.5l-2.5-3.2l-0.2-4.4l-2.8-0.6l-2.3-3.3l-1.7-3.2l0.3-2l-1.5-4.8l-0.3-4.9l0.8-2.5l-3.1-2.6l-1.9,0.3l-2.4-1.7l-1.8,2.6
		l-0.1,3l-1,4.9l1,2.6l2.8,4.4l0.4,1.6l0.7,0.4l0.1,2.2l1-0.1v4.2l1.3,1.6l0.5,2.3l2.7,3.2l0.4,6l1,2.8l0.9,3l-0.3,3.4l2.6,0.2
		l1.6,2.9l1.5,2.9l-0.3,1.2l-2.8,2.3h-1l-0.7-3.9l-2.9-3.7l-3.4-3.1l-2.5-1.6l1.2-4.7l-0.1-3.5l-2.1-2L363,330l-0.9,0.8l-1-1.7
		l-3-1.5l-2.2-3.8l0.5-0.4l2.1,0.3l2.7-2.4l1-2.9l-2.9-4.6l-2.6-1.7l-0.8-4l-0.6-4.3l-0.8-5.1l-0.2-5.8l6.3-0.5l7.1-0.7l-0.9,1.3
		l7,3.1l10.9,4.5h10.8h4.3l0.8-2.7h9.4l1.3,2.3l2.1,2.1l2.4,2.8l0.8,3.3l0.4,3.6l2.3,1.9l4,1.9l4.8-5l4.5-0.2l3.2,2.6l1.6,4.4
		l0.9,3.8l2.4,3.6l0.2,4.5l0.9,3l3.9,2L449.3,335.9L449.3,335.9z"
					/>
					<path
						id="NI"
						className={Styles.country}
						d="M519.6,405.5l-0.5,0.7l-0.5,1.4l0.4,2.3l-1.5,2.2l-0.8,2.6l-0.5,2.8l0.2,1.7l-0.1,2.9l-0.9,0.6
		l-0.7,2.8l0.2,1.7l-1.2,1.6l0.1,1.7l0.8,1.1l-1.4,1.4l-1.7-0.5l-0.8-1.3l-1.8-0.5l-1.3,0.8l-3.6-1.7l-0.9,0.8l-1.8-2l-2.5-2.6
		l-1.1-2.1l-2.2-2.1l-2.5-2.9l0.7-1l0.8,1l0.5-0.4l1.8-0.3l0.8-1.5h0.8l0.2-3.2l1.3-0.1h1.2l1.4-1.7l1.5,1.3l0.6-0.8l1.2-0.8
		l2.1-1.7l0.2-1.4l0.5,0.1l0.9-1.5l0.6-0.2l0.9,1l1.1,0.2l1.3-0.8h1.4l2-0.8l0.9-0.9L519.6,405.5L519.6,405.5z"
					/>
					<path
						id="PA"
						className={Styles.country}
						d="M549.3,446.2l-0.7,0.9l1.1,3.8l-1.1,1.9l-1.8-0.5l-0.9,3.1l-1.8-1.8l-1-3.5l1.4-1.7l-1.4-0.4l-0.9-2.1
		l-2.8-1.8l-2.4,0.4l-1.3,2.2l-2.4,1.6l-1.2,0.2l-0.6,1.4l2.5,3.5l-1.6,0.8l-0.8,0.9l-2.7,0.4l-0.8-3.9l-0.8,1.1l-1.8-0.4l-1-2.5
		l-2.3-0.5l-1.5-0.7h-2.4l-0.2,1.4l-0.6-1l0.3-1.3l0.6-1.3l-0.2-1.1l0.9-0.8l-1.1-0.9l0.1-2.6l2.2-0.6l1.9,2.3l-0.2,1.4l2.2,0.3
		l0.6-0.6l1.5,1.6l2.8-0.5l2.5-1.6l3.5-1.3l2-1.9l3.1,0.4l-0.2,0.6l3.1,0.2l2.4,1.2l1.8,1.9L549.3,446.2L549.3,446.2z"
					/>
					<path
						id="SV"
						className={Styles.country}
						d="M492.5,415.9l-0.7,1.5l-3.3-0.1l-2-0.6l-2.2-1.3l-3-0.4l-1.5-1.4l0.3-0.9l2-1.6l1.2-0.7l-0.3-0.8
		l1.4-0.4l1.6,0.6l1.1,1.2l1.6,1.1l0.1,0.8l2.5-0.7l1.2,0.4l0.7,0.7L492.5,415.9L492.5,415.9z"
					/>
					<path
						className={Styles.country}
						d="M539.5,194.5l-6.1,2l-4.7,2.5l-4.6,2.7l-0.5,0.9l5.7-1.3l2.1,2.1l4.6-1.5l4.9-2.1l5.4-2.1l-3.1,3.3l2.5,0.8
		l2.5,2.4l5.1-1.4l5.1-0.5l0.3,1.8l1.5,0.2l1.2,0.2l1.5,2.5l-4.7,0.6h-0.1l-3.7-0.7l-4.5,1.2l-3.7,0.6l-4.7,4.1l-3,2.3l0.4,0.7
		l5.5-4.1h0.7l-4.7,4.9l-2.9,4.4l-2.5,3.6l-0.6,3.1l-0.8,1.5l-0.6,1.7l0.1,3.3l0.3,0.5l1.8-0.1l1.6-0.7l1.4-0.8l3.3-3.1l1.8-4.2
		l-0.1-3.9l1.4-2.7l2.6-3.1l2.1-2.2l2.7-1.5l-0.4,2.1l2.2-3.1l1.3-0.6l1.7-2.4l3.8,1.3l2.8,2.4l-0.8,2.9l-1.6,2.9l-3.8,2.5l-0.4,1.6
		h1l4.3-2.7l1.6,0.6l-0.5,3.7l-0.7,2.6l-3.7,3.5l-2,2.2l-2.7,2.4l2.7,1.3l2.5,0.4l4-0.9l3.7-1.7l3-0.9l4.6-1.8l5.8-3.8l0.1-0.6
		l0.3-1.9l2.7-0.8l3.9,0.3l4,0.5l4.6-2.1l0.6-2.5l-0.2-0.9l6.8-4.4l2.7-1.1l7.8-0.1h9.3l1.1-1.5l1.7-0.3l2.5-1l2.8-2.9l3.2-4.9
		l5.5-4.7l1.1,1.6l3.7-1l1.6,1.8l-2.9,8.5l2.2,3.6l0.2,2.1l-6.4,3l-6,2.2l-6,1.9l-4,3.8l-1.3,1.4l-1.2,3.4l0.7,3.3l2.1,0.2l0.2-2.3
		l1.1,1.4l-1,1.8l-3.8,1l-2.5-0.1l-4.2,1.1l-2.3,0.3l-3.1,0.3l-5,1.9l8.1-1.2l1.1,1.2l-7.9,1.9H601l0.4-0.8l-2.1,1.8l1.4,0.3
		l-2.5,4.6l-5.3,4.9l0.1-1.7l-1.1-0.3l-1.2-1.6v3.5l1,1.1l-0.6,2.4l-2.4,2.5l-4.5,5.1l-0.4-0.2l2.9-4.4l-2-2.4l1-5.4l-1.9,2.8v4.1
		l-3.2-1l3,2l-1.5,6.1l1.4,0.5v2.2l-1,6.4l-4.6,4.7l-6.1,1.9l-4.4,3.8l-2.8,0.4l-3.4,2.4l-1.3,2.1l-6.9,4.2l-3.8,3.1l-3.5,3.8
		l-1.9,4.5v4.5l0.6,5.5l1.5,4.5l-0.5,2.8l1.3,7.4l-1,4.4l-0.6,2.5l-2,3.9l-1.8,0.8l-2.6-0.8l-0.4-2.8l-1.8-1.5l-2-5.5l-1.6-4.9
		l-0.4-2.5l2-4.3l-0.8-3.5l-3.1-5.4l-1.9-1l-6.1,3l-0.9-0.4l-2-3l-3-1.6l-6.4,0.9l-4.6-0.8l-4.3,0.5l-2.5,1l0.6,1.7l-0.7,2.6
		l0.8,1.3l-1.2,0.8l-1.8-0.9l-2.3,1.2l-3.9-0.2l-3.3-3.4l-4.9,0.8l-3.6-1.5l-3.5,0.5l-5,1.5l-6.1,4.7l-6.1,2.8l-3.7,3l-1.9,2.9
		l-1,4.5l-0.4,3l0.6,2.2l-2.2,0.2l-3.6-1.4l-3.9-2l-0.9-3l-0.2-4.5l-2.4-3.6l-0.9-3.8l-1.6-4.4l-3.2-2.6l-4.5,0.2l-4.8,5l-4-1.9
		L417,312l-0.4-3.6l-0.8-3.3l-2.4-2.8l-2.1-2.1l-1.3-2.3h-9.4l-0.8,2.7h-4.3h-10.8l-10.9-4.5l-7-3.1l0.9-1.3l-7.1,0.7l-6.3,0.5
		l0.3-3.2l-2.1-3.7l-2.2-0.8l0.1-1.8l-2.9-0.4l-1.2-1.7l-4.8-0.6l-0.9-1.1l0.8-3.5l-2.5-6.4l-0.5-8.9l0.9-1.5l-1.3-2.1l-1.5-5.4
		l1.8-5.2l-0.9-3.5l3.9-5.3l2.8-5.4l1.1-4.9l5.5-6l4-5.7l4-5.7l4.3-8.5l1.8-5.3l0.4-2.9l1.4-1.3l5.8,2.2l-1,5.9l2.2-1.7l2.5-5.1
		l1.6-5.1h14.1h14.7h4.8h15.1h14.7h14.8h14.9h16.8h17h10.2l1.3-2.4h1.7l-0.9,3.4l1,1l3.3,0.4l4.6,1l3.9,1.9l4.4-0.8L539.5,194.5z"
					/>
					<path
						className={Styles.country}
						d="M275,138.6l-7,2.3l-0.8-1.6l2.3-2.8l6.4-2.1l3.5-0.9l2.6,0.4v1.9L275,138.6z"
					/>
					<path
						className={Styles.country}
						d="M236,122l-3.9,0.9l-1.7-1.1l-0.8-1.6l5.7-1l3,0.6L236,122z"
					/>
					<path
						className={Styles.country}
						d="M237.2,99.6l1.2,1l3.5-0.5l1.6,1.5l3.3,0.7l-1.2,0.7l-4.9,1.2l-1.7-1.3l-0.3-1l-4.3,0.3l-0.3-0.5L237.2,99.6z"
					/>
					<path
						className={Styles.country}
						d="M410,66.6L385.4,87l-35.6,32.7l4.2,0.2l2.8,1.6l0.5,2.6l0.3,3.8l7.6-3.3l6.5-1.9l-0.6,3.1l0.8,2.4l1.6,2.7
		l-1.1,4.2L371,142l4.6,3.8l-3.2,3.8l-5.1,2.9l-0.6-2.2l-2.5-2l3.3-5.2l-1.6-4.9l2.7-5.6l-4.1-0.4l-7.1-0.1l-3.8-1.8l-3.3-6.1
		l-3.3-1.1l-5.7-2.1l-6.8,0.5l-6-2.7l-2.7-2.5l-6.3,1.2l-3.5,4.1l-2.9,0.4l-6.6,1.2l-6.2,2l-6.4,1.3l3.2-3.5l8.4-5.8l6.8-1.8
		l0.4-1.4l-9.4,3.2l-7.4,3.9l-11.2,4.2l0.2,2.9l-9,4.2l-7.7,2.5l-6.6,1.9l-4,2.6l-10.6,3.1l-4.5,2.8l-8.2,2.6l-2.7-0.4l-6.2,1.6
		l-7,2.1l-6.1,2l-10,1.8l0.4-1.1l8.2-2.8l6.6-1.9l8.6-3.3l6.5-0.6l5-2.5l10.4-3.6l2.3-1.2l5.7-2.1l5.8-4.5l6.2-3.5l-7.3,1.8
		l-0.3-1.1l-4.9,2.2l0.4-3l-3.6,2.1l1.6-2.9l-7.3,2.3h-2.8l3.7-3.5l3.3-2.2l-0.4-2.1l-7.2,1.2l-0.6-2.8l-1.3-1.4l4-3.3l-0.4-2.5
		l5.9-3.3l7.7-3.3l5.3-2.9l4.1-0.4l1.8,0.9l7.1-2.8l2.5,0.5l5.6-1.8l2.5-2.6l-1.1-1l6-2.2l-2.8,0.1l-6.2,1.2l-2.9,1.3l-1.8-1.3
		l-6.9,0.7l-4.6-1.4l1.2-2.3l-1-3.2l9.2-2.4l13.2-2.7h3.5l-4.3,2.8l9.2-0.2l1.2-3.5l-2.3-2.1l0.8-2.8l-0.7-2.3l-3.3-1.7l6.5-2.9
		l7.5-0.2l8.8-2.4l4.8-2.6l7.9-2.6l4.8-0.6l11.2-2.4l3.1,0.4l10.4-2.8l4.4,1.1l-0.5,2.4l3.3-1l6.3,0.3l-1.9,1.2l4.9,0.9l4.9-0.5
		l6.2,1.6l7.2,0.6l2.2,0.6l6.6-0.8l4.1,1.6L410,66.6z"
					/>
					<path
						id="AI"
						className={Styles.country}
						d="M634.2,384.9H634l0.3-0.4h0.3l-0.2,0.3L634.2,384.9L634.2,384.9z"
					/>
					<path
						className={Styles.country}
						d="M640.7,391.9l0.6,0.3l-0.2,0.3h-0.2l-0.7,0.1l-0.2-0.1V392l0.3-0.1l0.1-0.3h0.2L640.7,391.9z"
					/>
					<path
						className={Styles.country}
						d="M640.7,388.2h0.2l0.2,0.2l0.1,0.4l-0.1,0.3l-0.2,0.1l-0.1-0.2l-0.3-0.2v-0.5L640.7,388.2z"
					/>
					<path
						className={Styles.country}
						d="M580.4,366l-0.4,0.5l-0.2,0.5l-0.6,0.3h-0.5l-0.1-0.1l-0.4,0.2l-0.5,0.1l-0.6-0.2l-0.4,0.1l-0.1-0.5l0.3-0.2
		l0.2-0.3l0.4-0.2l0.3-0.4h0.4l0.3-0.2l0.2,0.3l0.5,0.2l0.5-0.2l0.7-1h0.3v0.2L580.4,366z"
					/>
					<path
						className={Styles.country}
						d="M580.6,364.3l-0.2-0.6l0.6-0.3l0.3,0.3v0.2L581,364L580.6,364.3z"
					/>
					<path
						className={Styles.country}
						d="M581,357.8l0.2-0.1l0.5,0.3h0.4l0.4,0.1l0.3,0.2v0.3l-0.2,0.1l-0.5-0.4h-0.4l-0.1-0.1l-0.5,0.2l-0.5-0.2v-0.1
		l0.2-0.5L581,357.8z"
					/>
					<path
						className={Styles.country}
						d="M577.1,356l-0.2,0.4v0.7l-0.1,0.4l-0.4,0.3l-0.2,0.4l-0.4,0.3l-0.7,0.3L575,359l-0.2-0.2l0.1-0.2l0.4-0.1
		l0.2-0.2v-0.2l0.4-0.1l0.2-0.4l0.4-0.1l0.3-0.4l-0.2-0.3h-0.4l-0.2-0.2l0.4-0.5l0.1-0.1H577.1z"
					/>
					<path
						className={Styles.country}
						d="M575.2,355.7L575.2,355.7l0.5,0.1l0.4,0.2l-0.4,0.4l-0.1-0.2h-0.2l-0.4-0.1l-0.4-0.2l-0.3-0.6l0.3-0.1l0.4,0.1
		L575.2,355.7z"
					/>
					<path
						className={Styles.country}
						d="M566,349.8l0.6,0.7l0.3,0.2l0.3,0.5l-0.3-0.1l-0.1-0.2l-0.3-0.1l-0.4-0.4l-0.2-0.1l-0.2-0.4L566,349.8z"
					/>
					<path
						className={Styles.country}
						d="M569.7,349.8l0.3,0.6l0.2,0.5v0.4l0.2,0.2l0.2,0.7v0.9l0.2,0.3l0.5,0.2l0.4,0.6v0.8l-0.4-0.7v-0.2l-0.3-0.4
		l-0.6-0.2l0.2-0.2l-0.3-0.3v-0.3l0.2-0.3l-0.1-0.5l-0.3-0.4l0.1-0.1l-0.3-0.4l-0.1-0.6l-0.2,0.1L569.7,349.8z"
					/>
					<path
						className={Styles.country}
						d="M572.6,349.8l-0.3,0.2l-0.3-0.1l-0.1-0.2l0.6-0.2L572.6,349.8z"
					/>
					<path
						className={Styles.country}
						d="M564.4,347.9l-0.1-0.1l-0.3-0.7h0.2L564.4,347.9z"
					/>
					<path
						className={Styles.country}
						d="M574.9,347.4l-0.4,0.6h-0.4l0.2-0.3l0.1-0.6l0.4-0.3l0.1,0.4V347.4z"
					/>
					<path
						className={Styles.country}
						d="M556.4,346.2l-0.3,0.1l-0.2,0.3l-0.3-0.1l0.6-0.4L556.4,346.2z"
					/>
					<path
						className={Styles.country}
						d="M556.6,346.9l0.4,0.2l0.2-0.2l0.1-0.5l0.2-0.1l0.2,0.6v0.5l-0.1,0.3l0.1,0.6v0.3l-0.4,0.8l-0.2,0.1l-0.4-0.2
		l0.2-0.3l0.4-0.2l-0.1-0.1l-0.6,0.4l-0.2-0.2l0.4-0.3h-0.4l-0.2,0.1l-0.2-0.2v-0.7l0.1-0.2l-0.4-0.5v-0.2l0.4-0.2l0.1-0.3l0.3-0.1
		l0.1-0.2l0.6-0.3l0.1,0.4l-0.5,0.4L556.6,346.9z"
					/>
					<path
						className={Styles.country}
						d="M570.1,346.3l0.1,0.5l-0.2-0.1l-0.5,0.1l-0.4,0.1l-0.1-0.2l0.3-0.2l0.4-0.3V346l-0.4-0.2l-0.2-0.6l-0.1-0.6
		l-0.4-0.4V344l-0.5-0.5l0.3-0.3l0.4,0.4v0.5l0.2,0.3l0.1,0.5l0.3,0.5l0.4,0.3l0.1,0.3L570.1,346.3z"
					/>
					<path
						className={Styles.country}
						d="M559.8,340.8l0.3,0.2l-0.6,0.3l-0.3-0.1l-0.2,0.2l-0.5-0.1l0.2-0.3l0.4-0.2H559.8z"
					/>
					<path
						className={Styles.country}
						d="M555.6,340.3l0.4-0.2l0.3,0.3l-0.1,0.2l0.1,0.2l-0.2,0.4l0.3,0.3l0.2,0.9l0.2,0.3l0.4,0.5l-0.2,0.7l0.1,0.6
		l-0.2,0.3l-0.3,0.1l-0.4,0.4l-0.3,0.2h-0.3l-0.3,0.2H555v-0.4l-0.3-0.2l0.1-0.6l-0.3,0.3l-0.2-0.1l-0.6-0.4l-0.3-0.3l-0.2-0.3
		l0.7-0.3l0.5,0.7l0.1-0.6l-0.3-0.1l-0.1-0.3l0.1-0.3l0.6-0.6l0.2-0.4l-0.1-0.1l0.3-0.4v-0.5l-0.2-0.7l0.1-0.1h0.3L555.6,340.3z"
					/>
					<path
						className={Styles.country}
						d="M563.7,337.7l0.1,0.2l0.3,0.4l0.8,0.7l0.5,0.1l0.1,0.1l0.2,0.4l0.3,0.4l0.5,0.4v0.2l-0.2,0.8l-0.1,0.2v0.3
		l-0.1,0.5l-0.2,0.3l-0.1,0.7l-0.1,0.1l-0.2-0.6l-0.4-0.3l0.1-0.2l0.5,0.1l0.2-0.2l-0.1-0.2l0.1-0.4l0.3-0.4l0.1-0.5v-0.3l-0.5-0.4
		l-0.4-0.7l-0.3-0.2l-0.6-0.3l-0.3-0.3l-0.6-0.2l-0.4,0.2l0.4-0.9H563.7z"
					/>
					<path
						className={Styles.country}
						d="M554.9,330.2l0.5,0.2h0.1l0.7,0.1l0.2-0.2l0.2,0.1h0.5l0.4-0.2h0.4l0.3-0.2l0.1,0.5l-0.1,0.2h-0.3h-0.4
		l-1.1,0.2h-0.3l-1.5,0.4l-0.7,0.4h-0.4l-0.2-0.1l-0.3-0.3l-0.5-0.6l0.6,0.3l0.1,0.2h0.5l0.5-0.2l0.4-0.6l-0.3-0.1l0.2-0.3l0.1-0.3
		L554.9,330.2z"
					/>
					<path
						className={Styles.country}
						d="M559.2,328.9l0.8,0.2l0.2-0.1l0.4,0.3l0.9,1l0.2,0.6l0.4,0.1l0.7,0.5v0.2l-0.2,0.5l0.2,0.7h-0.4l-0.5,0.4
		l-0.1,0.2l-0.3,1l-0.1,0.9l-0.2,0.2l-0.3-0.2l-0.1-0.4l-0.4-0.1l0.3-0.5h0.2l0.5-0.4l-0.1-0.4l0.2-0.4v-0.3l0.2-0.3V332l0.2-0.1
		l0.2-0.3l0.1-0.3l-0.3-0.1l-0.5-0.1l-0.1-0.3v-0.2l-0.4-0.2v-0.3l-0.3-0.7l-0.2-0.1l-0.3,0.1l-0.5-0.1h-0.3l-0.6-0.4H559.2z"
					/>
					<path
						id="BL"
						className={Styles.country}
						d="M635.2,387l-0.1-0.3l0.3,0.1L635.2,387L635.2,387z"
					/>
					<path
						id="BM"
						className={Styles.country}
						d="M637.3,294H637l-0.1,0.1l0.1,0.2l-0.4,0.2l-0.1-0.1l0.2-0.1h0.1l0.1-0.3l0.3-0.1L637.3,294L637.3,294z
		"
					/>
					<path
						id="BB"
						className={Styles.country}
						d="M651.5,418l-0.6-0.2l-0.1-0.5v-0.8l0.2-0.4l0.2,0.2l0.2,0.6l0.5,0.3l0.1,0.4L651.5,418L651.5,418z"
					/>
					<path
						className={Styles.country}
						d="M532.7,377.8l0.3,0.1l0.2-0.4l0.4,0.1h0.5l0.1,0.2L534,378l-0.2-0.1l-0.4,0.1l-0.2,0.1h-0.7L532.7,377.8z"
					/>
					<path
						className={Styles.country}
						d="M540.8,375.3l-0.7,0.3l0.2-0.3H540.8z"
					/>
					<path
						className={Styles.country}
						d="M541.4,375.4l-0.1-0.1l0.8-0.3l-0.2,0.3L541.4,375.4z"
					/>
					<path
						id="DM"
						className={Styles.country}
						d="M642,401.7l0.5,0.1l0.2,0.5v0.6l-0.1,0.8l-0.1,0.2l-0.2,0.1l-0.4,0.2l0.1-0.4l-0.1-0.2l-0.1-0.7
		l-0.3-0.5v-0.3l0.1-0.3v-0.3h0.2L642,401.7L642,401.7z"
					/>
					<path
						className={Styles.country}
						d="M636.3,392H636l-0.1-0.2l0.2-0.4h0.2l0.1,0.2L636.3,392z"
					/>
					<path
						className={Styles.country}
						d="M635.6,390.5l-0.1,0.3h-0.2l-0.4-0.2l-0.2-0.2l0.3-0.4l0.5,0.4L635.6,390.5z"
					/>
					<path
						id="LC"
						className={Styles.country}
						d="M643.7,413.8l-0.2-0.2l-0.4-0.2l-0.1-0.2v-0.6l0.1-0.2l0.7-1.1l0.3,0.2v0.7L644,413l-0.1,0.4
		L643.7,413.8L643.7,413.8z"
					/>
					<path
						id="MF"
						className={Styles.country}
						d="M634.2,386l-0.4-0.1l0.2-0.3l0.3-0.1L634.2,386z"
					/>
					<path
						id="MS"
						className={Styles.country}
						d="M638.2,393.9l0.2,0.4v0.3l-0.2,0.2l-0.2-0.1l-0.1-0.3L638.2,393.9z"
					/>
					<path
						className={Styles.country}
						d="M607.1,385.9l-0.3-0.2l0.1-0.2h0.3l0.1,0.1L607.1,385.9z"
					/>
					<path
						className={Styles.country}
						d="M621.7,385.4h-0.2l-0.9,0.3H620l0.2-0.2l0.4-0.2l0.4-0.1l0.6,0.1L621.7,385.4z"
					/>
					<path
						className={Styles.country}
						d="M612.2,383l0.6,0.1h0.6l0.3,0.1l0.6-0.1l0.3,0.1l0.4-0.1l0.2,0.1l0.5-0.1l0.4,0.1h0.3l0.8,0.2v-0.2l0.6,0.2
		l0.1-0.1l0.8,0.1l0.2,0.2h0.2l0.6,0.3l0.2-0.1l-0.1,0.7l0.2,0.3l-0.9,0.3l-0.3,0.4l-0.3,0.6l-0.3,0.2l-0.3,0.1h-0.4l-0.6,0.1
		l-0.3,0.2l-0.7-0.1l-0.2-0.2l-0.5,0.2l-0.3-0.3l-0.7,0.2l-0.9-0.1l-0.3,0.1l-0.6,0.1l-0.2,0.1l-0.2-0.2l-0.4-0.1l-0.3,0.2l-0.6-0.1
		l0.3-0.7v-0.4l0.2-0.4l-0.2-0.6l-0.2-0.1l-0.1-0.4l0.4-0.2l0.2-0.1v-0.5l0.4-0.2L612.2,383z"
					/>
					<path
						id="SX"
						className={Styles.country}
						d="M634.2,386v0.1l-0.4-0.1v-0.1L634.2,386z"
					/>
					<path
						className={Styles.country}
						d="M587.7,361.6h0.7l0.3,0.4h-0.3l-0.3-0.1l-0.5,0.1l-0.1-0.3L587.7,361.6z"
					/>
					<path
						className={Styles.country}
						d="M585,361.5l0.3,0.4l0.6-0.1l-0.2,0.2h-0.6l-0.4-0.2L585,361.5z"
					/>
					<path
						className={Styles.country}
						d="M587.2,360.9v0.5l-0.5-0.2l-0.1-0.3l0.1-0.1L587.2,360.9z"
					/>
					<path
						id="VC"
						className={Styles.country}
						d="M642.2,417.4l-0.1,0.1l-0.5-0.3v-0.4l0.2-0.3l0.2-0.5h0.4l0.1,0.4l-0.1,0.8L642.2,417.4L642.2,417.4z"
					/>
					<path
						id="VG"
						className={Styles.country}
						d="M626.1,383.4l-0.4,0.2h-0.1l-0.3,0.2l-0.1-0.1l0.1-0.3l0.6-0.1L626.1,383.4L626.1,383.4z"
					/>
					<path
						className={Styles.country}
						d="M624.5,387.8l0.3,0.3l-1.1,0.2l-0.1-0.5l0.7-0.2L624.5,387.8z"
					/>
					<path
						className={Styles.country}
						d="M625.2,384l-0.2,0.2h-0.5V384l0.3-0.1L625.2,384z"
					/>
					<path
						className={Styles.country}
						d="M623.8,383.8l0.4,0.3l-0.2,0.2l-0.3-0.2h-0.5l0.2-0.2L623.8,383.8z"
					/>
					<path
						id="BQSE"
						className={Styles.country}
						d="M634.3,389.6l-0.2,0.1v-0.2l0.1-0.1L634.3,389.6L634.3,389.6z"
					/>
					<path
						id="BQSA"
						className={Styles.country}
						d="M632.8,388.7L632.8,388.7v-0.2l0.1,0.1L632.8,388.7z"
					/>
					<path
						id="MQ"
						className={Styles.country}
						d="M644,406.9v0.2l0.4-0.1l-0.2,0.5l0.2,0.2v0.2l0.2,0.2l0.2,0.9l-0.3,0.3l-0.1-0.4l-0.1,0.1l-0.6-0.1
		h-0.4l-0.2-0.3l0.6-0.5h-0.4l-0.4-0.4l-0.1-0.5l-0.2-0.5l0.3-0.4l0.4,0.1l0.5,0.3L644,406.9L644,406.9z"
					/>
					<path
						className={Styles.country}
						d="M643,399.9h-0.3l-0.2-0.4l0.1-0.2l0.3-0.3l0.3,0.2l0.1,0.3v0.2L643,399.9z"
					/>
					<path
						className={Styles.country}
						d="M641.2,397.3h0.3v0.2v0.1l-0.2,0.1v0.8v0.4l-0.2,0.2l-0.6,0.3v-0.2l-0.2-0.2l-0.1-0.5V398l-0.1-0.5v-0.3
		l0.2-0.3l0.3-0.1l0.5,0.3L641.2,397.3z"
					/>
					<path
						className={Styles.country}
						d="M642.6,396.9l0.4,0.1l0.5,0.4l-1.3,0.3l-0.3,0.1l-0.4-0.3l0.1-0.6l0.2-0.1l-0.1-0.6l0.1-0.2l0.3-0.2l0.3,0.4
		v0.4L642.6,396.9z"
					/>
				</g>
				<g
					id="Europe"
					className={Styles.continent}
					data-active={active == "Europe"}
					onClick={() => setActiveContinent("Europe")}
				>
					<path
						id="AL"
						className={Styles.country}
						d="M1088,228l0.4,1.2l1.4-0.6l1.2,1.7l1.3,0.7l0.6,2.3l-0.5,2.2l1,2.7l2.3,1.5l0.1,1.7l-1.7,0.9l-0.1,2.1
		l-2.2,3.1l-0.9-0.4l-0.2-1.4l-3.1-2.2l-0.7-3l0.1-4.4l0.5-1.9l-0.9-1l-0.5-2.1L1088,228L1088,228z"
					/>
					<path
						id="AT"
						className={Styles.country}
						d="M1070.6,190.8l-0.3,0.8l0.7,2.1l-0.2,2.6h-2.8l1.1,1.4l-1.3,4l-0.9,1.1l-4.4,0.1l-2.4,1.5l-4.2-0.5
		l-7.3-1.7l-1.3-2.1l-4.9,1.1l-0.5,1.2l-3.1-0.9l-2.6-0.2l-2.3-1.2l0.7-1.5l-0.2-1.1l1.4-0.3l2.7,1.7l0.6-1.7l4.4,0.3l3.5-1.1
		l2.4,0.2l1.7,1.3l0.4-1.1l-1-4.1l1.7-0.8l1.6-2.9l3.8,2.1l2.6-2.6l1.7-0.5l4,1.9l2.3-0.3L1070.6,190.8L1070.6,190.8z"
					/>
					<path
						id="BE"
						className={Styles.country}
						d="M1016.5,177.1l-0.4,4.2l-1.3,0.2l-0.4,3.5l-4.4-2.9l-2.5,0.5l-3.5-2.9l-2.4-2.5l-2.2-0.1l-0.8-2.2
		l3.9-1.2l3.6,0.5l4.5-1.3l3.1,2.7L1016.5,177.1L1016.5,177.1z"
					/>
					<path
						id="BG"
						className={Styles.country}
						d="M1132.6,221.6l-2.3,2.6l-1.3,4.5l2.1,3.6l-4.6-0.8l-5,2l0.3,3.2l-4.6,0.6l-3.9-2.3l-4,1.8l-3.8-0.2
		l-0.8-4.2l-2.8-2.1l0.7-0.8l-0.6-0.8l0.6-2l1.8-2l-2.8-2.7l-0.7-2.4l1.1-1.4l1.8,2.6l1.9-0.4l4,0.9l7.6,0.4l2.3-1.6l5.9-1.5l4,2.3
		L1132.6,221.6L1132.6,221.6z"
					/>
					<path
						id="BA"
						className={Styles.country}
						d="M1083,214.3l1.9-0.1l-1.1,2.8l2.7,2.5l-0.5,2.9l-1.1,0.3l-0.9,0.6l-1.6,1.5l-0.4,3.5l-4.8-2.4
		l-2.1-2.7l-2.1-1.4l-2.5-2.4l-1.3-1.9l-2.7-3l0.8-2.6l2,1.5l1-1.4l2.3-0.1l4.5,1.1l3.5-0.1L1083,214.3L1083,214.3z"
					/>
					<path
						id="BY"
						className={Styles.country}
						d="M1141.6,162.7l-3.9-0.2l-0.8,0.6l1.5,2l2,4l-4.1,0.3l-1.3,1.4l0.3,3.1l-2.1-0.6l-4.3,0.3l-1.5-1.5
		l-1.7,1.1l-1.9-0.9l-3.9-0.1l-5.7-1.5l-4.9-0.5l-3.8,0.2l-2.4,1.6l-2.3,0.3l-0.5-2.8l-1.9-2.8l2.8-1.3l-0.4-2.4l-1.7-2.3l-0.6-2.7
		h4.7l4.8-2.3l0.5-3.4l3.6-2l-1-2.7l2.7-1l4.6-2.3l5.3,1.5l0.9,1.5l2.4-0.7l4.8,1.4l1.1,2.9l-0.7,1.6l3.8,4l2.1,1.1v1.1l3.4,1.1
		l1.7,1.6L1141.6,162.7L1141.6,162.7z"
					/>
					<path
						id="CH"
						className={Styles.country}
						d="M1034.4,197.5l0.2,1.1l-0.7,1.5l2.3,1.2l2.6,0.2l-0.3,2.5l-2.1,1.1l-3.8-0.8l-1,2.5l-2.4,0.2l-0.9-1
		l-2.7,2.2l-2.5,0.3l-2.2-1.4l-1.8-2.7l-2.4,1v-2.9l3.6-3.5l-0.2-1.6l2.3,0.6l1.3-1.1h4.2l1-1.3L1034.4,197.5L1034.4,197.5z"
					/>
					<path
						id="CZ"
						className={Styles.country}
						d="M1059.7,175.2l2.5,2l3.7,0.5l-0.2,1.7l2.8,1.3l0.6-1.6l3.4,0.7l0.7,2l3.7,0.3l2.6,3.1h-1.5l-0.7,1.1
		l-1.1,0.3l-0.2,1.4l-0.9,0.3l-0.1,0.6l-1.6,0.6l-2.2-0.1l-0.6,1.4l-2.4-1.2l-2.3,0.3l-4-1.9l-1.7,0.5l-2.6,2.6l-3.8-2.1l-3-2.6
		l-2.6-1.5l-0.7-2.7l-1-1.8l3.4-1.3l1.7-1.6l3.5-1.2l1.1-1.2l1.3,0.7L1059.7,175.2L1059.7,175.2z"
					/>
					<path
						id="DE"
						className={Styles.country}
						d="M1053.9,158.9l1.4,3.1l-1.2,1.7l1.9,2.1l1.5,3.3l-0.2,2.2l2.4,3.9l-2.2,0.6l-1.3-0.7l-1.1,1.2
		l-3.5,1.2l-1.7,1.6l-3.4,1.3l1,1.8l0.7,2.7l2.6,1.5l3,2.6l-1.6,2.9l-1.7,0.8l1,4.1l-0.4,1.1l-1.7-1.3l-2.4-0.2l-3.5,1.1l-4.4-0.3
		l-0.6,1.7l-2.7-1.7l-1.4,0.3l-5.5-1.9l-1,1.3h-4.2l0.4-4.5l2.4-4.2l-7.2-1.2l-2.4-1.6l0.2-2.7l-1-1.4l0.4-4.2l-1.1-6.5h2.9l1.2-2.3
		l0.9-5.6l-0.9-2.1l0.8-1.3l4-0.3l1,1.3l3.1-3l-1.3-2.3l-0.4-3.4l3.7,0.8l2.9-0.9l0.3,2.3l4.9,1.4l0.1,2.2l4.7-1.2l2.6-1.6l5.6,2.4
		L1053.9,158.9L1053.9,158.9z"
					/>
					<path
						className={Styles.country}
						d="M1046.1,147.7l-2.4,4.9l-5.2-3.5l-0.9-2.5l6.8-2L1046.1,147.7z"
					/>
					<path
						className={Styles.country}
						d="M1033.3,151.5l-2.9,0.9l-3.7-0.8l-2.1-3.4l-0.4-6.1l0.6-1.7l1.3-1.8l4-0.3l1.6-1.7l3.6-1.7v3.1l-1.2,2l0.7,1.6
		l2.6,0.9l-1,2.3l-1.4-0.6l-3.1,4.3L1033.3,151.5z"
					/>
					<path
						id="EE"
						className={Styles.country}
						d="M1113.7,124.6l0.9,1l-2.6,3.4l2.4,5.6l-1.6,1.9l-3.8-0.1l-4.4-2.2l-2.1-0.7l-3.8,1l-0.1-3.5l-1.5,0.8
		l-3.3-2.1l-1-3.4l5.5-1.7l5.6-0.8l5.1,0.9L1113.7,124.6L1113.7,124.6z"
					/>
					<path
						id="FI"
						className={Styles.country}
						d="M1104.1,70.1l0.4,3.8l7.3,3.7l-2.9,4.2l6.5,6.3l-1.7,4.8l4.9,4.2l-0.9,3.8l7.4,3.9l-0.9,2.9l-3.4,3.4
		l-8,7.4l-8,0.5l-7.6,2.1l-7.1,1.3l-3.2-3.2l-4.7-1.9l0.1-5.8l-3-5.2l1.6-3.4l3.3-3.5l8.8-6.2l2.6-1.2l-0.9-2.4l-6.5-2.6l-1.8-2.2
		l-1.8-8.5l-7.2-3.7l-6-2.7l2.2-1.4l5.1,2.8l5.3-0.2l4.7,1.3l3.4-2.4l1.1-4l5.9-1.8l5.8,2.1L1104.1,70.1L1104.1,70.1z"
					/>
					<path
						className={Styles.country}
						d="M956.7,158.2l-3.5-1.2l-3,0.1l1.2-3.3l-0.9-3.2l4-0.3l4.9,3.8L956.7,158.2z"
					/>
					<path
						className={Styles.country}
						d="M972.6,129.5l-5.1,6.5l4.7-0.8h5.1l-1.3,4.9l-4.3,5.4l4.9,0.3l0.3,0.7l4.2,7.1l3.2,1l2.9,7l1.4,2.4l5.9,1.1
		l-0.6,4l-2.4,1.8l1.9,3.2l-4.4,3.2l-6.5-0.1l-8.4,1.8l-2.2-1.3l-3.3,2.9l-4.5-0.7l-3.6,2.4l-2.5-1.2l7.3-6.5l4.4-1.4l-7.6-1
		l-1.3-2.5l5.1-1.9l-2.5-3.3l1-4l7.1,0.6l0.8-3.6l-3.1-3.7l-0.1-0.1l-5.7-1.1l-1.1-1.6l1.8-2.7l-1.5-1.7l-2.6,2.9l-0.1-5.9l-2.2-3
		l1.9-6.2l3.8-4.8l3.6,0.4L972.6,129.5z"
					/>
					<path
						className={Styles.country}
						d="M1112.7,272.6l3.1,2.2l4.1-0.4l4,0.4v1.2l2.8-0.8l-0.5,1.9l-7.6,0.5l-0.1-1l-6.6-1.3L1112.7,272.6z"
					/>
					<path
						className={Styles.country}
						d="M1121.9,239.9l-3.2-0.2l-2.7-0.6l-6.2,1.6l4,3.6l-2.5,1.1h-2.9l-3.1-3.3l-0.9,1.4l1.6,3.8l2.9,3l-1.9,1.4
		l3.2,2.9l2.8,1.9l0.4,3.6l-5-1.7l1.8,3.3l-3.3,0.6l2.5,5.7l-3.5,0.1l-4.6-2.8l-2.4-5.1l-1.3-4.3l-2.3-2.9l-3-3.7l-0.5-1.8l2.2-3.1
		l0.1-2.1l1.7-0.9l-0.1-1.7l3.4-0.5l1.8-1.4l2.8,0.1l0.8-1.1l1-0.2l3.8,0.2l4-1.8l3.9,2.3l4.6-0.6l-0.3-3.2l2.7,1.7l-1.1,4
		L1121.9,239.9z"
					/>
					<path
						id="GL"
						className={Styles.country}
						d="M896.3,1.4l19.9,3l-6.7,1.4l-13,0.2L878,6.4l1.4,0.7l12.3-0.5l9.7,1.4l7-1.2l2.4,1.4l-4.5,2.4l9.2-1.6
		l17.1-1.5l10,0.8l1.7,1.7l-14.8,2.9l-2.2,1l-11.4,0.8l8.1,0.2l-4.9,3.2l-3.6,2.9l-1.2,5.2l3.7,3.2l-5.9,0.1l-6.5,1.6l6.3,2.6
		l-0.1,4.2l-4.2,0.5l4.1,4.3l-8.7,0.4l4,2l-1.6,1.8l-5.7,0.8l-5.5,0.1l4.2,3.4l-0.5,2.4l-7.3-2.2l-2.4,1.4l5,1.3l4.6,3.2l0.6,4.3
		l-7.4,1l-2.7-2.1l-4.2-3l0.5,3.6l-5.4,2.8l10.7,0.2l5.5,0.3l-11.9,4.7l-12.2,4.3l-12.7,1.8l-4.6,0.1l-4.9,2.1l-7.5,5.8l-10.2,3.9
		l-3,0.3l-6.1,1.3l-6.6,1.4l-4.8,3.4l-1.4,4l-3.4,3.8l-8.6,4.6l0.3,4.5l-3.6,4.8l-4.1,5.7l-6.5,0.4l-5-4.8l-9-0.1l-3.2-3.2l-0.8-5.6
		l-4.8-7.2l-0.7-3.7l1.5-5.1l-3.7-5.1l3.3-4.1l-1.9-2l7-6.4l7.2-2.1l2.6-2.2l2.8-4.2l-5.5,1.9l-2.6,0.8l-4.1,0.7l-4.2-1.7l1.5-3.7
		l3-2.8l3.8-0.1l7.6,1.5l-5.3-3.4l-2.7-1.8l-4.4,0.7l-2.6-1.3l7-4.8l-1.3-2L768,46l-1.4-5.4l-3.6-1.9l1.4-2.1l-8.1-2.9l-7.7-0.4
		l-10.1,0.2l-9.5,0.4l-3.1-1.6l-3.8-3.1l11-1.5l7.6-0.2l-14.6-1.3l-6.5-1.9l2.2-1.8l15.7-2.2l15-2.2l2.8-1.6l-8.1-1.6l4.6-1.7
		l14.7-2.9l5.4-0.4l0.2-1.8l9-1.1l11-0.6h10.4l2.8,1.2l10.6-2.2l7.1,1.5l4.6,0.3l6.2,1.3l-6.7-2.1l1.6-1.7l12.7-2.2L853,2.7l5.1-1.4
		L870,1L896.3,1.4L896.3,1.4z"
					/>
					<path
						id="HR"
						className={Styles.country}
						d="M1081.5,207.6l1.5,2.5l1.7,1.8l-1.7,2.4l-2.4-1.4l-3.5,0.1l-4.5-1.1l-2.3,0.1l-1,1.4l-2-1.5l-0.8,2.6
		l2.7,3l1.3,1.9l2.5,2.4l2.1,1.4l2.1,2.7l4.8,2.4l-0.5,1l-5-2.3l-3.2-2.3l-4.8-1.9l-4.7-4.6l1-0.5l-2.5-2.7l-0.3-2.1l-3.3-1
		l-1.4,2.7l-1.6-2.1v-2.2l0.1-0.1l3.6,0.2l0.8-1l1.8,1l2,0.1l-0.1-1.7l1.7-0.7l0.3-2.5l3.9-1.7l1.6,0.8l4,2.7l4.3,1.2L1081.5,207.6
		L1081.5,207.6z"
					/>
					<path
						id="HU"
						className={Styles.country}
						d="M1096.2,191.9l3,1.7l0.5,1.7l-2.9,1.3l-1.9,4.2l-2.6,4.3l-3.9,1.2l-3.2-0.3l-3.7,1.6l-1.8,1l-4.3-1.2
		l-4-2.7l-1.6-0.8l-1.2-2.1l-0.8-0.1l1.3-4l-1.1-1.4h2.8l0.2-2.6l2.7,1.7l1.9,0.6l4.1-0.7l0.3-1.3l1.9-0.2l2.3-0.9l0.6,0.4l2.3-0.8
		l1-1.5l1.6-0.4l5.5,1.9L1096.2,191.9L1096.2,191.9z"
					/>
					<path
						id="IE"
						className={Styles.country}
						d="M956.7,158.2l0.7,4.4l-3.9,5.5l-8.8,3.6l-6.8-0.9l4.3-6.4l-2.1-6.2l6.7-4.8l3.7-2.8l0.9,3.2l-1.2,3.3
		l3-0.1L956.7,158.2L956.7,158.2z"
					/>
					<path
						id="IS"
						className={Styles.country}
						d="M924.8,84.5l-1.4,3.6l4.4,3.8l-6.1,4.3l-13.1,3.9l-3.9,1.1l-5.6-0.9l-11.9-1.8L892,96l-9-2.7l7.9-1.1
		l0.1-1.7l-8.8-1.3l3.6-3.7l6.6-0.8l6,3.8l7-3l5.1,1.5l7.3-2.9L924.8,84.5L924.8,84.5z"
					/>
					<path
						className={Styles.country}
						d="M1068.2,256.4l-1.7,5.1l0.9,1.9l-0.9,3.3l-4.2-2.4l-2.7-0.7l-7.5-3.2l0.5-3.3l6.2,0.6l5.4-0.7L1068.2,256.4z"
					/>
					<path
						className={Styles.country}
						d="M1034.2,237.4l3.3,4.5l-0.4,8.5l-2.4-0.4l-2.1,2.1l-2-1.7l-0.5-7.7l-1.3-3.6l2.9,0.3L1034.2,237.4z"
					/>
					<path
						className={Styles.country}
						d="M1055.9,203.9l-0.4,3.1l1.4,2.7l-4.1-1l-3.9,2.3l0.4,3.1l-0.5,1.8l1.9,3.2l5,3.2l2.9,5.3l6.1,5.1l4-0.1
		l1.4,1.4l-1.4,1.3l4.8,2.3l4,1.9l4.7,3.4l0.6,1.1l-0.8,2.3l-3.1-3l-4.6-1l-1.9,4.1l3.9,2.4l-0.4,3.3l-2.1,0.4l-2.5,5.5l-2.2,0.5
		l-0.1-2l0.9-3.4l1.1-1.4l-2.3-3.7l-1.8-3.2l-2.2-0.8l-1.8-2.7l-3.4-1.2l-2.4-2.6l-3.8-0.4l-4.3-2.8l-4.9-4.2l-3.7-3.6l-1.9-6.3
		l-2.6-0.7l-4.2-2.1l-2.3,0.8l-2.9,3l-2.1,0.4l0.5-2.7l-2.8-0.8l-1.5-4.9l1.7-1.9l-1.5-2.4l0.1-1.8l2.2,1.4l2.5-0.3l2.7-2.2l0.9,1
		l2.4-0.2l1-2.5l3.8,0.8l2.1-1.1l0.3-2.5l3.1,0.9l0.5-1.2l4.9-1.1l1.3,2.1L1055.9,203.9z"
					/>
					<path
						id="LT"
						className={Styles.country}
						d="M1111.1,147.6l1,2.7l-3.6,2l-0.5,3.4l-4.8,2.3h-4.7l-1.4-1.9l-2.5-0.7l-0.6-1.5l0.2-1.7l-2.2-0.9
		l-5.1-1.1l-1.7-5.1l5.1-1.8l7.9,0.4l4.5-0.6l0.9,1.2l2.5,0.4L1111.1,147.6L1111.1,147.6z"
					/>
					<path
						id="LU"
						className={Styles.country}
						d="M1016.9,185.4l-1.4,0.1l-1.1-0.5l0.4-3.5l1.3-0.2l1,1.4L1016.9,185.4z"
					/>
					<path
						id="LV"
						className={Styles.country}
						d="M1112.8,136.5l2.5,1.3l1,2.9l2.1,3.6l-4.6,2.3l-2.7,1l-5-2.9l-2.5-0.4l-0.9-1.2l-4.5,0.6l-7.9-0.4
		l-5.1,1.8l-0.5-4.5l1.7-3.8l4.1-2l4.4,4.5l3.7-0.2l0.1-4.6l3.8-1l2.1,0.7l4.4,2.2L1112.8,136.5L1112.8,136.5z"
					/>
					<path
						id="MK"
						className={Styles.country}
						d="M1105.5,236.6l-1,0.2l-0.8,1.1l-2.8-0.1l-1.8,1.4l-3.4,0.5l-2.3-1.5l-1-2.7l0.5-2.2l0.7,0.1l0.1-1.3
		l2.9-1l1.2-0.3l1.7-0.3l2.4-0.2l2.8,2.1L1105.5,236.6L1105.5,236.6z"
					/>
					<path
						id="ME"
						className={Styles.country}
						d="M1090.6,227.2l-0.8,1.4l-1.4,0.6l-0.4-1.2l-1.9,3.1l0.5,2.1l-1.1-0.5l-1.7-2.1l-2.3-1.3l0.5-1l0.4-3.5
		l1.6-1.5l0.9-0.6l1.4,1.1l0.9,0.9l1.7,0.7l2.1,1.3L1090.6,227.2L1090.6,227.2z"
					/>
					<path
						className={Styles.country}
						d="M1113.7,67.5l-6.4,2.1l-3.2,0.5l0.8-3.8l-5.8-2.1l-5.9,1.8l-1.1,4l-3.4,2.4l-4.7-1.3l-5.3,0.2l-5.1-2.8
		l-2.2,1.4l-2.6,0.2l0.1,3.6l-8-0.9l-0.6,3.1h-4l-2.3,3.9l-3.4,6.1l-5.7,7.9l1.8,2l-1.3,2.2l-4.3-0.1l-2.4,5.4l1,7.7l3.1,2.9
		l-0.8,6.9l-3.4,4l-1.8,3.4l-3.3-3.6l-8.6,6.8l-6.1,1.4l-6.5-3l-1.8-6.3l-2-13.5l4-3.7l11.3-4.9l8.1-5.9l7.2-7.8L1048,77l6.4-4.1
		l10.3-6.8l8.5-2.4l6.7,0.3l5.2-4.4l7.4,0.2l7-1l13.7,3.9l-4.9,1.4L1113.7,67.5z"
					/>
					<path
						className={Styles.country}
						d="M1076.6,25.2l-7.6,1.9l-6.8-1.1l2.2-1.2l-2.6-1.5l7.3-0.9l1.9,1.7L1076.6,25.2z"
					/>
					<path
						className={Styles.country}
						d="M1051,16.7l12.6,3.4l-8.6,1.8l-1.2,3.4l-3,0.9l-0.9,4l-4.4,0.2l-8.5-2.9l3-1.7l-5.7-1.4l-7.7-3.9l-3.2-3.5
		l9.3-1.6l2.3,1.5h5l1-1.5l5.2-0.2L1051,16.7z"
					/>
					<path
						className={Styles.country}
						d="M1075.4,13.7l7.4,1.5l-4.4,2.4l-10.1,0.5l-10.7-0.8l-1-1.2h-5.1l-4.3-2l10.5-1.2l5.4,1l3.1-1.3L1075.4,13.7z"
					/>
					<path
						id="PL"
						className={Styles.country}
						d="M1079.9,154.8l5.9,0.7l8.8-0.1l2.5,0.7l1.4,1.9l0.6,2.7l1.7,2.3l0.4,2.4l-2.8,1.3l1.9,2.8l0.5,2.8
		l3.2,5.4l-0.3,1.7l-2.3,0.7l-3.8,5.2l1.6,2.8l-1.1-0.4l-5-2.4l-3.5,0.9l-2.4-0.6l-2.8,1.3l-2.7-2.2l-1.9,0.9l-0.3-0.4l-2.6-3.1
		l-3.7-0.3l-0.7-2l-3.4-0.7l-0.6,1.6l-2.8-1.3l0.2-1.7l-3.7-0.5l-2.5-2l-2.4-3.9l0.2-2.2l-1.5-3.3l-1.9-2.1l1.2-1.7l-1.4-3.1
		l3.1-1.8l7.1-2.8l5.8-2l4.8,1l0.6,1.5L1079.9,154.8L1079.9,154.8z"
					/>
					<path
						id="RO"
						className={Styles.country}
						d="M1118.9,193.1l1.6,0.7l1.8,1.8l2,2.6l3.4,3.8l0.6,2.7l-0.2,2.7l1.3,2.9l2.4,1.2l2.3-1.1l2.4,1.1
		l0.4,1.7l-2.3,1.3l-1.6-0.6l-0.4,7.7l-3.1-0.7l-4-2.3l-5.9,1.5l-2.3,1.6l-7.6-0.4l-4-0.9l-1.9,0.4l-1.8-2.6l-1-1.1l1-1.1l-1.3-0.7
		l-1.5,1.4l-3.1-1.9l-0.7-2.6l-3.2-1.4l-0.8-2.1l-3-2.4l3.9-1.2l2.6-4.3l1.9-4.2l2.9-1.3l2-1.4l3.2,0.7h3.2l2.5,1.6l1.6-1l3.6-0.6
		l1-1.5L1118.9,193.1L1118.9,193.1z"
					/>
					<path
						id="RS"
						className={Styles.country}
						d="M1102,218.2l-1.1,1.4l0.7,2.4l2.8,2.7l-1.8,2l-0.6,2l0.6,0.8l-0.7,0.8l-2.4,0.2l-1.7,0.3l-0.3-0.5
		l0.6-0.7l0.4-1.6l-0.7,0.1l-1.1-1.2l-0.9-0.3l-0.8-1l-1-0.4l-0.8-0.9l-0.9,0.4l-0.5,2.1l-1.2,0.4l0.4-0.5l-2.1-1.3l-1.7-0.7
		l-0.9-0.9l-1.4-1.1l1.1-0.3l0.5-2.9l-2.7-2.5l1.1-2.8l-1.9,0.1l1.7-2.4l-1.7-1.8l-1.5-2.5l3.7-1.6l3.2,0.3l3,2.4l0.8,2.1l3.2,1.4
		l0.7,2.6l3.1,1.9l1.5-1.4l1.3,0.7l-1,1.1L1102,218.2L1102,218.2z"
					/>
					<path
						id="SK"
						className={Styles.country}
						d="M1098.1,187.7l-1.2,1.7l-0.7,2.5l-1,0.6l-5.5-1.9l-1.6,0.4l-1,1.5l-2.3,0.8l-0.6-0.4l-2.3,0.9
		l-1.9,0.2l-0.3,1.3l-4.1,0.7l-1.9-0.6l-2.7-1.7l-0.7-2.1l0.3-0.8l0.6-1.4l2.2,0.1l1.6-0.6l0.1-0.6l0.9-0.3l0.2-1.4l1.1-0.3l0.7-1.1
		h1.5l0.3,0.4l1.9-0.9l2.7,2.2l2.8-1.3l2.4,0.6l3.5-0.9L1098.1,187.7L1098.1,187.7z"
					/>
					<path
						id="SI"
						className={Styles.country}
						d="M1069.8,203.9l-3.9,1.7l-0.3,2.5l-1.7,0.7l0.1,1.7l-2-0.1l-1.8-1l-0.8,1l-3.6-0.2l1.1-0.5l-1.4-2.7
		l0.4-3.1l4.2,0.5l2.4-1.5l4.4-0.1l0.9-1.1l0.8,0.1L1069.8,203.9L1069.8,203.9z"
					/>
					<path
						id="SE"
						className={Styles.country}
						d="M1088.2,87l-7,1.6l-3.5,3.9l1.3,3.5l-6.2,4.5l-7.8,5l-2.1,8.1l3.7,4.1l4.8,3.3l-3.3,6.6l-4.6,1.4
		l-0.6,10l-2.1,5.7l-5.7-0.6l-2.2,4.8l-5.5,0.3l-1.9-5.7l-4.5-6.9l-4.2-8.4l1.8-3.4l3.4-4l0.8-6.9l-3.1-2.9l-1-7.7l2.4-5.4l4.3,0.1
		l1.3-2.2l-1.8-2l5.7-7.9l3.4-6.1l2.3-3.9h4l0.6-3.1l8,0.9l-0.1-3.6l2.6-0.2l6,2.7l7.2,3.7l1.8,8.5L1088.2,87L1088.2,87z"
					/>
					<path
						className={Styles.country}
						d="M1121.9,239.9l1.2-0.7l1.1-4l-2.7-1.7l5-2l4.6,0.8l0.9,2.5l4.8,2l-0.7,1.6l-6.2,0.3l-2,2l-3.9,3.4l-2-2.9
		L1121.9,239.9z"
					/>
					<path
						id="UA"
						className={Styles.country}
						d="M1157.2,174.6l2.3,2.7l0.1,1.2l6.7,2.2l3.6-1l3.6,2.9l2.9-0.1l7.7,2l0.4,1.9l-1.3,3.2l1.8,3.5
		l-0.3,2.1l-4.8,0.4l-2.2,1.8l0.4,2.7l-3.9,0.5l-3,2.1l-4.6,0.3l-4,2.4l1,3.9l2.8,1.5l5.1-0.4l-0.6,2.3l-5.4,1.1l-6.3,3.6l-3.1-1.3
		l0.7-2.9l-5.9-1.9l0.7-1.2l4.6-2.1l-1.7-1.4l-8.1-1.6l-0.8-2.4l-4.5,0.8l-1.3,3.5l-3.3,4.6l-2.4-1.1l-2.3,1.1l-2.4-1.2l1.2-0.7
		l0.6-2.1l1.1-2.1l-0.6-1.1l1-0.5l0.6,0.9l3,0.2l1.3-0.5l-1-0.6l0.2-1l-2-1.6l-1.1-2.6l-2-1l0.1-2.1l-2.6-1.7l-2-0.3l-4-1.9
		l-3.2,0.6l-1.1,0.9h-2.1l-1,1.5l-3.6,0.6l-1.6,1l-2.5-1.6h-3.2l-3.2-0.7l-2,1.4l-0.5-1.7l-3-1.7l0.7-2.5l1.2-1.7l1.1,0.4l-1.6-2.8
		l3.8-5.2l2.3-0.7l0.3-1.7l-3.2-5.4l2.3-0.3l2.4-1.6l3.8-0.2l4.9,0.5l5.7,1.5l3.9,0.1l1.9,0.9l1.7-1.1l1.5,1.5l4.3-0.3l2.1,0.6
		l-0.3-3.1l1.3-1.4l4.1-0.3l1.8,0.2l1-1.4l1.5,0.3l4.9-0.6l3.8,3.5l-0.9,1.3l0.8,1.9L1157.2,174.6L1157.2,174.6z"
					/>
					<path
						id="XK"
						className={Styles.country}
						d="M1097.8,230.8l-1.2,0.3l-2.9,1l-0.1,1.3l-0.7-0.1l-0.6-2.3l-1.3-0.7l-1.2-1.7l0.8-1.4l1.2-0.4l0.5-2.1
		l0.9-0.4l0.8,0.9l1,0.4l0.8,1l0.9,0.3l1.1,1.2l0.7-0.1l-0.4,1.6l-0.6,0.7L1097.8,230.8L1097.8,230.8z"
					/>
					<path
						id="NL"
						className={Styles.country}
						d="M1016.5,177.1l-2.8-1.5l-3.1-2.7l-4.5,1.3l-3.6-0.5l2.5-1.7l4-9l6.5-2.6l4,0.2l0.9,2.1l-0.9,5.6
		l-1.2,2.3h-2.9L1016.5,177.1L1016.5,177.1z"
					/>
					<path
						id="PT"
						className={Styles.country}
						d="M946.9,263.7l-2.2,1.6l-2.8-0.9l-2.7,0.7l0.9-5l-0.3-3.9l-2.4-0.6l-1.1-2.4l0.5-4.2l2.2-2.3l0.5-2.6
		l1.2-3.8v-2.7l-0.9-2.3l-0.2-2.2l1.9-1.6l2.2-0.9l1.2,3.1h3l0.9-0.8l3.1,0.2l1.3,3.2l-2.4,1.7l-0.3,5l-0.8,0.9l-0.3,3.1l-2.3,0.5
		l2,3.8l-1.6,4.2l1.8,1.9l-0.8,1.7l-2,2.4L946.9,263.7L946.9,263.7z"
					/>
					<path
						className={Styles.country}
						d="M1094.6,155.4l-8.8,0.1l-5.9-0.7l0.7-2.6l6.3-2l5.1,1.1l2.2,0.9l-0.2,1.7L1094.6,155.4z"
					/>

					<path
						id="ES"
						className={Styles.country}
						d="M976.6,223.4l2,2.4l9.5,2.9l1.9-1.4l5.8,2.9l5.9-0.8l0.4,3.7l-4.9,4.2l-6.6,1.4l-0.5,2.1l-3.2,3.5
		l-2,5.2l2,3.7l-3,2.8l-1.2,4.2l-4,1.3l-3.7,4.9l-6.8,0.1l-5-0.1l-3.4,2.2l-2.1,2.4l-2.6-0.5l-1.9-2.2l-1.4-3.6l-4.9-1l-0.4-2.2
		l2-2.4l0.8-1.7l-1.8-1.9l1.6-4.2l-2-3.8l2.3-0.5l0.3-3.1l0.8-0.9l0.3-5l2.4-1.7l-1.3-3.2l-3.1-0.2l-0.9,0.8h-3l-1.2-3.1l-2.2,0.9
		l-1.9,1.6l0.5-4.5l-2-2.7l7.4-4.6l6.2,1.1h6.9l5.4,1.1l4.3-0.4L976.6,223.4L976.6,223.4z"
					/>
					<path
						className={Styles.country}
						d="M1035.7,231.4l-1.5,4.9l-2.4-1.3l-1.3-4.2l0.9-2.4l3.2-2.4L1035.7,231.4z"
					/>
					<path
						className={Styles.country}
						d="M1014.4,185l1.1,0.5l1.4-0.1l2.4,1.6l7.2,1.2l-2.4,4.2l-0.4,4.5l-1.3,1.1l-2.3-0.6l0.2,1.6l-3.6,3.5v2.9l2.4-1
		l1.8,2.7l-0.1,1.8l1.5,2.4l-1.7,1.9l1.5,4.9l2.8,0.8l-0.5,2.7l-4.5,3.6l-10.2-1.7l-7.4,2.1l-0.6,3.8l-5.9,0.8l-5.8-2.9l-1.9,1.4
		l-9.5-2.9l-2-2.4l2.7-3.8l1-12.6l-5.1-6.6l-3.7-3.2l-7.6-2.4l-0.4-4.6l6.5-1.3l8.3,1.6l-1.5-7.1l4.7,2.7l11.4-4.8l1.5-5.1l4.2-1.3
		l0.8,2.2l2.2,0.1l2.4,2.5l3.5,2.9l2.5-0.5L1014.4,185z"
					/>
					<path
						className={Styles.country}
						d="M1166.5,277.2L1166.5,277.2l-0.2,0.3l-0.1,0.6l-0.1,0.2h-0.3l-0.2,0.2l-0.5,0.2l-0.2,0.1l-0.6,0.3l-0.4-0.1
		l-0.5,0.1l-0.3,0.4l-0.2-0.3l-0.3,0.1h-0.2l-0.3-0.3l-0.4,0.3h-0.5l-0.5-0.2l-0.5-0.1l-0.4-0.3l-0.3-0.6l-0.3-0.3l-0.2-0.7
		l-0.2-0.3l0.1-0.3l0.5,0.3l0.4-0.1l0.2-0.3l0.1-0.3l0.3-0.1l0.2,0.1l0.2-0.2h0.2l0.1,0.4l0.5,0.2l0.1,0.2h0.6l0.6-0.5l0.4,0.1
		l0.3-0.2l0.3,0.1l0.4,0.2l0.2-0.3h0.3l0.3,0.4v0.6l0.3-0.2l0.3,0.2l0.1-0.1L1166.5,277.2z"
					/>
					<rect
						id="_x30_"
						x="997.9"
						y="189.1"
						className={Styles.country}
						width="0"
						height="0"
					/>
				</g>
				<g
					id="Australia"
					className={Styles.continent}
					data-active={active == "Australia"}
					onClick={() => setActiveContinent("Australia")}
				>
					<path
						className={Styles.country}
						d="M1743,763.6l3.7,2.2l3.3-0.9l4.9-1.2l2.8,0.4l-4.5,7.6l-3.3,2.1l-4,5.2l-0.6-1.8l-6.6,4.4l-0.8-0.3l-3-0.2
		l0.5-5.4l2-4.2l0.6-5.6l2-2.9L1743,763.6z"
					/>
					<path
						className={Styles.country}
						d="M1793.5,590.2l1.2,5l4-2.4l1.4,2.7l2.3,2.5l-1.1,2.9l0.2,5.5l0.2,3.2l1.3,0.8l0.4,5.5l-1.2,3.3l0.8,4.3
		l5.4,3.4l3.2,3l3.2,2.8l-1.1,1.6l2.3,4l0.5,7l2.6-1.4l1.5,2.7l1.6-0.9l-0.7,6.8l2.9,3.9l1.9,2.4l2.8,5.2v5.2l-1,3.7l-1.8,3.9
		l0.7,5.5l-2.5,5.7l-2.1,3l-3.8,5.7l-1.5,3.7l-3.1,4.6l-5,5.8l-5.5,3.2l-4.4,4.9l-3.3,3.2l-4.4,5.5l-3.7,3.2l-3.9,4.8l-3.1,4.4
		l-0.8,2.1l-4.3,2.2l-6.1,0.2l-6.3,2.7l-3.8,2.4l-4.8,2.8l-2.7-2.9l-2.6-1.1l2.6-3.3l-3.5,1.2l-7.2,4.6l-3.6-1.7l-2.4-1l-2.8-0.5
		l-4.1-1.8l-1.3-4l1.5-4.8l0.4-3.3l-1.4-2.6l-4.7-0.7l3.2-3.2l0.9-4.7l-4.7,4.4l-5.3,1.2l4.5-3.5l2.5-3.7l3.5-3.2l1.6-4.7l-6.8,5.4
		l-4.3,2.2l-4.3,5.1l-3-2.6l1.7-3.4l-1.4-4.7l-1.8-2.4l1.6-1.5l-5.3-3.9l-3.8-0.1l-4-3.2l-9.9,0.6l-7.9,2.3l-6.9,2.2l-5-0.4l-7,3.3
		l-5.3,1.4l-2.3,3.4l-3,2.6l-4.7,0.2l-3.6,0.5l-4.2-1.1l-4.2,0.7l-3.8,0.3l-4.5,3.4l-1.5-0.3l-3.4,1.8l-3.4,2l-3.8-0.2h-3.5
		l-4.1-4.1l-2.4-1.2l1.5-3.7l2.9-0.9l1.5-1.4l0.6-2.3l2.3-4.5l0.7-3.8l-0.7-6.5l0.2-3.7l1.4-3.6l-0.9-4.2l0.3-1.9l-1.7-2.6l0.7-5
		l-1.9-5.1l-0.1-2.7l1.8,2.8l-0.5-6l2.3,1.9l1.1,2.5l0.6-3.3l-1.6-5.1l-0.1-2l-0.8-1.9l1.3-3.7l1.5-1.6l1.3-3.2l0.1-3.8l3.1-4.6
		l-0.4,4.9l3.1-4.4l4.9-2.2l3.2-2.7l4.7-2.4l2.6-0.5l1.4,0.8l4.8-2.4l3.5-0.7l1.1-1.4l1.5-0.6l3.1,0.2l6.2-1.9l3.5-2.9l2-3.4
		l3.9-3.2l0.7-2.6l0.7-3.5l4.9-5.5l1.4,5.6l2.6-1.3l-1.5-3l2.3-3.1l2.2,1.4l1.5-4.9l3.5-3.2l1.8-2.5l2.9-1.1l0.4-1.8l2.3,0.7
		l0.4-1.6l2.6-0.9l2.8-0.9l3.7,3l2.6,3.8h3.5l3.5,0.6l-0.7-3.5l3.5-5.1l2.7-1.7l-0.6-1.6l2.9-3.7l3.7-2.3l2.7,0.8l4.9-1.2l0.3-3.3
		l-3.9-2.1l3.1-0.9l3.6,1.6l2.7,2.6l4.5,1.6l1.7-0.6l3.3,2l3.5-1.9l2,0.6l1.5-1.3l2.2,3.2l-2,3.5l-2.5,2.6l-1.9,0.2l0.3,2.5
		l-2.2,3.2l-2.5,3.2l0.2,1.8l3.8,3.6l4,2l2.5,2.2l3.3,3.8h1.6l2.6,1.7l0.5,2l4.9,2.1l4.1-2.2l1.8-3.4l1.7-2.9l1.3-3.5l2.6-5.1
		l-0.2-3.1l0.7-1.9l-0.1-3.6l1.4-4.9l1.3-1.3l-0.6-2.1l1.8-3.4l1.5-3.5l0.4-1.9l2.3-2.4l1.3,3.2l-0.1,4l1.3,0.8l-0.1,2.7l1.6,3.2
		l-0.1,3.7L1793.5,590.2z"
					/>
					<path
						className={Styles.country}
						d="M1886.2,764.4l-0.8,2.6l5.6-2.6l-0.5,2.7l-2.1,2.7l-4.2,2.9l-7.1,4.7l-4.7,2.6l-0.6,3l-4,0.1l-6.3,2.4
		l-4.7,4.1l-8.2,6.4l-6.3,2.8l-4,1.8l-4.6-0.1l-1.5-2.1l-5.1-0.4l1-2.4l6.5-4.6l11.4-6.3l4.3-1.2l5.6-2.4l7-3.3l5.7-3.3l6-4.7
		l3.1-1.6l3.5-3.6l5.8-2.9L1886.2,764.4z"
					/>
					<path
						className={Styles.country}
						d="M1915.2,733.9l-0.4,6.8l2.9-4.4l1.3,1.8l-2.4,4.8l2.9,2.1l3.2,0.5l4.7-2.4l2.2,0.7l-5.2,5.7l-4.2,3.7l-3.9-0.1
		l-2.8,1.9l-1.5,2.8l-1.6,1.1l-4.6,3.5l-5.9,4.3l-6,2.6l0.5-1.7l-1.5-0.9l6.9-5.2l0.9-3.6l-3.8-2.5l1.8-2.3l5.3-2.2l4.2-5l2.5-4.1
		l0.6-4.3l0.8-1.1l-0.9-2.7l-0.7-5.6l0.4-4.6l2.2-0.5l0.9,3.6l3.2,1.6L1915.2,733.9z"
					/>
					<path
						className={Styles.country}
						d="M1915.2,646.9l-0.2,0.1l-0.2,0.4l-0.4-0.2l0.2-0.5l0.1-0.2l0.3,0.1L1915.2,646.9z"
					/>
					<path
						className={Styles.country}
						d="M1919.6,639.1l-0.1,0.1l0.2,0.4h0.3l0.4-0.2l-0.1,0.6l-0.2,0.2l-0.1,0.4l-0.5,0.3l-0.4-0.2v-0.1l-0.3-0.3
		l0.1-0.2l-0.2-0.2l0.1-0.4l-0.1-0.4h0.1l0.4,0.1L1919.6,639.1z"
					/>
					<path
						className={Styles.country}
						d="M1916.9,634.8l-0.2,0.5v0.4l-0.2,0.3l0.3,0.2l0.2,0.1l0.1,0.2l-0.1,0.2l0.3,0.4h-0.2l-0.2,0.5l-0.2,0.2
		l-0.3-0.1l-0.2-0.3l-0.1-0.2l-0.6-0.1l-0.3-0.5v-0.2l-0.1-0.3h0.3l0.3-0.2l0.4-0.3l0.1-0.3l-0.4-0.2l-0.4,0.1l0.2-0.4l0.3-0.1
		l0.2,0.1l0.4-0.3L1916.9,634.8z"
					/>
					<path
						className={Styles.country}
						d="M1913.5,633.2l-0.3,0.6l-0.1,0.3h0.3l-0.5,0.5l-0.2,0.2l-0.1-0.2l0.3-0.4l0.2-0.5L1913.5,633.2z"
					/>
					<path
						className={Styles.country}
						d="M1913.5,633.2l-0.2-0.4h0.4l0.1,0.4H1913.5z"
					/>
					<path
						className={Styles.country}
						d="M1899.6,630.8l0.2,0.1l0.5,0.9l0.3,0.3l0.3-0.1l0.2-0.3l0.9,0.5l0.3,0.5l0.7,0.6l0.3,0.4l0.1,0.3l0.4,0.4
		l0.1,0.2h0.2l0.3,0.3l0.9,0.2l0.1,0.3v0.4l0.3,0.4l0.4,0.1l-0.3,0.5l0.1,0.2l0.4,0.5h0.1l0.4,0.5l-0.1,0.4l0.7,0.2l0.4,0.5h0.3
		l0.3,0.2l0.5,0.4l-0.1,0.3h0.3l0.4,0.5l0.6,0.4v0.2l0.3,0.1l0.3,0.3v0.2l0.3,0.6l0.4,0.4l0.1-0.1l0.4,0.6l0.5,0.1l0.3,0.3v0.4
		l0.1,0.4l0.1,0.1l-0.2,0.6l-0.7,0.4l-0.1-0.3l-0.3,0.1l-0.3,0.3l-0.6-0.8h-0.4l-0.1-0.2h-0.2l-0.2,0.3l-0.9-0.9h-0.2l-0.4-0.5
		l0.1-0.8h-0.3h-0.4l-0.3-0.2l-0.1-0.2l-0.2-0.1h-0.3v-0.3l-0.2-0.5l-0.4,0.1l-0.8-0.5l-0.1-0.2v-0.3l-0.3,0.2l-0.6-0.3l-0.1-0.4
		l-0.4-0.3l-0.2-0.5l-0.5-0.1l0.2-0.2l-0.3-0.3l-0.3-0.1l-0.3-0.7v-0.4l-0.2-0.3h-0.3l-0.2-0.6l0.1-0.2l-0.7-0.4l-0.3-0.2l-0.4-0.6
		l0.3-0.2l-0.2-0.2l-0.2-0.3v-0.5l-0.5-0.3V633l-0.2-0.3h0.3l0.1-0.3l-0.1-0.2h-0.6l-0.2-0.3l0.5-0.7l-0.3-0.3L1899.6,630.8z"
					/>
					<path
						className={Styles.country}
						d="M1900.7,631.1l-0.3-0.1l0.1-0.4L1900.7,631.1z"
					/>
					<path
						className={Styles.country}
						d="M1898.4,628.7l-0.2,0.2l-0.1-0.4l0.2-0.2L1898.4,628.7z"
					/>
					<path
						className={Styles.country}
						d="M1931.8,631.8l-0.5-0.2l0.2-0.4l0.5-0.1l0.2,0.4l-0.1,0.2L1931.8,631.8z"
					/>
					<path
						className={Styles.country}
						d="M1930.6,628h-0.2l-0.3-0.1l-0.4-0.5l-0.1-0.4v-0.4l0.2-0.2l0.1-0.4l0.3-0.1h0.4l-0.2,0.3v0.5l0.7,0.5l-0.3,0.3
		L1930.6,628z"
					/>
					<path
						className={Styles.country}
						d="M1930.6,622.1h0.3l0.1,0.2l-0.5,0.1l0.1,0.3l0.4,0.2l0.1,0.3l-0.1,0.4l-0.4,0.1l-0.4-0.3h-0.4l-0.2-0.2
		l-0.3-0.2l0.1-0.4v-0.3l0.2-0.5l0.3-0.4h0.5l0.2,0.1l-0.1,0.4L1930.6,622.1z"
					/>
					<path
						className={Styles.country}
						d="M1927.6,614.5l0.6-0.1l0.1,0.1l0.2,0.6l0.2,0.3l0.1,0.1l-0.4,0.6l-0.8,0.2l-0.3-0.3l-0.1-0.5l-0.3-0.1
		l-0.4,0.3l-0.1-0.1l0.2-0.4l0.4-0.3l0.4-0.5L1927.6,614.5z"
					/>
					<path
						className={Styles.country}
						d="M1929.9,610.6l-0.1-0.3h0.3L1929.9,610.6z"
					/>
					<path
						className={Styles.country}
						d="M1928.3,608.2l0.1,0.5v0.2l0.4,0.1l0.2,0.5h0.5v0.4l-0.1,0.1l-0.3-0.3l-0.3-0.1l-0.6,0.2l-0.3-0.1l-0.1-0.2
		l-0.1-0.4l0.2-0.5l0.2-0.3L1928.3,608.2z"
					/>
					<path
						className={Styles.country}
						d="M1929.2,608L1929.2,608l-0.2-0.3h0.2V608z"
					/>
					<path
						className={Styles.country}
						d="M1929.4,606.6l-0.2,0.2h-0.6l-0.3,0.1l-0.2-0.2l-0.4-0.1l-0.5-0.4l0.1-0.2l0.5-0.2l0.4-0.1l0.4-0.3l0.1-0.3
		h0.2l0.1,0.3v0.6l0.5,0.4L1929.4,606.6z"
					/>
					<path
						className={Styles.country}
						d="M1924.7,605.3l0.2,0.3l0.6,0.4l0.7,0.7l0.1,0.1v0.4l0.2,0.3l-0.3,0.2l-0.4,0.4v-0.2l-0.3-0.1h-0.4l-0.2,0.1
		v0.2l-0.4,0.2l-0.3-0.1v-0.7l-0.1-0.6l0.1-0.6l0.1-0.5l-0.2-0.5l-0.6,0.3l-0.2-0.2l-0.1-0.3v-0.3l0.3-0.3v-0.4l0.1-0.3l0.3-0.1
		l0.6,0.3l-0.1,0.2l0.2,0.5l-0.1,0.2L1924.7,605.3z"
					/>
					<path
						className={Styles.country}
						d="M1924.1,602.9h-0.3l-0.5-0.3v-0.1l0.2-0.3l0.2-0.1l0.5,0.1l-0.1,0.4V602.9z"
					/>
					<path
						className={Styles.country}
						d="M1923.6,601.9l0.4-0.3l0.1,0.1l0.1,0.3L1923.6,601.9L1923.6,601.9z"
					/>
					<path
						className={Styles.country}
						d="M1929.3,604.5l-0.2-0.1l0.1-1.2l-0.1-0.3l0.1-0.5l0.3-0.8l0.1-0.5l0.2,0.6l-0.1,0.3v0.6v1l-0.1,0.6
		L1929.3,604.5z"
					/>
					<path
						className={Styles.country}
						d="M1928,601.2h-0.7l-0.3-0.1v-0.2l0.9-0.7l0.5-0.2l0.5-0.1v0.2l-0.3,0.4l-0.2,0.3L1928,601.2z"
					/>
					<path
						className={Styles.country}
						d="M1929.9,600.5v0.2h-0.3l0.1-0.3l0.2-1.3V599l0.1-1.2l0.2,0.1l-0.1,1.3l0.1,0.3l-0.1,0.5L1929.9,600.5z"
					/>
					<path
						className={Styles.country}
						d="M1923.6,601.9l-0.9-0.1l-0.6,0.3l-0.1,0.2h-0.2l-0.1-0.6l-0.2-0.1l-0.4-1l0.4-0.8v-0.4l-0.1-0.2v-1.4l-0.1-0.3
		V597l0.2-0.3v-0.3l0.2-0.3v-0.2l0.3-0.1l0.1,0.5l0.2,0.4l0.2,0.3l0.1,1.3l-0.1,0.8v0.1l0.6-0.1l0.3-0.3l0.2-0.8v-0.2l0.3-0.2
		l0.3,0.1l-0.1,0.4v0.9l0.3,0.1v0.5l-0.2,0.1l0.3,0.4l-0.2,0.2v0.5l0.2,0.5l-0.1,0.2h-0.3L1923.6,601.9z"
					/>
					<path
						className={Styles.country}
						d="M1927.8,592.6l0.4,0.3l-0.1,0.4l-0.3,0.3l-0.2,0.2h-0.6v-0.4l0.1-0.4l0.2-0.3L1927.8,592.6z"
					/>
					<path
						className={Styles.country}
						d="M1928.4,590.7l-0.3,0.2l-0.2,0.1v0.2l-0.2,0.1V591l-0.2-0.2l-0.1-0.3l0.1-0.4l0.2-0.1l0.4-0.2l0.1,0.2l0.1,0.4
		v0.1L1928.4,590.7z"
					/>
					<path
						className={Styles.country}
						d="M1989.1,624.9l-0.3,0.1l0.1-0.4L1989.1,624.9z"
					/>
					<path
						className={Styles.country}
						d="M1981.6,623.3l0.2,0.2l0.3,0.1v0.3l-0.5,0.2l-0.5-0.3l-0.5,0.3h-0.3l-0.2,0.3l0.1,0.3l-0.4-0.1l-0.1,0.2
		l-0.4-0.1l-0.1,0.1l-0.4-0.2l0.3-0.1l0.2-0.1l0.2-0.2l0.4,0.1l0.3-0.3l0.2-0.3l0.5-0.1l0.5-0.3H1981.6z"
					/>
					<path
						className={Styles.country}
						d="M1982.5,623.2l-0.2,0.1l-0.2-0.1l0.2-0.2L1982.5,623.2z"
					/>
					<path
						className={Styles.country}
						d="M1988.4,617.1l0.1,0.5l-0.2,0.6l-0.1-0.2l-0.1-0.4h-0.2l0.1-0.5l0.2-0.1L1988.4,617.1z"
					/>
					<path
						className={Styles.country}
						d="M1985.8,615.7h-0.1l-0.1-0.4l0.2-0.2l0.4-0.1v0.4l-0.2,0.2L1985.8,615.7z"
					/>
					<path
						className={Styles.country}
						d="M1983.6,613.2l-0.1,0.4l0.3,0.2l0.1,0.3l0.3,0.4l0.3,0.1l0.1,0.3h0.2l0.1,0.4l-0.3,0.5l0.1,0.2l0.1,0.5
		l-0.3,0.2v0.5h0.2l0.1,0.5l-0.3,0.3l-0.6,0.2l-0.1-0.2l-0.3,0.1l-0.1,0.2l-0.3-0.3l-0.8,0.3l-0.8,0.7l-0.3-0.1l-0.4,0.2l-0.3-0.1
		l-0.6,0.1l-0.3-0.2l-0.9-0.3l-0.3-0.1l-0.6-0.1l-0.2-0.1l-0.5-0.2l-0.2-1l0.3-0.7h0.3l0.3-0.1l0.1-0.5l0.3-0.1l0.1-0.4l-0.2-0.2
		l0.6-0.4l0.4-0.5l0.1,0.1l0.6-0.5h0.2l0.9-0.4l0.6,0.2l0.3-0.1l0.5-0.2l0.6-0.1l0.3-0.3L1983.6,613.2z"
					/>
					<path
						className={Styles.country}
						d="M1989.9,613.4l-0.2,0.1l0.2-0.9h0.4l-0.1,0.4L1989.9,613.4z"
					/>
					<path
						className={Styles.country}
						d="M1978.5,611.4l-0.2,0.3l-0.5,0.3v-0.3l0.4-0.4L1978.5,611.4z"
					/>
					<path
						className={Styles.country}
						d="M1993.4,611l-0.2-0.2l0.1-0.2l0.5-0.5l0.3-0.5l-0.3,1.1L1993.4,611z"
					/>
					<path
						className={Styles.country}
						d="M1994.4,606l-0.4,0.4l-0.8,1.1l-0.3,0.1l-0.7,0.4l-0.2,0.6l-0.4,0.2l-0.2,0.3l-0.1,0.2l0.3,0.1l0.6-0.3
		l0.1-0.1l0.3-0.3l0.2-0.3l0.6-0.3l0.3-0.3l0.6-0.3v0.3l-0.5,0.7l-0.2,0.1l0.1,0.6l-0.3,0.3l-0.3-0.3h-0.4l-0.5,0.1l-0.4,0.3
		l-0.7,0.1h-1l0.5-0.5l-0.4-0.2l-0.6,0.2l-0.4,0.2v0.2l-0.3,0.1l-0.2,0.1l-0.1,0.4l-0.2,0.3l-0.3-0.1v-0.2l-0.4-0.1l-0.4,0.2
		l-0.2,0.5l-0.3,0.2h-0.3v-0.3v-0.4l-0.2-0.4l0.1-0.2l-0.1-0.1l-0.6,0.2v-0.4l0.4-0.3h0.1l-0.1-0.5l0.3-0.1l0.5,0.3l0.6-0.4h0.2
		l0.3-0.3h0.2l0.3-0.3v-0.2l0.8-0.1l0.9-0.3l0.3-0.1l0.4,0.1l0.5-0.2l0.2-0.4h0.2l0.2-0.4l0.4,0.1l0.1-0.2l0.2,0.1l1-0.5l0.1,0.3
		l0.2-0.1l0.2,0.1l0.3-0.3l0.5-0.2l0.1,0.1l-0.5,0.4H1994.4z"
					/>
					<rect
						id="_x32_"
						x="1798.2"
						y="719.3"
						className={Styles.country}
						width="0"
						height="0"
					/>
				</g>
			</svg>
		</div>
	);
};

export default InternationalMap;
