import { useState, useRef, useEffect } from "react";
import { FaAngleDown } from "react-icons/fa6";
import Styles from "./Select.module.css";

/**
 * Renders a custom select component.
 *
 * @param {string} label - The label for the select component.
 * @param {array} options - An array of objects representing the options for the select component.
 * @param {string} defaultOption - The default option for the select component.
 * @param {function} onChange - A callback function that is called when the select value changes.
 * @return {JSX.Element} The rendered select component.
 */
const Select = ({ label, options, defaultOption, onChange, name }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultOption);
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleSelect = (value) => {
    setSelectedValue(value);
    setIsOpen(false);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className={Styles.selectContainer} ref={wrapperRef}>
      {label && <label className={Styles.label}>{label}</label>}
      <input type="hidden" name={name} value={selectedValue} />
      <div className={Styles.selectWrapper} onClick={() => setIsOpen(!isOpen)}>
        <div className={Styles.selectedValue}>
          {options?.find((option) => option.value === selectedValue)?.label ||
            defaultOption}
        </div>
        <div className={Styles.arrow}>
          <FaAngleDown />
        </div>

        <div
          className={`${Styles.optionsContainer} ${
            isOpen ? Styles.optionsContainerOpen : ""
          }`}
        >
          {options?.map((option, index) => (
            <div
              key={index}
              className={Styles.option}
              onClick={() => handleSelect(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Select;
