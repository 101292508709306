import Styles from "./TrainingCalendar.module.css";
import { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!

import { harmony, triad, analogue, lightness } from "simpler-color";

const TrainingCalendar = ({ courses }) => {
  // console.debug("Props: ");
  // console.debug(courses);

  const [events, setEvents] = useState(false);

  useEffect(() => {
    if (courses) setEvents(convertCourseIntoEvent(courses));
  }, [courses]);

  // console.debug("Events: ");
  // console.debug(events);

  return (
    <section className={Styles.wrapper}>
      <div className={Styles.container} data-contain="true">
        <h2>Upcoming Training Courses</h2>
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={events}
          eventBorderColor="#000000"
          eventTextColor="#FFFFFF"
          eventContent={renderEventContent}
          height="auto"
        />
      </div>
    </section>
  );
};

/**
 * Converts courses into events for the calendar.
 *
 * @param {Array} courses - The courses to be converted into events.
 * @returns {Array} The converted courses.
 */
const convertCourseIntoEvent = (courses) => {
  console.group();
  console.debug("BEGIN ::: convertCourseIntoEvent");

  let consecutiveCourses = [];
  let nonConsecutiveCourses = [];

  // Determine if courses are consecutive or non-consecutive
  courses?.forEach((course) => {
    let isConsecutive = true;
    if (course.schedule.length > 1) {
      for (let i = 1; i < course.schedule.length; i++) {
        const prevDate = new Date(course.schedule[i - 1].date);
        const currentDate = new Date(course.schedule[i].date);
        const diffDays = Math.ceil(
          Math.abs(currentDate - prevDate) / (1000 * 60 * 60 * 24)
        );
        if (diffDays > 1) {
          isConsecutive = false;
          break;
        }
      }
    }

    if (isConsecutive) {
      consecutiveCourses.push(course);
    } else {
      nonConsecutiveCourses.push(course);
    }
  });

  console.debug("Consecutive courses: ", consecutiveCourses);
  console.debug("Non-Consecutive courses: ", nonConsecutiveCourses);

  const eventBgBaseColor = "#3788d8";

  // Convert consecutive courses into events
  const convertedConsecutiveCourses = consecutiveCourses?.map((course) => ({
    title: course.courseName,
    id: course.id,
    start: course.startDate,
    end: new Date(
      new Date(course.endDate).setDate(new Date(course.endDate).getDate() + 1)
    )
      .toISOString()
      .split("T")[0],
    url: course.url,
    backgroundColor: lightness(eventBgBaseColor, getRandomInt(10, 70)),
  }));

  let convertedNonConsecutiveCourses = [];

  // Convert non-consecutive courses into events
  nonConsecutiveCourses.forEach((course) => {
    let startDate = new Date(course.schedule[0].date);
    for (let i = 1; i < course.schedule.length; i++) {
      const currentDate = new Date(course.schedule[i].date);
      const prevDate = new Date(course.schedule[i - 1].date);
      const diffDays = Math.ceil(
        Math.abs(currentDate - prevDate) / (1000 * 60 * 60 * 24)
      );
      if (diffDays > 1) {
        const event = {
          title: course.courseName,
          id: course.id,
          start: startDate.toISOString().split("T")[0],
          end: new Date(
            new Date(prevDate).setDate(new Date(prevDate).getDate() + 1)
          )
            .toISOString()
            .split("T")[0],
          url: course.url,
          backgroundColor: lightness(eventBgBaseColor, getRandomInt(10, 70)),
        };
        convertedNonConsecutiveCourses.push(event);
        startDate = currentDate;
      }
      if (i === course.schedule.length - 1) {
        const event = {
          title: course.courseName,
          id: course.id,
          start: startDate.toISOString().split("T")[0],
          end: new Date(
            new Date(currentDate).setDate(new Date(currentDate).getDate() + 1)
          )
            .toISOString()
            .split("T")[0],
          url: course.url,
          backgroundColor: lightness(eventBgBaseColor, getRandomInt(10, 70)),
        };
        convertedNonConsecutiveCourses.push(event);
      }
    }
  });
  let allConvertedCourses = [
    ...convertedConsecutiveCourses,
    ...convertedNonConsecutiveCourses,
  ];

  console.debug("Converted Consecutive Courses: ", convertedConsecutiveCourses);
  console.debug(
    "Converted NonConsecutive Courses: ",
    convertedNonConsecutiveCourses
  );
  console.debug("All Converted Courses: ", allConvertedCourses);

  console.debug("LEAVE ::: convertCourseIntoEvent");
  console.groupEnd();

  return allConvertedCourses;
};

const renderEventContent = (eventInfo) => {
  return (
    <>
      <p>
        <b>{eventInfo.event.title}</b>
      </p>
    </>
  );
};

export default TrainingCalendar;

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}
