import { PrismicNextImage } from "@prismicio/next";
import Styles from "./PostHeader.module.css";

const PostHeader = ({ post }) => {
  return (
    <section className={Styles.wrapper}>
      <PrismicNextImage
        field={
          post?.background_image_override?.url
            ? post?.background_image_override
            : post?.featured_image
        }
        className={Styles.featuredImage}
      />
      <div className={Styles.container}>
        <div className={Styles.col}>
          <h1 className={Styles.heading}>{post.title}</h1>
        </div>
      </div>
    </section>
  );
};

export default PostHeader;
