import { useRef } from "react";
import useIsomorphicLayoutEffect from "@/Utility/useIsomorphicLayoutEffect";
import useWindowSize from "@/Utility/useWindowSize";
import React from "react";
import Styles from "./ScaledHeading.module.css";

const ScaledHeading = ({
	heading,
	breakLines = false,
	containerClass,
	textClass,
	containerStyles,
	wrapperStyles,
	dataContain = false,
	margin = 0,
	hideMobile = false,
	paddingTop = false,
}) => {
	const scaledText = useRef();
	const headings = heading?.split(" ");
	const { width } = useWindowSize();

	const scaleText = (scalable) => {
		const scalableContainer = scalable.parentNode;
		scalable.style.transform = "scale(1)";
		const scalableContainerWidth = scalableContainer.offsetWidth - margin;
		const scalableWidth = scalable.offsetWidth;
		scalable.style.transform = `scale(${
			scalableContainerWidth / scalableWidth
		})`;
		scalableContainer.style.height = `${
			scalable.getBoundingClientRect().height
		}px`;
	};

	useIsomorphicLayoutEffect(() => {
		scaleText(scaledText.current);
	}, [width]);

	return (
		<div
			className={Styles.wrapper}
			style={wrapperStyles}
			data-padding-top={paddingTop}
		>
			<div data-contain={dataContain}>
				<div
					id={Styles.textContainer}
					className={containerClass}
					style={containerStyles}
					data-hide-mobile={hideMobile}
				>
					<h3 id={Styles.text} className={textClass} ref={scaledText}>
						{headings?.map((heading, index) => {
							if (index < headings.length - 1 && breakLines) {
								return (
									<React.Fragment key={index}>
										{heading} <br />
									</React.Fragment>
								);
							} else {
								return <React.Fragment key={index}>{heading} </React.Fragment>;
							}
						})}
					</h3>
				</div>
			</div>
		</div>
	);
};

export default ScaledHeading;
