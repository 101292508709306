import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import { Button } from "..";
import Styles from "./CheckoutModal.module.css";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
	process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
);

const CheckoutModal = ({ course, status, setModalOpen }) => {
	const [quantity, setQuantity] = useState(1);

	const formatDate = (dateString) => {
		if (dateString) {
			const [year, month, day] = dateString.split("-");
			return [month, day, year].join("-");
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const stripe = await stripePromise;

		const requestBody = {
			priceId: course.priceId,
			quantity: quantity,
			courseId: course.id,
		};

		if (course?.customQuestionField) {
			requestBody.customQuestionField = course.customQuestionField;
		}

		const response = await fetch("/api/checkout-session", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(requestBody),
		})
			.then((res) => res.json())
			.then((data) => {
				stripe.redirectToCheckout({
					sessionId: data.sessionId,
				});
			})
			.catch((error) => {
				console.log("Error:", error);
			});
	};

	if (status == "success") {
		return (
			<div className={Styles.container}>
				<h4 className={Styles.title}>Thanks For Booking!</h4>
				<p>Get ready for your training with some of our best equipment</p>
				<Button
					link={{ link_type: "Web", url: "/equipment" }}
					label="Shop Equipment"
				/>
			</div>
		);
	}

	if (status == "canceled")
		return (
			<div className={Styles.container}>
				<h4 className={Styles.title}>Oh No, You Cancelled</h4>
				<p>Do you have any questions we could help you with?</p>
				<Button
					link={{ link_type: "Web", url: "/contact" }}
					label="Contact Us"
				/>
			</div>
		);

	return (
		<div className={Styles.container}>
			<form onSubmit={handleSubmit} className={Styles.form}>
				<div className={Styles.productCard}>
					<div className={Styles.info}>
						<h5 className={Styles.name}>{course?.courseName}</h5>
						<p className={Styles.date}>
							Dates: {formatDate(course?.startDate)} -{" "}
							{formatDate(course?.endDate)}
						</p>
						<span className={Styles.price}>${course?.price}</span>
					</div>
					<div className={Styles.quantity}>
						{/* <button
								className={Styles.quantityMinus}
								onClick={() =>
									setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1))
								}
								type="button"
							>
								<MdRemove size="1.5rem" />
							</button> */}

						<span className={Styles.quantityInput}>Attendees: {quantity}</span>
						{/* 
							<button
								className={Styles.quantityPlus}
								onClick={() =>
									setQuantity((prevQuantity) => Math.min(prevQuantity + 1, 100))
								}
								type="button"
							>
								<MdAdd size="1.5rem" />
							</button> */}
					</div>
				</div>
				<div className={Styles.btnContainer}>
					{setModalOpen && (
						<Button
							type="button"
							label="Cancel"
							onClick={() => {
								setModalOpen(false);
							}}
							variant="secondary"
						></Button>
					)}
					<Button
						type="button"
						submit={true}
						label="Checkout"
						role="link"
						className={Styles.checkoutBtn}
					></Button>
				</div>
			</form>
		</div>
	);
};

export default CheckoutModal;
