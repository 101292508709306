import { PrismicNextImage } from "@prismicio/next";
import Styles from "./TrainerDetail.module.css";
import { PrismicRichText } from "@prismicio/react";

const TrainerDetail = ({ trainer }) => {
	return (
		<div className={Styles.wrapper}>
			<div className={Styles.container} data-contain="true">
				<PrismicNextImage
					field={trainer?.data?.image}
					className={Styles.image}
				/>
				<div className={Styles.col}>
					<div className={Styles.header}>
						<h1>{trainer?.data?.name}</h1>
						<p className={Styles.title}>{trainer?.data?.title}</p>
					</div>
					<PrismicRichText field={trainer?.data?.bio} />
				</div>
			</div>
		</div>
	);
};

export default TrainerDetail;
