import { PrismicNextImage } from "@prismicio/next";
import Styles from "./Footer.module.css";
import { IconSocial, UptownLink } from "..";
import Link from "next/link";
import { PrismicRichText } from "@prismicio/react";

const Footer = ({ data }) => {
	const year = new Date().getFullYear();

	return (
		<footer className={Styles.wrapper}>
			<div className={Styles.container} data-contain="true">
				<div className={Styles.contentContainer}>
					<div className={Styles.logoContainer}>
						<Link href="/" className={Styles.logo}>
							<PrismicNextImage field={data?.logo} />
						</Link>
						<div className={Styles.socialLinks}>
							{data?.social_links.map((social, index) => (
								<UptownLink key={index} link={social.link}>
									<IconSocial icon={social.icon} size="1.5rem" />
								</UptownLink>
							))}
						</div>
						<PrismicRichText field={data?.description} />
					</div>
					<div className={Styles.colContainer}>
						{data?.slices?.map((slice, index) => (
							<div
								className={Styles.col}
								key={index}
								data-variation={slice?.variation}
							>
								<p className={Styles.colHeading}>
									{slice?.primary?.column_heading}
								</p>
								{slice.variation == "default" &&
									slice?.items?.map((item, index) => (
										<UptownLink
											key={index}
											link={item?.link}
											className={Styles.footerItem}
										>
											<IconSocial icon={item?.icon} />
											{item?.link_label}
										</UptownLink>
									))}
								{slice.variation == "address" &&
									slice?.items.map((item, index) => (
										<div className={Styles.address} key={index}>
											<p className={Styles.addressTitle}>{item?.title}</p>
											<PrismicRichText field={item?.info} />
										</div>
									))}
							</div>
						))}
					</div>
				</div>
				<div className={Styles.copyrightContainer}>
					<p>Tech Safety Lines. &copy; {year} All rights reserved.</p>
					<div className={Styles.termsContainer}>
						{data?.terms_link?.url && (
							<UptownLink link={data?.terms_link}>
								Terms & Conditions
							</UptownLink>
						)}
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;

