import Head from "next/head";
import { useRouter } from "next/router";

/**
Generates a meta tag component for a webpage based on given parameters. *
@param {string} metaTitle - The title of the webpage.
@param {string} metaDescription - The description of the webpage.
@param {string} socialShareImage - The social media share image for the webpage.
@param {string} canonicalURL - The canonical URL for the webpage. *
@param {boolean} noIndex - True if the webpage should not be indexed by search engines. *
@returns {JSX.Element} - Returns a meta tag component for the webpage. */
const Meta = ({
	metaTitle,
	metaDescription,
	socialShareImage,
	canonicalURL,
	noIndex,
}) => {
	const router = useRouter();
	const domain = "https://techsafetylines.com";
	const pageURL = domain + router.asPath;
	const canonical = canonicalURL?.url ? domain + canonicalURL.url : pageURL;

	return (
		<Head>
			<title>{metaTitle}</title>
			<meta name="description" content={metaDescription} />
			<meta property="og:title" content={metaTitle} />
			<meta property="og:description" content={metaDescription} />
			<meta property="og:image" content={socialShareImage} />
			<meta property="og:type" content="website" />
			<meta property="og:url" content={pageURL} />
			{noIndex && <meta name="robots" content="noindex" />}
			<link href={canonical} rel="canonical" />
		</Head>
	);
};

export default Meta;
