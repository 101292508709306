import { PrismicNextImage } from "@prismicio/next";
import { UptownLink } from "@/components";
import { BsArrowRight } from "react-icons/bs";

import Styles from "./Card.module.css";

const Card = ({ link, image, title, description, containImage }) => {
	return (
		<UptownLink link={link} className={Styles.card}>
			<div className={Styles.imageContainer}>
				<PrismicNextImage
					field={image}
					className={Styles.image}
					data-contain={containImage}
				/>
			</div>
			<div className={Styles.cardBtnContainer}>
				<h4 className={Styles.cardTitle}>{title}</h4>
				<div className={Styles.cardBtn}>
					<BsArrowRight size={"1.5rem"} />
				</div>
			</div>
			{description && <p>{description}</p>}
		</UptownLink>
	);
};

export default Card;
