import { BiPencil } from "react-icons/bi";
import { BsGlobe, BsPeople } from "react-icons/bs";
import { AiOutlinePhone } from "react-icons/ai";
import { MdsSharpEngineering } from "react-icons/md";
import {
	FaFacebookF,
	FaTwitter,
	FaInstagram,
	FaLinkedinIn,
} from "react-icons/fa";

const Icon = ({ icon, size, color, className }) => {
	switch (icon) {
		case "Pencil":
			return <BiPencil size={size} color={color} className={className} />;
		case "Globe":
			return <BsGlobe size={size} color={color} className={className} />;
		case "Group":
			return <BsPeople size={size} color={color} className={className} />;
		case "Phone":
			return <AiOutlinePhone size={size} color={color} className={className} />;
		case "Engineer":
			return (
				<MdsSharpEngineering size={size} color={color} className={className} />
			);
		case "Facebook":
			return <FaFacebookF size={size} color={color} className={className} />;
		case "Twitter":
			return <FaTwitter size={size} color={color} className={className} />;
		case "Instagram":
			return <FaInstagram size={size} color={color} className={className} />;
		case "Linkedin":
			return <FaLinkedinIn size={size} color={color} className={className} />;
	}
};

export default Icon;

/**
Select List

"Pencil",
"Globe",
"Group", 
"Phone", 
"Engineer",
"Facebook", 
"Twitter",
"Instagram",
"Linkedin",


 */
