import { PrismicRichText } from "@prismicio/react";
import { Button } from "@/components";
import { PrismicNextImage } from "@prismicio/next";

import Styles from "./ImageTextCard.module.css";

const ImageTextCard = ({
	image,
	heading,
	description,
	ctaLink,
	ctaLabel,
	imageRight,
	negativeMargin,
	fullWidth,
	imageSmall,
	removeMaxHeight,
}) => {
	return (
		<div
			className={Styles.wrapper}
			data-full-width={fullWidth}
			data-reverse={imageRight}
			data-neg-margin={negativeMargin}
		>
			<PrismicNextImage
				field={image}
				className={Styles.image}
				data-small={imageSmall}
				data-remove-max-height={removeMaxHeight}
			/>
			<div className={Styles.container}>
				<h3 className={Styles.heading}>{heading}</h3>
				<PrismicRichText field={description} />
				{ctaLabel && ctaLink && <Button link={ctaLink} label={ctaLabel} />}
			</div>
		</div>
	);
};

export default ImageTextCard;
