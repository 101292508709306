import { UptownLink } from "..";

import Styles from "./TrainerCard.module.css";

const TrainerCard = ({ trainer }) => {
	return (
		<UptownLink
			link={{ link_type: "Document", url: trainer?.url }}
			className={Styles.wrapper}
		>
			<div
				className={Styles.circle}
				style={{ backgroundImage: `url(${trainer?.data?.image?.url})` }}
			></div>
			<h4>{trainer?.data?.name}</h4>
			<p className={Styles.title}>{trainer?.data?.title}</p>
		</UptownLink>
	);
};

export default TrainerCard;
