import { useState, useLayoutEffect, useRef, useEffect } from "react";
import useIsomorphicLayoutEffect from "@/Utility/useIsomorphicLayoutEffect";
import { PrismicNextImage } from "@prismicio/next";
import { Button, UptownLink, Icon, IconMenu, ContactForm, Modal } from "..";
import { IoIosArrowDown } from "react-icons/io";
import useOutsideClick from "../../Utility/useOutsideClick";
import Link from "next/link";
import Styles from "./Nav.module.css";
import Head from "next/head";

const Nav = ({ data }) => {
  const linkSlices = data?.slices;
  const [mobileNavOpen, setmobileNavOpen] = useState(false);
  const [sticky, setSticky] = useState(false);
  const nav = useRef();

  // useState hook for contactFormModal
  const [isModalVisible, setModalVisbility] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalVisbility(true);

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      if (windowHeight > 20) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    }
  };

  useOutsideClick(nav, () => {
    setmobileNavOpen(false);
  });

  useIsomorphicLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // if (mobileNavOpen && modalOpen) {
    //   document.body.style.overflow = "hidden";
    // } else {
    //   document.body.style.overflow = "visible";
    // }
    return () => (document.body.style.overflow = originalStyle);
  }, [mobileNavOpen, modalOpen]);

  return (
    <>
      <Head>
        <link rel="shortcut icon" type="image/jpg" href={data?.favicon?.url} />
      </Head>
      <header className={Styles.wrapper} ref={nav} data-sticky={sticky}>
        <div className={Styles.container} data-contain="true">
          <Link href="/" onClick={() => setmobileNavOpen(false)}>
            <PrismicNextImage field={data?.logo} className={Styles.logo} />
          </Link>
          <IconMenu
            className={Styles.menuIcon}
            state={mobileNavOpen}
            onClick={() => setmobileNavOpen(!mobileNavOpen)}
          />
          <nav className={Styles.nav} data-open={mobileNavOpen}>
            <ul className={Styles.primary}>
              {linkSlices &&
                linkSlices.map((slice, index) => (
                  <li key={index}>
                    <UptownLink
                      link={slice?.primary?.link}
                      className={Styles.link}
                      onClick={() => setmobileNavOpen(false)}
                    >
                      {slice?.primary?.label}{" "}
                      {slice?.items.length > 0 && <IoIosArrowDown />}
                    </UptownLink>
                    {slice?.items.length > 0 && (
                      <ul className={Styles.secondary}>
                        {slice?.items?.map((item, index) => {
                          if (item?.child_link.url)
                            return (
                              <li key={index}>
                                <UptownLink
                                  link={item?.child_link}
                                  className={Styles.link}
                                  onClick={() => setmobileNavOpen(false)}
                                >
                                  <Icon icon={item?.child_icon} />{" "}
                                  <span>{item?.child_label}</span>
                                </UptownLink>
                              </li>
                            );
                        })}
                      </ul>
                    )}
                  </li>
                ))}
            </ul>
            <div className={Styles.ctaContainer}>
              <Button
                label="Contact Us"
                type="button"
                variant="secondary"
                onClick={() => setModalOpen(!modalOpen)}
              />
              {/* <Button
              link={data?.secondary_cta_link}
              label={data?.secondary_cta_label}
              variant="secondary"
            /> */}

              <Button
                link={data?.primary_cta_link}
                label={data?.primary_cta_label}
                variant="primary"
                onClick={() => setmobileNavOpen(!mobileNavOpen)}
              />
            </div>

            <Link
              href="/"
              onClick={() => setmobileNavOpen(false)}
              className={Styles.mobileLogo}
            >
              <PrismicNextImage field={data?.logo} />
            </Link>
          </nav>
        </div>
        {/* modal for contact form */}
        <Modal
          title="Contact Us"
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setmobileNavOpen={setmobileNavOpen}
        >
          <ContactForm setModalOpen={setModalOpen} />
        </Modal>
      </header>
    </>
  );
};

export default Nav;
