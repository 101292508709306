import { Button } from "../";
import { useRef, useState } from "react";
import Styles from "./ContactForm.module.css";
import Select from "./Select/Select";

/**
 * `ContactForm` renders a contact form
 * If using in a modal pass the setModalOpen prop for the state of the modal to change
 * @component
 * @param {function} props.setModalOpen - A function to set the state of a modal (open/close).
 */
const ContactForm = ({ setModalOpen }) => {
  console.group("ContactForm ::: /components/ContactForm/ContactForm.jsx");

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isSubmissionPosted, setIsSubmissionPosted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.group("handleSubmit(event)");

    let currentUserContext = await getUserContext();
    console.debug(currentUserContext);

    const formData = new FormData(formRef.current);

    // Convert form data to object
    const formValues = Object.fromEntries(formData.entries());
    console.dir(formValues);

    const response = await fetch("/api/hubspot/postForm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...formValues, currentUserContext }),
    }).catch((error) => {
      console.error("Error submitting form:", error);
      return { status: 500 }; // Simulate a failed response
    });

    console.debug(response);

    setIsFormSubmitted(true); // Store true in form submission state regardless of success or failure

    if (response.status === 200) {
      // Form Submission was successful
      console.debug("Form Success!");
      setIsSubmissionPosted(true);
    } else {
      // Form Submission was unsuccessful
      console.debug("Form unsuccessful");
      setIsSubmissionPosted(false);
    }

    console.groupEnd();
  };

  const getUserContext = async () => {
    // Fetch IP address
    const ipResponse = await fetch("https://api.ipify.org?format=json");
    const ipData = await ipResponse.json();
    const ipAddress = ipData.ip;

    // Load data from cookie
    const cookie = document.cookie;
    console.log(cookie);

    // Find hubspotutk cookie
    const cookieArray = cookie.split(";");
    let hubspotutk = "";
    cookieArray.forEach((cookie) => {
      if (cookie.includes("hubspotutk")) {
        hubspotutk = cookie.split("=")[1];
      }
    });
    console.log(hubspotutk);

    // Build user context object
    const userContext = {
      hutk: hubspotutk,
      pageUri: window.location.href,
      pageName: document.title,
      ipAddress: ipAddress,
    };

    return userContext;
  };

  const formRef = useRef(null);

  console.groupEnd();
  return (
    <>
      {!isFormSubmitted ? (
        // Display Form if it hasn't been submitted yet
        <form
          ref={formRef}
          name="contact-form-cmp"
          className={Styles.form}
          data-form="true"
          data-shadow={!setModalOpen}
          onSubmit={(event) => handleSubmit(event)}
          data-netlify="true"
        >
          <div className="formRow">
            <div className="formGroup">
              <label htmlFor="first-name">First Name</label>
              <input
                id="first-name"
                name="firstName"
                type="text"
                placeholder="Enter your first name"
                required
              />
            </div>

            <div className="formGroup">
              <label htmlFor="last-name">Last Name</label>
              <input
                id="last-name"
                name="lastName"
                type="text"
                placeholder="Enter your last name"
                required
              />
            </div>
          </div>
          <div className="formRow">
            <div className="formGroup">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Enter your email"
                required
              />
            </div>
          </div>
          <div className="formRow">
            <div className="formGroup">
              <label htmlFor="email">Company</label>
              <input
                id="company"
                name="company"
                type="text"
                placeholder="Enter your company name"
              />
            </div>
          </div>
          <div className="formRow">
            <div className="formGroup">
              <label htmlFor="phone">Phone</label>
              <input
                id="phone"
                name="phone"
                type="tel"
                placeholder="123-456-7890"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              />
            </div>
            <Select
              label="Industry"
              name="primary_industry"
              options={[
                {
                  label: "Construction",
                  value: "Construction",
                },
                {
                  label: "Utility",
                  value: "Utility",
                },
                {
                  label: "Solar",
                  value: "Solar",
                },
                {
                  label: "Wind",
                  value: "Wind",
                },
                {
                  label: "Telecommunications",
                  value: "Telecommunications",
                },
                {
                  label: "Theme Parks",
                  value: "Theme Parks",
                },
                {
                  label: "Arboriculture",
                  value: "Arboriculture",
                },
                {
                  label: "Oil & Gas",
                  value: "Oil & Gas",
                },
                {
                  label: "General",
                  value: "General",
                },
                {
                  label: "Armed Forces",
                  value: "Armed Forces",
                },
                {
                  label: "Window Cleaning",
                  value: "Window Cleaning",
                },
                {
                  label: "Bridge",
                  value: "Bridge",
                },
                {
                  label: "Other",
                  value: "Other",
                },
              ]}
            />
          </div>

          <div className="formRow">
            <div className="formGroup">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                placeholder="What can we help you with?"
                rows="4"
              />
            </div>
          </div>
          <div className={Styles.btnContainer}>
            {setModalOpen && (
              <Button
                type="button"
                label="Cancel"
                onClick={() => setModalOpen(false)}
                variant="secondary"
              ></Button>
            )}
            <Button
              type="submit"
              submit={true}
              label="Submit"
              variant="primary"
            />
          </div>
          <input type="hidden" name="form-name" value="contact_form" />
        </form>
      ) : (
        <>
          {/* Display Form Submission Status if it has been submitted */}
          <div className={Styles.formSubmittedMessage}>
            {isSubmissionPosted ? (
              <div data-testid="contact-form-success-message">
                <h2>Thank you for your submission!</h2>
                <p>Someone with our team will get back to you soon.</p>
              </div>
            ) : (
              <div data-testid="contact-form-error-message">
                <h2>Oops! Something went wrong.</h2>
                <p>
                  Please try submitting your form again or reach out to us
                  directly at{" "}
                  <a href="mailto:ashley@techsafetylines.com">
                    ashley@techsafetylines.com
                  </a>
                </p>
              </div>
            )}
          </div>
          <div className={Styles.btnContainer}>
            {setModalOpen && (
              <Button
                type="button"
                label="Close"
                onClick={() => setModalOpen(false)}
                variant="primary"
              ></Button>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ContactForm;
