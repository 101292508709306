import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";

const Layout = ({ pageProps, children }) => {
	return (
		<div className="wrapper">
			<Nav data={pageProps?.nav?.data} />
			{children}
			<Footer data={pageProps?.footer?.data} />
		</div>
	);
};

export default Layout;
