import * as prismic from "@prismicio/client";
import * as prismicNext from "@prismicio/next";
import slicemachine from "./slicemachine.config.json";

/**
 * The project's Prismic repository name.
 */
export const repositoryName = prismic.getRepositoryName(
  slicemachine.apiEndpoint
);

// Update the routes array to match your project's route structure
/** @type {prismic.ClientConfig['routes']} **/
const routes = [
  {
    type: "home",
    path: "/",
  },
  {
    type: "page",
    path: "/:uid",
  },
  {
    type: "equipment",
    path: "/equipment",
  },
  {
    type: "equipment_category",
    path: "/equipment/:uid",
  },

  {
    type: "product",
    path: "/equipment/:category?/:uid",
    resolvers: {
      category: "equipment_category",
    },
  },
  {
    type: "learning_center",
    path: "/learning-center",
  },
  {
    type: "post_category",
    path: "/learning-center/:uid",
  },

  {
    type: "post",
    path: "/learning-center/:category?/:uid",
    resolvers: {
      category: "post_category",
    },
  },
  {
    type: "training",
    path: "/training",
  },
  {
    type: "courses",
    path: "/training/courses",
  },
  {
    type: "course",
    path: "/training/courses/:uid",
  },
  {
    type: "trainers",
    path: "/training/trainers",
  },
  {
    type: "trainer",
    path: "/training/trainers/:uid",
  },
  {
    type: "partners",
    path: "/training/partners",
  },
  {
    type: "training_hub",
    path: "/training/hub",
  },
];

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config {prismicNext.CreateClientConfig} - Configuration for the Prismic client.
 */
export const createClient = (config = {}) => {
  const client = prismic.createClient(slicemachine.apiEndpoint, {
    routes,
    ...config,
  });

  prismicNext.enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  });

  return client;
};
