import React from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { UptownLink } from "..";

import Styles from "./Breadcrumbs.module.css";

const Breadcrumbs = ({ breadcrumbs, style }) => {
	return (
		<div className={Styles.wrapper} style={style}>
			<div className={Styles.container} data-contain="true">
				{breadcrumbs.map((breadcrumb, index) => {
					if (breadcrumb.link.url && breadcrumb.link.link_type === "Document") {
						return (
							<React.Fragment key={index}>
								<IoIosArrowBack size="1rem" className={Styles.arrowBack} />
								<UptownLink key={index} link={breadcrumb?.link}>
									{breadcrumb.label}
								</UptownLink>
								<IoIosArrowForward
									size="1rem"
									className={Styles.arrowForward}
								/>
							</React.Fragment>
						);
					} else {
						return <span key={index}>{breadcrumb}</span>;
					}
				})}
			</div>
		</div>
	);
};

export default Breadcrumbs;
