import { useEffect, useState } from "react";
import Styles from "./IconMenu.module.css";

const IconMenu = ({ color, size, className, onClick, state, style }) => {
	const [open, setOpen] = useState();

	useEffect(() => {
		state ? setOpen(true) : setOpen(false);
	}, [state]);

	size = 0.6;

	return (
		<div className={className} style={style}>
			<div
				id={Styles.iconMenu}
				style={{ color: `${color}`, transform: `scale(${size})` }}
				onClick={onClick}
				data-open={open}
			>
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>
	);
};

export default IconMenu;
