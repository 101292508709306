import {
	FaFacebookF,
	FaTwitter,
	FaInstagram,
	FaLinkedinIn,
	FaTiktok,
	FaYoutube,
} from "react-icons/fa";

const IconSocial = ({ icon, size, color, className }) => {
	switch (icon) {
		case "Facebook":
			return <FaFacebookF size={size} color={color} className={className} />;
		case "Twitter":
			return <FaTwitter size={size} color={color} className={className} />;
		case "Instagram":
			return <FaInstagram size={size} color={color} className={className} />;
		case "LinkedIn":
			return <FaLinkedinIn size={size} color={color} className={className} />;
		case "TikTok":
			return <FaTiktok size={size} color={color} className={className} />;
		case "Youtube":
			return <FaYoutube size={size} color={color} className={className} />;
	}
};

export default IconSocial;

/**
Select List

"Facebook",
"Twitter",
"Instagram",
"LinkedIn", 
"TikTok", 
"Youtube"

 */
