import { UptownLink } from "..";
import { FaPlay } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import Styles from "./PostCard.module.css";

const PostCard = ({ post, variant }) => {
	if (post.url)
		return (
			<UptownLink
				link={{ link_type: "Document", url: post?.url }}
				className={Styles.cardWrapper}
			>
				<div
					className={Styles.card}
					style={{ backgroundImage: `url(${post?.data?.featured_image?.url})` }}
					data-variant={variant}
				>
					<h4 className={Styles.title}>{post?.data?.title}</h4>
					<div className={Styles.btn}>
						{variant == "Video" ? "Watch Now" : "Read Now"}
						{variant !== "Video" && variant !== "Large" && (
							<IoIosArrowForward size="1.5rem" />
						)}
					</div>
					{variant == "Video" && (
						<div className={Styles.iconWrapper}>
							<FaPlay className={Styles.icon} />
						</div>
					)}
				</div>
			</UptownLink>
		);
};

export default PostCard;
